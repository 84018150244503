<template>
  <div id="iframeBg">
    <div class="allBox">
      <div class="contentPC">
        <div class="navPC">
          <div class="nav">
            <ul>
              <li>
                <span class="pointer" @click="event_navToPage_pc(1, '')"
                  >华章书院</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer" @click="event_navToPage_pc(2, '')"
                  >华章师资</span
                >
              </li>
              <li v-if="navClassTypeName">
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li v-if="navClassTypeName">
                <span class="pointer" @click="event_navToPage_pc(2, '')">{{
                  navClassTypeName
                }}</span>
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span
                  class="pointer"
                  @click="event_navToPage_pc(3, lecturerId)"
                  >{{ userInfo.name }}</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer color">发送邀请</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="contentBox">
          <div class="personOll">
            <div class="personMes">
              <div class="headphoto">
                <el-image class :src="userInfo.list_image">
                  <div slot="placeholder" class>
                    <img class src="../../../assets/img/avatar_place.png" alt />
                  </div>
                  <div slot="error" class>
                    <img class src="../../../assets/img/avatar_place.png" alt />
                  </div>
                </el-image>
                <!-- <img :src="userInfo.image || require('../../../assets/img/avatar_place.png')" alt /> -->
              </div>
              <div class="personollMes">
                <span class="name">{{ userInfo.name }}</span>
                <div class="label">
                  <p v-for="(item, i) in userInfo.titleList">{{ item }}</p>
                </div>
                <div class="labelList">
                  <!-- 面议 -->
				  <span class="detail_state">￥ 面议 </span>
                  <!-- <span class="detail_state"
                    >￥{{ userInfo.remuneration || "面议" }}</span
                  > -->
                  <!-- <span v-for="(item,i) in userInfo.tagList">{{item}}</span> -->
                </div>
              </div>
            </div>
            <div class="informationOll">
              <div>
                <div class="information">
                  <p>基本信息</p>
                  <img src="../../../assets/img/state_1.png" alt />
                </div>
                <div class="informationList">
                  <div class="informationmes">
                    <span class="name fix_titleColor">活动类型</span>
                    <div class="PCridoe">
                      <div
                        class="radioBox"
                        @click="typClick('20')"
                        style="margin-right: 75px"
                      >
                        <img
                          v-show="radio != '20'"
                          src="../../../assets/img/radio.png"
                          alt=""
                        />
                        <img
                          v-show="radio == '20'"
                          src="../../../assets/img/radio_1.png"
                          alt=""
                        />
                        <span>授课</span>
                      </div>
                      <div class="radioBox" @click="typClick('10')">
                        <img
                          v-show="radio == '20'"
                          src="../../../assets/img/radio.png"
                          alt=""
                        />
                        <img
                          v-show="radio != '20'"
                          src="../../../assets/img/radio_1.png"
                          alt=""
                        />
                        <span>活动</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="informationList">
                  <div class="informationmes">
                    <span class="name fix_titleColor">公司名称</span>
                    <input
                      v-model="baseInfo.companyName"
                      class="input"
                      type="text"
                      placeholder="请输入公司名称"
                    />
                  </div>
                  <div class="informationmes">
                    <span class="name fix_titleColor">姓名</span>
                    <input
                      v-model="baseInfo.contactName"
                      class="input"
                      type="text"
                      placeholder="请输入姓名"
                    />
                  </div>
                  <div class="informationmes">
                    <span class="name fix_titleColor">联系方式</span>
                    <input
                      v-model="baseInfo.companyTel"
                      class="input"
                      type="text"
                      placeholder="请输入联系方式"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="information">
                  <p>{{ inviteType == 10 ? "基本需求" : "授课需求" }}</p>
                </div>
                <div
                  class="informationList"
                  :class="inviteType == 20 ? '' : 'informationLists'"
                >
                  <div class="informationmes">
                    <span class="name fix_titleColor">活动城市</span>
                    <div class="input">
                      <el-cascader
                        class="pc_select_input"
                        placeholder="选择城市"
                        v-model="value2"
                        :options="options"
                        :props="{ expandTrigger: 'hover' }"
                        @change="handleChangeCityPC"
                        clearable
                      ></el-cascader>
                    </div>
                  </div>
                  <div class="informationmes">
                    <span class="name fix_titleColor">活动日期</span>
                    <div class="input">
                      <el-date-picker
                        class="inputs"
                        v-model="baseInfo.eventDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        format="yyyy-MM-dd"
                        placeholder="选择日期"
                      ></el-date-picker>
                    </div>
                  </div>
                  <div class="informationmes">
                    <!--                <span class="name fix_titleColor">活动类型</span>
                    <div class="input">
                      <el-cascader
                        placeholder="选择类型"
                        v-model="baseInfo.eventType"
                        :options="typeOptions"
                        @change="handleChangeTypePC"
                      ></el-cascader>
                    </div> -->
                  </div>
                  <!-- <div class="informationmes" v-if="inviteType == 20">
                    <span class="name2">授课规模</span>
                    <input v-model="baseInfo.eventScale" class="input" placeholder="规模" />
                  </div>-->
                </div>
              </div>
              <div>
                <div class="information">
                  <p>描述需求</p>
                </div>
                <textarea
                  v-model="baseInfo.description"
                  class="textarea"
                  placeholder="请简单描述您的活动流程和对嘉宾的要求"
                  style="resize: none"
                  name
                  id
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div>
                <p class="reminder">
                  我们将会在收到邀请函后，1~3个工作日与您取得联系
                </p>
              </div>
              <div class="footerBoxs">
                <div class="footerBtn" @click="event_toResule()">
                  <span>提交意向</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import elementResizeDetectorMaker from "element-resize-detector";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import CityListJson from "../../../js/CityList.json";
import Axios from "axios";
import Vue from "vue";

export default {
  components: {
    fooTer,
  },
  data() {
    return {
      lecturerId: "",
      radio: "20",
      rlen: history.length,
      navClassTypeName: "",
      rightNavShow: "",
      flag: "",
      isMobile: "",
      show: false,
      showDate: false,
      showType: false,
      currentDate: new Date(),
      showTiem: false,
      isLoadingShow: true,
      location: "",
      areaList: {},
      value1: "",
      value2: "",
      typeList: [{ values: ["授课", "活动"] }],
      typeOptions: [
        {
          value: 20,
          label: "授课",
        },
        {
          value: 10,
          label: "活动",
        },
      ],
      options: [],
      baseInfo: {
        companyName: "",
        contactName: "",
        companyTel: "",
        eventPlace: "",
        eventDate: "",
        eventType: "20",
        eventTypeStr: "",
        eventScale: "",
        description: "",
      },
      navLevel: 4,
    };
  },
  methods: {
    // PC
    typClick(value) {
      this.radio = value;
      this.baseInfo.eventType = value;
    },
    handleChangeCityPC(value, name) {
      var province_list = this.areaList.province_list;
      var city_list = this.areaList.city_list;
      var county_list = this.areaList.county_list;
      var provinceStr = "";
      var city = "";
      var county = "";
      for (var i = 0; i < value.length; i++) {
        var str = value[i];
        if (i == 0) provinceStr = province_list[str];
        if (i == 1) city = city_list[str];
        if (i == 2) county = county_list[str];
      }
      this.baseInfo.eventPlace = provinceStr + "," + city + "," + county;
    },

    handleChangeTypePC(value) {
      this.baseInfo.eventType = value;
      // for (var i = 0; i < this.typeOptions.length; i++) {
      //   if (this.typeOptions[i].value == value) this.baseInfo.eventTypeStr = this.typeOptions[i].label;
      // }
    },

    // event_navToPage_pc(type, info) {
    //   debugger;
    //   // var navIndex = type - this.navLevel;
    //   // navIndex = this.rlen - history.length + navIndex;
    //   if (type == 1) {
    //     //华章书院
    //     //测试代码:
    //     navIndex += 1;
    //     this.$router.go(navIndex);
    //   } else if (type == 2) {
    //     //华章师资   / 热门
    //     this.$router.go(navIndex);
    //   } else if (type == 3) {
    //     //讲师信息
    //     // this.$router.go(navIndex);

    //     this.$router.push({
    //       path: "GuestDetail",
    //       query: {
    //         id: info || "",
    //       },
    //     });
    //   }
    // },
    event_navToPage_pc(type, info) {
      if (type == 1) {
        //华章书院
        //测试代码:
        this.$router.push({
          path: "teachers",
          query: {},
        });
      } else if (type == 2) {
        //华章师资   / 热门
        this.$router.push({
          path: "GuestList",
          query: {},
        });
      } else if (type == 3) {
        //讲师信息
        this.$router.push({
          path: "GuestDetail",
          query: {
            id: info || "",
          },
        });
      }
    },
    showPopup() {
      this.show = true;
    },
    showDatePopup() {
      this.showDate = true;
    },
    showTypePopup() {
      this.showType = true;
    },
    onCancel() {
      this.show = false;
    },
    // 选择地址
    onconfirm(val) {
      this.baseInfo.eventPlace =
        val[0].name + "," + val[1].name + "," + val[2].name;
      this.onCancel();
    },
    // 关闭时间控件
    oncancelTiem() {
      this.showDate = false;
    },
    // 关闭时间控件
    oncancelType() {
      this.showType = false;
    },
    // 选择时间
    confirmType(val) {
      var typeStr = val[0];
      this.baseInfo.eventTypeStr = typeStr;
      if (typeStr == "授课") {
        this.baseInfo.eventType = 20;
      } else if (typeStr == "活动") {
        this.baseInfo.eventType = 10;
      }
      // let year = val.getFullYear();
      // let month = val.getMonth() + 1;
      // let day = val.getDate();
      // this.baseInfo.eventType = `${year}-${month}-${day}`;
      this.oncancelType();
    },
    // 选择时间
    confirmTiem(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      this.baseInfo.eventDate = `${year}-${month}-${day}`;
      this.oncancelTiem();
    },
    // 时间格式
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    //手机格式认证
    checkPhoto(photoStr) {
      var isPhone = false;
      if (/^1[3456789]\d{9}$/.test(photoStr)) {
        isPhone = true;
      }
      return isPhone;
    },
    // 跳转邀请函
    event_toResule() {
      console.log(this.radio);
      this.baseInfo.eventType = this.radio;
      var that = this;
      var message = "";
      if (this.userId == "") {
        message = "请登录账户";
      } else if (!this.lecturerId) {
        message = "讲师信息错误";
      } else if (this.baseInfo.companyName == "") {
        message = "请输入公司名称";
      } else if (this.baseInfo.contactName == "") {
        message = "请输入姓名";
      } else if (this.baseInfo.companyTel == "") {
        message = "请输入联系方式";
      } else if (!this.checkPhoto(this.baseInfo.companyTel || "")) {
        message = "手机号码有误,请重新输入";
      } else if (this.baseInfo.eventPlace == "") {
        message = "请选择活动城市";
      } else if (this.baseInfo.eventDate == "") {
        message = "请选择活动日期";
      } else if (this.baseInfo.eventType == "") {
        message = "请选择活动类型";
      }
      if (message != "") {
        this.$alert(message);
        return;
      }

      var that = this;
      var data = {
        userId: this.userId,
        lecturerId: this.lecturerId,
        inviteType: this.baseInfo.eventType,
        companyName: this.baseInfo.companyName,
        contactName: this.baseInfo.contactName,
        companyTel: this.baseInfo.companyTel,
        eventPlace: this.baseInfo.eventPlace,
        eventDate: this.baseInfo.eventDate,
        description: this.baseInfo.description || "",
      };
      if (this.inviteType == 20)
        data.eventScale = this.baseInfo.eventScale || "";
      this.AjaxTeacher(ApiUrl.submitRequest, data, function(res) {
        if (res.code == 0) {
          var url = "/InviteResult";
          var query = {
            navClassTypeName: that.navClassTypeName || "",
            navTitle: that.userInfo.name || "",
            lecturerId: that.lecturerId || "",
          };


          // let routeUrl = this.$router.resolve({
          //   path: url,
          //   query: query,
          // });
          // window.open(routeUrl.href, "_blank");
          that.$router.push({
            path: url,
            query: query,
          });
        } else {
          that.$alert(res.message);
        }
      });
    },

    setupAjaxData() {},
    setupAreaJsonData() {
      var that = this;
      that.areaList = CityListJson;
      that.setupAreaData_pc();

      // Axios.get("../../../js/CityList.json").then(
      //   (res) => {
      //     console.log(res.data);
      //     that.areaList = res.data;
      //     if (!that.isMobile) that.setupAreaData_pc();
      //   },
      //   function (err) {}
      // );
    },

    setupAreaData_pc() {
      //数据集合: this.areaList
      var province_list = this.areaList.province_list;
      var city_list = this.areaList.city_list;
      var county_list = this.areaList.county_list;

      var options = [];
      for (let key1 in province_list) {
        //遍历省
        var value1 = key1;
        var label1 = province_list[key1];

        var children1 = [];
        for (let key2 in city_list) {
          //遍历市
          var value2 = key2;
          var label2 = city_list[key2];
          var children2 = [];

          if (value1.slice(0, 3) == value2.slice(0, 3)) {
            //同省的时候,

            for (let key3 in county_list) {
              //遍历区
              var value3 = key3;
              var label3 = county_list[key3];

              if (value2.slice(0, 5) == value3.slice(0, 5)) {
                //同区的时候
                //item3 区
                var item3 = {
                  value: value3,
                  label: label3,
                };
                children2.push(item3); //添加区 到市
              }
            }
            var item2 = {
              value: value2,
              label: label2,
              children: children2,
            };
            children1.push(item2);
          }
        }
        var item1 = {
          value: value1,
          label: label1,
          children: children1,
        };
        options.push(item1);
      }
      this.options = options;
    },
  },

  beforeRouteEnter(to, from, next) {
    next();
    return;
    if (!(window.self === window.top)) {
      //iframe打开
      next();
      return;
    }

    // var userId = from;
    //
    // from.meta.keepAlive = true;

    var isMobile = true; //判断是否是手机
    var flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    // console.log(flag);
    if (flag === null) {
      // console.log("pc端");
      isMobile = false;
      next({
        path: "/GuestDetailBg", //跳pc首页
      });
      // next();
    } else {
      // console.log("移动端");
      isMobile = true;
      next();
    }
  },

  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    console.log(this.flag);
    if (this.flag === null) {
      console.log("pc端");
      this.rightNavShow = true;
    } else {
      console.log("移动端");
      this.rightNavShow = false;
    }
    const clientWidth = document.body.clientWidth;

    console.log("屏幕尺寸", clientWidth);
    console.log("状态", this.rightNavShow);

    if (this.rightNavShow) {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      var app = document.getElementById("iframeBg");
      erd.listenTo(app, (element) => {
        _this.$nextTick(() => {
          var iHeight = app.offsetHeight + 11;
          iHeight = iHeight > 500 ? iHeight : 500;
          parent.window.postMessage(
            { msgType: 1, msgData: { iHeight: iHeight } },
            "*"
          );
        });
      });
    }
  },

  created() {
    document.title = "华章师资 - 华章书院";
    this.userId = localStorage.getItem("userId") || "";
    var jsonInfo = localStorage.getItem("lecturer_userInfo");
    // localStorage.removeItem("lecturer_userInfo");

    if (jsonInfo) {
      this.userInfo = JSON.parse(jsonInfo);
    } else {
    }

    this.lecturerId = this.$route.query.id || "";
    this.inviteType = this.$route.query.type || "10";
    this.navClassTypeName = this.$route.query.navClassTypeName || "";

    if (this.lecturerId == "") {
      console.log("嘉宾ID为空");
      return;
    }
    this.setupAjaxData();
    this.setupAreaJsonData();
  },
};
</script>

<style lang="scss">
/* PC*/
.contentPC {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  cursor: default;
  // padding-top: 11px;
  .navPC {
    width: 100%;
    height: 75px;
    background-color: #F4F4F4;
    .nav {
      width: 100%;
      height: 100%;
      max-width: 1157px;
      margin: 0 auto;
      ul {
        height: 100%;
        display: flex;
        align-items: center;

        li {
          height: 100%;
          // margin-right: 16px;
          display: flex;
          align-items: center;
          color: #363636;
          font-size: 16px;
          font-weight: 600px !important;
          // font-weight: bold;
          .top {
            margin-top: 4px;
          }
          .pointer {
            cursor: pointer;
            font-weight: 700;
            color: #303133;
          }
          .pointer:hover{ 
            color:#429efd;
          }
          .color {
            color: #e6554d;
            font-weight: 400;
          }
          .color:hover{ 
            color:#e6564e;
            cursor: auto;
          }
        }
        li:hover {
          color: #2d8cf0;
        }
      }
    }
  }
  .contentBox {
    width: 100%;
    height: 100%;
    max-width: 1157px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    padding-bottom: 0.3rem;
    .personOll {
      width: 93%;
      margin-left: 50px;
      .informationOll {
        .footerBoxs {
          display: flex;
          justify-content: flex-end;
          .footerBtn {
            width: 350px;
            height: 50px;
            background: #e45754;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              color: #fff;
              font-weight: 16px;
            }
          }
        }
        .reminder {
          font-size: 15px;
          color: #dadada;
          padding: 10px 0 19px 0;
          display: flex;
          align-items: center;
        }
        .reminder::before {
          content: "*";
          display: block;
          font-size: 24px;
          color: red;
          margin-right: 8px;
          margin-top: 2px;
        }
        .textarea {
          width: 98.3%;
          height: 140px;
          background: #f8f8f8;
          border-radius: 4px;
          padding: 10px 0 0 15px;
          font-size: 21px;
          color: #2b2b2b;
		  border: 1px #D2D2D2 solid;
        }
        .textarea::-webkit-input-placeholder {
          color: #dadada;
        }
        .informationList {
          display: flex;
          justify-content: space-between;
          .informationmes {
            display: flex;
            flex-direction: column;
            width: 330px;
            .name {
              color: #3f4050;
              font-weight: 16px;
              position: relative;
              padding-left: 15px;
            }
            .name2 {
              color: #3f4050;
              font-weight: 16px;
              position: relative;
              padding-left: 5px;
            }
            .name::before {
              content: "*";
              display: block;
              position: absolute;
              left: 0;
              top: 2px;
              color: #f53d45;
              font-size: 24px;
            }
            .input {
              background-color: #f8f8f8;
			  border: 1px #D2D2D2 solid;
              height: 50px;
              display: flex;
              align-items: center;
              padding-left: 12px;
              margin-top: 8px;
              font-size: 20px;
              color: #2b2b2b;
              // font-weight: bold;
            }
            .input::-webkit-input-placeholder {
              color: #cccccc;
            }
            .input /deep/ .el-input--prefix .el-input__inner {
              background-color: #f8f8f8;
              font-size: 20px;
              border: 0;
              cursor: pointer;
              color: #2b2b2b;
            }
            .input /deep/ .el-input__inner {
              background-color: #f8f8f8;
              font-size: 20px;
              border: 0;
              color: #2b2b2b;
            }
            .input /deep/ .el-input__inner::-webkit-input-placeholder {
              color: #2b2b2b;
            }
            .input
              /deep/
              .el-input--prefix
              .el-input__inner::-webkit-input-placeholder {
              color: #2b2b2b;
            }
            .PCridoe {
              display: flex;
              align-items: center;
              padding: 15px 0;
              .radioBox {
                display: flex;
                align-items: center;
                margin-right: 40px;
                span {
                  font-size: 20px;
                  border: 0;
                  color: #2b2b2b;
                  margin-left: 15px;
                }
                img {
                  width: 25px;
                  height: 25px;
                  object-fit: contain;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .informationLists::after {
          content: "";
          display: block;
          width: 3.3rem;
        }
        .information {
          display: flex;
          padding: 20px 0;
          align-items: center;

          p {
            color: #2b2b2b;
            font-size: 24px;
            font-weight: bold;
          }
          img {
            width: 60px;
            height: 24px;
            object-fit: contain;
            margin-top: 3px;
            margin-left: 8px;
          }
        }
      }

      .personMes {
        display: flex;
        align-items: center;
        padding: 24px 0;
        border-bottom: 1px solid #eeeeee;
        .headphoto {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .personollMes {
          display: flex;
          align-items: center;
          margin-left: 0.4rem;
          .name {
            color: #2b2b2b;
            font-size: 27px;
            font-weight: bold;
          }
          .label {
            color: #cccccc;
            font-size: 18px;
            margin: 0 0.3rem;
            p {
              padding: 3px;
              max-width: 660px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .labelList {
            display: flex;
            flex-direction: column;
            margin-left: 0.3rem;
            // span {
            //   padding: 3px 12px;
            //   background-color: #e0f3f7;
            //   font-size: 15px;
            //   color: #52c4c6;
            //   border-radius: 5px;
            //   margin-top: 5px;
            // }
            span {
              font-size: 20px;
              font-weight: bold;
              color: #fe8608;
              line-height: 1;
              display: block;
            }
          }
        }
      }
    }
  }
}
/* 手机版的样式 */
body {
  background-color: #f4f5f6;
}
.area /deep/ .van-picker__title {
  color: #2b2b2b;
}
.peronMes /deep/ .van-picker__title {
  color: #2b2b2b;
}
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}
.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}
.content-MT {
  width: 100%;
  margin-top: 50px;
  padding-bottom: 80px;
  flex-direction: column;
  background: #f4f5f6;
  /* background-color: red; */
  .personal_details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;

    background-color: #fff;
    .userBoxoll {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 15px;
      .name {
        color: #2b2b2b;
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        text-align: center;
      }
    }
    .profile_photo {
      width: 3rem;
      height: 3rem;
      // margin-left: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 50%;
      }
      .logo {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
    }
    .person {
      display: flex;
      align-items: center;
      width: 75%;
      justify-content: space-between;

      .personBox {
        display: flex;
        align-items: center;
      }
      .message {
        color: #cccccc;
        font-size: 12px;
        // margin-left: 20px;
        p {
          max-width: 10rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-right: 15px;
        height: 80px;
        overflow: hidden;
        span {
          font-size: 20px;
          font-weight: bold;
          color: #fe8608;
          line-height: 1;
          display: block;
          width: 65px;
          margin-right: 10px;
        }
      }
    }
  }
  .content {
    width: 93%;
    margin: 0 auto;
    background: #f4f5f6;
    .basic_information {
      .title {
        display: flex;
        padding: 15px 0;
        align-items: center;
        .mes {
          font-size: 16px;
          color: #2b2b2b;
          font-weight: bold;
        }
        .state {
          background: #000000;
          border-radius: 22px;
          padding: 2px 9px;
          color: #fff;
          font-size: 12px;
          margin-left: 15px;
        }
      }
      .text {
        padding: 10px 0;
        padding-left: 10px;
        width: 97.5%;
        height: 4rem;
        font-size: 14px;
      }
      .text::-webkit-input-placeholder {
        color: #dadada;
        font-size: 14px;
      }
      .basicMes {
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
        padding: 0 15px;
        padding-bottom: 20px;
        .basicMesBox {
          padding: 12px 0;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #eeeeee;
          color: #cccccc;
          font-size: 16px;
          // font-weight: bold;
          .group {
            margin-top: 12px;
          }
          .group /deep/ .van-radio__label {
            color: #2b2b2b;
            font-size: 15px;
          }
          .basicName {
            color: #dadada;
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 0;
          }
          input {
            // color: #cccccc;
            color: #2b2b2b;
            font-size: 15px;
            margin-top: 8px;
          }
          .mes::-webkit-input-placeholder {
            color: #cccccc;
          }
        }
        .boder {
          border-bottom: 1px solid #eeeeee;
        }
        .basicMesBoxs {
          display: flex;
          padding: 10px 0;

          .peronMes {
            width: 50%;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #dadada;
            .mes {
              // color: #cccccc;
              color: #2b2b2b;
              font-size: 15px;
              margin-top: 12px;
              // font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: space-between;
              line-height: 1;
              img {
                width: 8px;
                height: 10px;
                margin-right: 20px;
              }
            }
            .mes_place {
              color: #cccccc;
            }
            .mes::-webkit-input-placeholder {
              color: #cccccc;
            }
            .peronMesAcBox {
              position: relative;
              img {
                position: absolute;
                width: 12px;
                height: 12px;
                object-fit: cover;
                right: 10px;
                bottom: 2px;
                // display: none;
              }
            }
          }
        }
      }
    }
  }
}
.footerBox {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 92.5%;
  height: 49px;
  background: linear-gradient(194deg, #4fae82 0%, #6ce381 100%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.fix_titleColor {
  color: #2b2b2b;
}
.van-radio--horizontal {
  margin-right: 40px;
}
</style>
