<template>
  <div id="iframeBg">
    <!-- PC -->
    <div v-if="rightNavShow == true" class="allBox">
      <div class="content">
        <div class="navPC">
          <div class="nav">
            <ul>
              <li>
                <span class="pointer" @click="event_navToPage_pc(1, '')"
                  >华章书院</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer" @click="event_navToPage_pc(2, '')"
                  >华章师资</span
                >
              </li>
              <!-- <li>
                <span class="top">></span>
              </li>
              <li>
                <span class="pointer"  @click="event_navToPage_pc(2,'')">热门</span>
              </li> -->
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer color">收藏</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="contentBox">
          <!-- 回到顶部 -->
          <div class="upTop">
            <img src="../../../assets/img/UPTop.png" alt />
          </div>
          <div class="headImg">
            <img
              @click="navRightClick"
              v-if="rightImgType == 3"
              src="../../../assets/img/navright_3.png"
              alt
            />
            <img
              @click="navRightClick"
              v-if="rightImgType != 3"
              src="../../../assets/img/navright_4.png"
              alt
            />
          </div>
          <div class="personList">
            <div
              class="personBox"
              v-for="(item, i) in LecturerList"
              @click="event_lecturerItemClick(item)"
              :key="i"
            >
              <img
                class="dleIMG"
                @click.stop="event_delBtnClick(item, i)"
                v-show="hideImg"
                src="../../../assets/img/state1.png"
                alt
              />
              <div class="headpoton">
                <<<<<<< .mine
                <el-image class="" :src="item.image" style="border-radius:50%">
                  <div slot="placeholder" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                  <div slot="error" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                </el-image>
                ||||||| .r382
                <el-image class="" :src="item.image" style="border-radius:50%">
                  <div slot="placeholder" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                  <div slot="error" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                </el-image>
                =======
                <el-image class="" :src="item.image" style="border-radius: 50%">
                  <div slot="placeholder" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                  <div slot="error" class="">
                    <img
                      class=""
                      src="../../../assets/img/avatar_place.png"
                      alt
                    />
                  </div>
                </el-image>
                >>>>>>> .r384
                <!-- <img :src="item.image || require('../../../assets/img/avatar_place.png')" alt /> -->
              </div>
              <div class="personMessage">
                <div class="personNema">
                  <span class="name">{{ item.name }}</span>
                  <img src="../../../assets/img/icom8.png" alt />
                </div>
                <div class="user_skill">
                  <div class="user_skilist">
                    <p v-for="(titleItem, j) in item.titleList" :key="j">
                      {{ titleItem }}
                    </p>
                  </div>
                  <img src="../../../assets/img/youjiantou.png" alt />
                </div>
                <div class="user_label">
                  <span v-for="(tagItem, j) in item.tagList" :key="j">{{
                    tagItem
                  }}</span>
                </div>
              </div>
            </div>

            <!-- hide-on-single-page -->
            <div class="pagination">
              <el-pagination
                background
                layout="pager"
                :total="totalListNum"
                hide-on-single-page
                :current-page="currentPage"
                @current-change="handleSizeChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import elementResizeDetectorMaker from "element-resize-detector";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import Vue from "vue";

export default {
  components: {
    fooTer,
  },
  data() {
    return {
      userId: "",
      hideImg: false,
      rightNavShow: "",
      flag: "",
      windowHeight: document.documentElement.clientHeight,
      height_MT: "",
      Leftheight_MT: "",
      CategoryList: [],
      LecturerList: [],
      adBannerList: [],
      //搜索相关
      search_keyword: "", //keywork
      selectCategory: null, //cateId
      search_orderBy: "",
      habitationType: 0, //0未设置  10国内  20海外
      collectionNum: 0,
      currentPage: 1,
      pageSize: 10,
      totalListNum: 0,
      isFirstSetup: true,
      rightImgType: "3",

      loading: false,
      finished: false,
      refreshing: false,
      navLevel: 3,
      needUpdate: false,
    };
  },

  methods: {
    // PCtab切换
    // event_navToPage_pc(type, info) {
    //   var navIndex = type - this.navLevel;
    //   if (type == 1) {
    //     //华章书院
    //     //测试代码:
    //     navIndex += 1;
    //     this.$router.go(navIndex);
    //   } else if (type == 2) {
    //     //华章师资   / 热门
    //     this.$router.go(navIndex);
    //   } else if (type == 3) {
    //     //讲师信息
    //     this.$router.go(navIndex);
    //   }
    // },
        event_navToPage_pc(type, info) {
      if (type == 1) {
        //华章书院
        //测试代码:
        this.$router.push({
          path: "teachers",
          query: {},
        });
      } else if (type == 2) {
        //华章师资   / 热门
        this.$router.push({
          path: "GuestList",
          query: {},
        });
      } else if (type == 3) {
        //讲师信息
        this.$router.push({
          path: "GuestDetail",
          query: {
            id: info || "",
          },
        });
      }
    },

    handleSizeChange(e) {
      this.currentPage = e;
      this.LecturerList = [];
      this.onLoad();
    },

    onLoad() {
      //网络加载
      if (this.refreshing) {
        this.refreshing = false;
      }

      var that = this;
      if (this.finished) {
        return;
      }
      var data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        userId: this.userId,
      };

      this.AjaxTeacher(ApiUrl.getFavoriteList, data, function(res) {
        that.currentPage = res.currentPage;
        if (that.currentPage == 1) that.LecturerList = [];
        var tempArray = that.LecturerList;
        tempArray = tempArray.concat(res.rows);
        that.LecturerList = tempArray;

        that.totalListNum = res.total;
        that.loading = false;
        that.finished = that.LecturerList.length == res.total;
        if (!that.finished) that.currentPage += 1;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    setupAjaxData() {
      var that = this;
    },
    navRightClick() {
      this.rightImgType = this.rightImgType == "3" ? "4" : "3";
      this.hideImg = !this.hideImg;
    },
    getLecturerList() {
      this.onLoad();
    },

    event_lecturerItemClick(item) {
      var name = item.name || "嘉宾";
      var id = item.id || "";
      if (id == "") {
        console.log("嘉宾不能为空");
        return;
      }
      this.selectCategory = item;
      var url = "GuestDetail";
      var query = {
        name: name,
        id: id,
      };
      //跳转页面
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
    event_delBtnClick(item, i) {
      var that = this;
      if (!this.userId) {
        this.$alert("请登录账户");
        return;
      }
      var data = {
        userId: this.userId,
        lecturerId: item.id,
      };
      this.AjaxTeacher(ApiUrl.delFavorite, data, function(res) {
        that.LecturerList.splice(i, 1);
        this.$alert(res.message);
        localStorage.setItem("needUpdata_List", "1");
      });
    },
  },
  activated() {
    var needUpdate = localStorage.getItem("needUpdata_Collection") == "1";
    localStorage.removeItem("needUpdata_Collection");

    if (needUpdate) {
      localStorage.setItem("needUpdata_List", "1");
      this.finished = false;
      this.handleSizeChange(1);
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
    // if (!(window.self === window.top)) {
    //   //iframe打开
    //   next();
    //   return;
    // }

    // // var userId = from;
    // //
    // // from.meta.keepAlive = true;

    // var isMobile = true; //判断是否是手机
    // var flag = navigator.userAgent.match(
    //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    // );
    // // console.log(flag);
    // if (flag === null) {
    //   // console.log("pc端");
    //   isMobile = false;
    //   next({
    //     path: "/GuestDetailBg", //跳pc首页
    //   });
    //   // next();
    // } else {
    //   // console.log("移动端");
    //   isMobile = true;
    //   next();
    // }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "GuestList") {
      from.meta.keepAlive = false;
    } else {
      from.meta.keepAlive = true;
    }
    next();
  },

  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    console.log(this.flag);
    if (this.flag === null) {
      console.log("pc端");
      this.rightNavShow = true;
    } else {
      console.log("移动端");
      this.rightNavShow = false;
    }
    const clientWidth = document.body.clientWidth;
    this.height_MT = this.windowHeight - 50;
    this.Leftheight_MT = this.windowHeight - 104;

    if (this.rightNavShow) {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      var app = document.getElementById("iframeBg");
      erd.listenTo(app, (element) => {
        _this.$nextTick(() => {
          var iHeight = app.offsetHeight + 11;
          iHeight = iHeight > 500 ? iHeight : 500;
          parent.window.postMessage(
            { msgType: 1, msgData: { iHeight: iHeight } },
            "*"
          );
        });
      });
    }
  },
  created() {
    document.title = "华章师资 - 华章书院";
    this.userId = localStorage.getItem("userId") || "";
    if (this.isFirstSetup) {
      this.isFirstSetup = false;
      // this.setupAjaxData();
      this.getLecturerList();
    } else {
      this.updateAjaxData();
    }
  },
};
</script>

<style lang="scss" scoped>
.allBox {
  width: 100%;
  height: 100%;
  cursor: default;
}
.nav {
  width: 100%;
  height: 129px;
  background-color: #fff;
}
.navList {
  width: 100%;
  height: 100%;
  max-width: 1157px;
  margin: 0 auto;
}
.navList ul {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navList ul li {
  width: 13%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.navList ul .moreBox {
  width: auto;
  cursor: pointer;
}
.navList ul li .icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.navList ul li span {
  font-size: 14px;
  color: #e6554d;
}
.navList ul li .moer {
  width: 20px;
  height: 20px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;

  .navPC {
    width: 100%;
    height: 75px;
    background-color: #F4F4F4;
    .nav {
      width: 100%;
      height: 100%;
      max-width: 1157px;
      margin: 0 auto;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        li {
          height: 100%;
          margin-right: 16px;
          display: flex;
          align-items: center;
          color: #363636;
          font-size: 18px;
          // font-weight: bold;
          .top {
            margin-top: 4px;
          }
        .pointer {
          cursor: pointer;
            font-weight: 700;
            color: #303133;
        }
          .pointer:hover{ 
            color:#429efd;
          }
          .color {
            color: #e6564e;
          }

          .color:hover{ 
            color:#e6564e;
            cursor: auto;
          }
        }
      }
    }
  }
}

.contentBox {
  width: 100%;
  height: 100%;
  max-width: 1157px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 2rem;
  /* padding-right: 30px;
  padding-left:39px ; */
  position: relative;
  .upTop {
    position: absolute;
    bottom: 60px;
    right: -40px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    img {
      width: 15px;
      height: 26px;
      transform: rotate(90deg);
    }
  }
  .upTop:hover {
    background: rgba(0, 0, 0, 1);
  }
  .headImg {
    height: 80px;
    width: 93%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #eeeeee;
    img {
      width: 34px;
      height: 45px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .personList {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .pagination {
      padding: 50px 0;
    }
    .pagination /deep/ .el-pagination {
      display: flex;
      justify-content: center;
    }

    .personBox {
      width: 45%;
      padding: 0.5rem 0;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .dleIMG {
        position: absolute;
        left: -35px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        cursor: pointer;
      }
      .personMessage {
        width: 65%;
        display: flex;
        flex-direction: column;
        .personNema {
          display: flex;
          align-items: center;
          .name {
            color: #2b2b2b;
            font-size: 27px;
            font-weight: bold;
          }
          img {
            width: 22px;
            height: 22px;
            object-fit: contain;
            margin-left: 12px;
          }
        }
        .user_skill {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
          img {
            width: 22px;
            height: 22px;
            object-fit: contain;
          }
          .user_skilist {
            color: #cccccc;
            font-size: 15px;
            p {
              // padding: 2px 0;
            }
          }
        }
        .user_label {
          display: flex;
          flex-wrap: wrap;

          span {
            background-color: #e0f3f7;
            color: #52c4c6;
            font-size: 15px;
            border-radius: 5px;
            margin-right: 5px;
            padding: 0 5px;
          }
        }
      }
      .headpoton {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}
.inpBox {
  width: 93%;
  height: 79px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
.inp {
  width: 447px;
  height: 45px;
  background: #f7f7f7;
  border-radius: 23px;
  background-image: url(../../../assets/img/search.png);
  background-size: 22.5px;
  background-repeat: no-repeat;
  padding-left: 98px;
  background-position: 21px;
  color: #c9c9c9;
  font-size: 18px;
}
.inp::-webkit-input-placeholder {
  color: #c9c9c9;
  font-size: 18px;
  /* font-weight: bold; */
}
.screen {
  height: 100%;
  display: flex;
  width: 40%;
  align-items: center;
  font-size: 18px;
  color: #1c1c1c;
  justify-content: space-evenly;
}
.screen img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.screenBox {
  cursor: pointer;
}
.personMesList ul::-webkit-scrollbar {
  display: none;
}
.personMesList {
  width: 88%;
  margin: 0 auto;
  height: 100%;
}
.personMesList ul {
  width: 100%;
  height: 834.961px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.personMesName {
  display: flex;
  align-items: center;
}
.personMesName .collect {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-left: 12px;
}
.personMesName .name {
  color: #2b2b2b;
  font-size: 27px;
  font-weight: bold;
}
.personMes .mes {
  color: #cccccc;
  font-size: 15px;
}
.personMesoll {
  display: flex;
  color: #cccccc;
  font-size: 15px;
  justify-content: space-between;
}
.personMesoll .rightIocn {
  width: 30px;
  height: 25px;
  object-fit: cover;
}
.sort {
  margin-top: 5px;
}
.sort span {
  display: inline-block;
  padding: 2px 10px;
  background-color: #e0f3f7;
  color: #52c4c6;
  font-size: 15px;
}
.personLi {
  width: 50%;
  display: flex;
  height: 167px;
}
.personLiBox {
  width: 80%;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  margin: 0 auto;
}
.personLiBox .personPhoto {
  width: 122px;
  height: 122px;
  border-radius: 50%;
}
.personMes {
  width: 64%;
}

/* 手机版的样式 */
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;

  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}
.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}
.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}
.content-MT {
  width: 100%;
  display: flex;
  height: 100%;
  margin-top: 50px;
  /* background-color: red; */
}
.nav_left {
  width: 30%;
  height: 100%;
  background: #f9f9f9;
  ul {
    width: 100%;
    overflow-y: auto;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      .nav_img {
        width: 3rem;
        height: 2rem;
        opacity: 0.3;
      }
      span {
        color: #afafaf;
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }
    }
    .active {
      background-color: #fff;
      span {
        color: #333333;
      }
      img {
        opacity: 1;
      }
    }
  }
}
.nav_rigth {
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
  .enshrine {
    position: fixed;
    right: 0;
    bottom: 3rem;
    width: 5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    /* background: #ffffff; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 100px 0px 0px 100px;
    font-size: 0.5rem;
    .collect {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
    }
    .name {
      margin-left: 0.3rem;
    }
    .num {
      width: 16px;
      height: 16px;
      background: #fe8608;
      display: block;
      text-align: center;
      border-radius: 50%;
      margin-left: 0.5rem;
      line-height: 1.5;
      padding: 0.1rem;
      color: #fff;
    }
  }
  .headnav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
    .headnavAC {
      display: flex;
      align-items: center;
      color: #1c1c1c;
      font-size: 15px;
      img {
        width: 12px;
        height: 8px;
        margin-left: 5px;
      }
    }
  }
  .messageList {
    padding-top: 10px;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    .advertising {
      border-radius: 16px;
      width: 90%;
      overflow: hidden;
      margin: 0 auto;
      .banner {
        width: 100%;
        height: 4rem;
        display: block;
      }
    }
    ul {
      width: 90%;
      margin: 0 auto;
      li {
        padding: 0.6rem 0;
        border-bottom: 1px solid #eeeeee;
        .itemBg {
          display: flex;
          align-items: center;
          /*        justify-content: space-between;
        align-items: center;
        position: relative; */
          .dleImg {
            width: 22px;
            height: 22px;
          }
          .item_right {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .fix_marginleft {
              margin-left: 10px;
              margin-right: -5px;
            }
            .profile-photo {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
              overflow: hidden;
              .logo {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
            .user-message {
              display: flex;
              flex-direction: column;
              font-size: 12px;
              width: 68%;
              .label {
                display: flex;
                flex-wrap: wrap;
                margin-top: 5px;
                .labelBox {
                  display: inline-block;
                  background-color: #e0f3f7;
                  color: #52c4c6;
                  font-size: 14px;
                  padding: 2px 8px;
                  margin-right: 5px;
                  border-radius: 2px;
                  height: 21px;
                }
                .labelBox2 {
                  display: inline-block;
                  background-color: #fff5e5;
                  color: #fe8608;
                  font-size: 14px;
                  padding: 2px 8px;
                  margin-right: 5px;
                  border-radius: 2px;
                  margin-bottom: 5px;
                }
              }
              .person {
                font-size: 18px;
                font-weight: bold;
                color: #2b2b2b;
                display: flex;
                align-items: center;
                .iconstate {
                  width: 0.8rem;
                  height: 0.8rem;
                  margin-left: 5px;
                }
              }
              .message {
                display: flex;
                justify-content: space-between;
                color: #cccccc;
                margin-top: 2px;
                .message-text {
                  display: flex;
                  flex-direction: column;
                  width: 90%;
                  span {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }

              .right-img {
                position: absolute;
                top: 30%;
                right: 0;
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
