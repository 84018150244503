<template>
	<div
		class="maxBox"
		ref="main"
		style="height: 100vh; overflow: auto"
		v-infinite-scroll="loadMore"
		:infinite-scroll-disabled="busy"
		:infinite-scroll-immediate-check="true"
		infinite-scroll-distance="10"
		@scroll="handleScroll"
		@click="removeInputState()">
		<BackTop
			:bottom="300"
			:right="backTopRight"
			style="position: fixed;"
			:class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
			on-click="scrollToTop()">
			<div class="top" @click="scrollToTop">
				<div class="divg">
					<img
						style="width: 100%; height: 100%"
						src="../../assets/image/shang.png"
						alt="" />
				</div>
			</div>
		</BackTop>
		<div class="topNav">
			<div class="logipt">
				<!-- logo -->
				<div class="logoBox">
					<div class="logo_title" @click="GoToChangeTab(1)" style="cursor: pointer;">
						<h1>{{corpTitle}}</h1>
					</div>

					<!-- <router-link to="/searchpage"> -->
					<div
						class="inputs"
						style="width: 485px !important; background: #f7f7f7ff">
						<img src="../../assets/image/search.png" alt="" />
						<input
							ref="inputRef"
							class="ipus"
							style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
							confirm-type="search"
							placeholder-class="text"
							placeholder="书名、作者、关键字"
							@focus="input_focusIpt"
							v-on:keyup.enter="input_search(input_content)"
							v-model="input_content" />
						<!-- @blur="input_blurIpt" -->
						<div
							class="btnBox"
							style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
							@click="input_search()">
							搜索
						</div>
					</div>
					<div class="showContent" v-if="isInputing2">
						<div class="titleBg">
							<div class="title">热门搜索</div>
							<div class="clear"></div>
						</div>
						<div
							class="item"
							v-for="(item, index) in hotKeywords"
							:key="index"
							@click="input_search(item.title)">
							{{ item.title }}
						</div>
					</div>
					<!-- </router-link> -->
				</div>
				<div>
					<router-link to="/yunbook" 
					style="display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;">
						<h3 style="font-size: 18px;color: #2b2b2b;">
							我的书架
						</h3>
						<img
							class="hoveimg"
							style="width:24px;height:100%; margin:0 0 5px 5px;"
							src="../../assets/image/lishi.png"
							alt="" />
					</router-link>
				</div>
				<!-- logins -->
				<!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
				<!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
			</div>
			<!-- tab栏 -->
			<div
				class="tablistBg"
				v-if="isShowNav"
				style="height:70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);"></div>
			<div
				class="tablistBg"
				:class="isShowNav ? 'topNavShow' : ''"
				style="margin-top: 0px">
				<div class="tablist">
					<div
						class="tab-as"
						v-for="(item, index) in tabList"
						:key="index"
						:class="{ tabActive: item.product_type == num }"
						@click="GoToChangeTab(item.product_type)"
						style="color: #333333">
						{{ item.title }}
						<span
							v-if="item.product_type == 11"
							:class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
							style="color:#47CD96;padding-left:5px">LIVE</span>
					</div>
				</div>
			</div>
			<div class="hovers" ref="hoves">
				<div class="vipmoy">
					<img class="viplo" src="../../assets/image/viplogo.png" alt="" />
					<span class="spanv">[续费VIP]</span>
				</div>
				<div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
				<div class="times">2021.08.10 到期</div>
				<div class="fenge"></div>
				<div class="fosnam">
					<p>我的订单</p>
					<p>我的卡券</p>
				</div>
				<div class="fonbot">
					<p>物流查询</p>
					<p>我的企业</p>
					<p>关于我们</p>
					<p>作者助手</p>
				</div>
				<div class="nect">退出</div>
			</div>
		</div>
		<!-- content -->

		<div class="course">
			<div class="banner" v-if="banners && banners.length>0">
				<el-carousel
					:interval="5000"
					type="card"
					height="375px"
					indicator-position="none">
					<el-carousel-item v-for="(item, index) in banners" :key="index">
						<img
							style="width: 100%; height: 100%; border-radius: 4px"
							:src="item.img_url_pc"
							alt=""
							@click="GoToDetail(item.skip_type, item.skip_target)" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="coten">
				<h3>{{ saleList.title }}</h3>
				<span class="span1" @click="goToAllBookPage()">全部 ></span>
				<span> </span>
				<div class="maste" v-if="saleList != null">
					<div
						class="masDtl"
						v-for="(item, index) in saleList.list"
						:key="index"
						@click="goToDetailPage(item)">
						<div style="" class="divL">
							<img
								style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
              "
								:src="item.img_url_compressed"
								alt="" />
						</div>
						<div class="divR">
							<div class="tils">{{ item.title }}</div>
							<div class="outher">{{ item.author }}</div>
							<!-- <div class="intros">{{ item.summary }}</div> -->
							<div class="tims">
								<div class="divs">
									<img
										style="width: 100%; height: 100%"
										src="../../assets/image/shijian.png"
										alt="" />
								</div>
								{{ item.resource_length }}
							</div>
							<div class="botmoy">
								<!-- <span class="span2">￥199.00</span>
              <span class="span3">￥389.00</span>
              <div class="skm">试看</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="dlwig" v-for="(menu, menuIndex) in listData" :key="menuIndex">
				<div class="dlTop">
					<span class="span1">{{
            menu.id == 0 ? "所有课程" : menu.title
          }}</span>
					<div
						class="span2"
						:class="menu.sortType != 2 ? 'spanActive' : ''"
						@click="changeOrderBy(menu, menuIndex, 1)">
						最新
					</div>
					<div
						class="span3"
						g
						:class="menu.sortType == 2 ? 'spanActive' : ''"
						@click="changeOrderBy(menu, menuIndex, 2)">
						最热
					</div>
					<div v-if="menu.id == 0" class="span4" @click="goToAllBookPage()">
						更多 >
					</div>
				</div>
				<div class="dlcont">
					<div
						class="confx"
						v-for="(item, indexP) in menu.product.list"
						:key="indexP"
						@click="goToDetailPage(item)">
						<div class="div1">
							<img
								style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
              "
								wid
								:src="item.img_url_compressed"
								alt="" />
							<!-- <img
                class="mols"
                src="../../assets/image/icon-listen.png"
                alt=""
              /> -->
						</div>
						<div class="div2">
							<p class="p1">{{ item.title }}</p>
							<span class="p2">{{ item.author }}</span>
						</div>
					</div>
				</div>
			</div>
			<foo-ter />
		</div>
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				num: 4,
				tabList: [],
				productTypeData: {},
				busy: false,
				banners: [],
				saleList: [],
				listData: [],
				//lecList: [],
				search: {
					id: "0",
					product_type: 4,
					page_index: 1,
					page_size: 10,
					order_by: "hot_at:desc",
					totalPage: 1,
				},
				selectSortType: 2,
				busy: false,
				isShowNav: false,
				input_content: "",
				hotKeywords: [], //热门搜索
				isInputing2: this.isInputing,
				isShowScrollBtn: false,
				backTopRight: 200,
				isShowScrollBtn2: false,
				corpTitle:''
			};
		},
		components: {
			fooTer,
		},
		created() {
			var that = this;
			document.title = "课程";
			this.setupBannerList();
			this.setupSaleList();
			this.listDataGet();
			this.setupList();
			//this.getlecList();
			this.hotKeywordsGet();
			// this.changeSelectListType(1);
			
			var corp = localStorage.getItem("CorpInfo");
			if(corp){
				that.corpTitle = corp;
			}

			window.addEventListener("resize", function() {
				that.css_caculateBackTopRight();
			});
			this.css_caculateBackTopRight();
		},
		methods: {
			GoToIndex() {
				if (this.tabList && this.tabList.length > 0) {
					this.GoToChangeTab(this.tabList[0].product_type)
				}
			},
			css_caculateBackTopRight() {
				var screenWidth = document.body.offsetWidth;
				if (screenWidth < 1200) {
					this.isShowScrollBtn2 = false;
				} else {
					this.isShowScrollBtn2 = true;
				}
				var right = (screenWidth - 1157) / 2 - 30;
				this.backTopRight = right || 200;
				// console.log(screenWidth);
			},

			scrollToTop() {
				this.$refs.main.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			removeInputState() {
				this.isInputing = false;
				this.input_blurIpt();
				this.$forceUpdate();
			},
			hotKeywordsGet() {
				var that = this;
				this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
					if (data && data.length > 0) {
						that.hotKeywords = data;
					}
				});
			},
			input_search(title) {
				if (title && title != "") this.input_content = title;
				this.$refs.inputRef.blur();
				this.isInputing2 = false;
				var url = "/SearchPage";
				var query = { inputStr: this.input_content };
				this.$router.push({
					path: url,
					query: query
				});
				/*
				let routeUrl = this.$router.resolve({
				  path: url,
				  query: query,
				});
				window.open(routeUrl.href, "_blank");
				*/
				this.input_content = "";
			},
			input_focusIpt() {
				setTimeout(() => {
					this.isInputing2 = true;
					this.$refs.inputRef.focus();
					this.$forceUpdate();
				}, 200);
			},
			input_blurIpt() {
				  this.isInputing2 = false;
				  if (this.$refs.inputRef) {
					this.$refs.inputRef.blur();
				  }
				  // setTimeout(() => {
				  // this.isInputing2 = true;
				  //   this.$forceUpdate();
				  // }, 500);
			},
			handleScroll(e, item) {
				let scrollY = e.target.scrollTop;
				console.log(e.target.scrollTop);
				var height = 90;
				if (scrollY >= height) {
					this.isShowNav = true;
					this.isInputing = false;
					this.input_blurIpt();
				} else {
					this.isShowNav = false;
				}
				this.isShowScrollBtn = scrollY > 200;
				this.$forceUpdate();
			},
			loadMore() {},
			getlecList() {
				var that = this;

				this.AjaxTeacher(apiUrl.getLecturerList, {}, function(res) {
					that.lecList = res.currentPage;
				});
			},
			loadMore() {},
			setupList() {
				var that = this;
				this.tabList = [];
				var url = apiUrl.shuyuan_navbar_get;
				var param = { client_type: 2 };
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (item.product_type == 11) {
							item.title = "直播";
						}
						that.tabList.push(item);
					});
					that.productTypeData = data;
				});
			},
			handellet() {
				if (this.$refs.hoves.style.display == "none") {
					this.$refs.hoves.style.display = "block";
				} else if ((this.$refs.hoves.style.display = "block")) {
					this.$refs.hoves.style.display = "none";
				}
			},
			//Banner
			setupBannerList() {
				var that = this;
				var url = apiUrl.banner_get;
				var param = { product_type: this.search.product_type };
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (!item.img_url_pc || item.img_url_pc == "") {
							item.img_url_pc = item.img_url;
						}
					});
					that.banners = data;
				});
			},
			//Sale List
			setupSaleList() {
				var that = this;
				var url = apiUrl.sale_list_get;
				var param = { product_type: this.search.product_type };
				this.Ajax(url, param, (data) => {
					if (data) {
						that.saleList = data;
					}
				});
			},
			listDataGet() {
				var that = this;
				var url = apiUrl.menu_list_get;
				var param = { product_type: this.search.product_type };
				this.Ajax(url, param, (data) => {
					that.listData = data;
					for (var i = 0; i < that.listData.length; i++) {
						that.listData[i].product = {};
						that.setupListData(i);
					}
				});
			},
			//ListData
			setupListData(index) {
				var that = this;
				// that.search.page_index = index;
				if (!that.listData[index].order_by) {
					that.listData[index].order_by = "start_at:desc";
				}

				var url = apiUrl.menu_product_list_get;
				var param = {
					id: that.listData[index].id,
					product_type: that.search.product_type,
					page_index: that.search.page_index,
					page_size: 10,
					order_by: that.listData[index].order_by,
				};
				this.Ajax(url, param, (data) => {
					that.search.totalPage = data.count;
					if (data && data.list.length > 0) {
						if (that.search.page_index == 1) {
							that.listData[index].product = data;
						} else {
							var list = that.listData[index].product.list;
							that.listData[index].product = data;
							that.listData[index].product.list = list.concat(
								that.listData[index].product.list
							);
						}
					}
					that.busy = false;

					//渲染
					that.$forceUpdate();
				});
			},
			//Page: Detail
			goToDetailPage(item) {
				this.GoToDetail(this.search.product_type, item.id);
			},
			//Page: AllBook
			goToAllBookPage() {
				localStorage.setItem("toPagePath", "course");
				var query = {
					type: this.search.product_type,
					type2Sort: 1,
				};
				this.GoToAllBook(query);
			},
			//Click: Sort
			changeSelectListType(type) {
				this.selectSortType = type;
				if (type == 1) {
					this.search.order_by = "start_at:asc";
				} else if (type == 2) {
					this.search.order_by = "hot_at:desc";
				}
				this.setupListData(1);
			},
			changeOrderBy(menuItem, index, sortType) {
				menuItem.sortType = sortType;
				if (menuItem.sortType == 1) {
					menuItem.order_by =
						menuItem.order_by != "start_at:desc" ?
						"start_at:desc" :
						"start_at:asc";
				} else {
					menuItem.order_by =
						menuItem.order_by != "hot_at:desc" ? "hot_at:desc" : "hot_at:asc";
				}
				this.setupListData(index);
			},
			//Next PageList
			loadMore() {
				if (this.busy) return;
				if (this.search.page_index + 1 > this.search.totalPage) {
					return;
				} else {
					this.search.page_index += 1;
				}
				this.busy = true;
				this.setupListData(0);
			},
		},
	};
</script>

<style lang="scss">
	.maxBox {
		width: 100%;
		min-width: 1157px;
		cursor: default;
		// background: #d8e4f8;

		.top {
			width: 70px;
			height: 70px;
			background: #000000;
			border-radius: 8px;
			opacity: 0.19;
			display: flex;
			align-items: center;

			.divg {
				width: 32px;
				height: 18px;
				margin: 0 auto;
			}
		}

		.topNav {
			width: 100%;
			// height: 173px;
			border: 1px solid #fff;
			background: #fff;
			position: relative;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.hovers {
				width: 221px;
				height: 506px;

				z-index: 20;
				position: absolute;
				right: 290px;
				top: 90px;
				background: #fff;

				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
				display: none;

				.vipmoy {
					width: 180px;
					height: 20px;

					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0 auto;
					margin-top: 14px;

					.viplo {
						width: 34px;
						height: 15px;
					}

					.spanv {
						width: 65px;
						height: 20px;
						font-size: 14px;
						font-weight: 500;
						color: #e6544c;
					}
				}

				.names {
					width: 171px;
					height: 25px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin: 0 auto;
					font-size: 18px;
					font-weight: 500;
					color: #333333;
					margin-top: 8px;
				}

				.times {
					width: 123px;
					height: 20px;
					margin-left: 22px;
					margin-top: 9px;
					font-size: 14px;
					font-weight: 500;
					color: #999999;
				}

				.fenge {
					width: 179px;
					height: 1px;
					background: #eeeeee;
					margin: 0 auto;
					margin-top: 18px;
					margin-bottom: 18px;
				}

				.fosnam {
					width: 179px;
					height: 100px;
					// border: 1px solid red;
					margin: 0 auto;
					border-bottom: 1px solid #eeeeee;

					p {
						width: 76px;
						height: 25px;
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						line-height: 25px;
						letter-spacing: 1px;
						margin-top: 20px;
					}
				}

				.fonbot {
					width: 179px;
					height: 190px;
					// border: 1px solid;
					margin: 0 auto;
					border-bottom: 1px solid #eeeeee;

					p {
						width: 76px;
						height: 25px;
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						line-height: 25px;
						letter-spacing: 1px;
						margin-top: 20px;
					}
				}

				.nect {
					width: 179px;
					height: 57px;
					margin: 0 auto;
					line-height: 57px;
					font-size: 18px;
					font-weight: 500;
					color: #333333;
				}
			}

			.logipt {
				width: 1157px;
				height: 60px;

				margin: 0 auto;
				margin-top: 27px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.logoBox {
					height: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;

					.logo_title {
						display: inline-block;

						h1 {
							font-size: 30px;
						}
					}

					.inputs {
						width: 140px;
						height: 34px;
						background: #f7f7f7;
						border-radius: 25px;
						margin-left: 30px;
						text-align: center;
						font-size: 20px;
						line-height: 50px;
						display: flex;
						align-items: center;

						img {
							width: 17px;
							height: 17px;
							margin-left: 12px;
							margin-right: 12px;
						}

						span {
							font-size: 16px;
							font-weight: 500;
							color: #c9c9c9;
						}
					}

					


					.showContent {
						position: absolute;
						top: 50px;
						left: 320px;
						width: 452px;
						// height: 216px;
						background: white;
						z-index: 99999;
						padding: 18px;

						.titleBg {
							display: flex;
							justify-content: space-between;
							margin-bottom: 3px;

							.title {
								width: 64px;
								height: 22px;
								font-size: 16px;
								font-weight: 500;
								color: #2b2b2b;
								line-height: 22px;
							}

							.clear {
								width: 32px;
								height: 22px;
								font-size: 16px;
								font-weight: 500;
								color: #e6564e;
								line-height: 22px;
							}
						}

						.item {
							height: 22px;
							font-size: 16px;
							font-weight: 500;
							color: #999999;
							line-height: 22px;
							margin-top: 10px;
							cursor: pointer;
						}
					}
				}

				.logins {
					width: 95px;
					height: 29px;
					font-size: 21px;
					font-weight: 500;
					color: #e6564e;
				}

				.users {
					width: 210px;
					height: 47px;

					display: flex;
					align-items: center;
					justify-content: space-between;

					div {
						font-size: 21px;
						font-weight: 600;
						color: #e6564e;
						line-height: 25px;
						width: 130px;
						height: 25px;
						// border: 1px solid red;
						border-right: 3px solid #d8d8d8;
					}
				}
			}

			.tablistBg {
				width: 100%;
				background: white;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.topNavShow {
				position: fixed !important;
				z-index: 99 !important;
				top: 0 !important;
				// left: 0 !important;
				// left: calc((100% - 1157px) / 2) !important;
				background: white;
				margin-top: 0 !important;
				// width: calc(100% - 15px);
				width: 100%;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			}

			.tablist {
				height: 70px;
				width: 1157px;
				margin: 0 auto;
				display: flex;
				justify-content: space-around;
				align-items: center;
				font-size: 21px;
				font-weight: 400;
				color: #bdbdbd;
				position: relative;
				margin-top: 0px !important;

				.tabActive {
					font-size: 24px;
					font-weight: 500;
					color: #e6564e !important;
					padding-bottom: 5px;
					// border: 1px solid red;
					text-align: center;

					display: flex;
					justify-content: center;
				}

				.tab-as {
					text-align: center;
					display: flex;
					justify-content: center;
				}

				.tab-as:hover:before {
					content: "";
					width: 40px;
					border-radius: 5px;
					height: 3px;
					position: absolute;
					text-align: center !important;
					background-color: #e45754;
					display: block;
					bottom: 0;
				}

				.tabActive2 {
					margin-left: 0;
				}

				.unTabActive2 {
					margin-left: -6px;
				}

				.tabActive::before {
					content: "";
					width: 40px;
					border-radius: 5px;
					height: 3px;
					position: absolute;
					text-align: center !important;
					// border-bottom: 4px solid #e6564e;
					background-color: #e45754;
					display: block;
					bottom: 0;
				}
			}

			.tablist:hover {
				cursor: pointer;
			}
		}

		.course {
			width: 100%;
			min-height: 800px;
			background: #f7f7f7;

			// border: 1px solid blue;
			.banner {
				height: 420px;
				width: 1157px;
				border: 1px solid #fff;
				background: #fff;
				margin: 0 auto;
				margin-bottom: 12px;

				.el-carousel {
					margin-top: 23px;

					.el-carousel__item {
						width: 750px;
						margin-left: -85px;
					}
				}
			}

			.coten {
				width: 1157px;
				height: 557px;
				margin: 0 auto;
				margin-top: 11px;
				background: #fff;

				h3 {
					font-size: 24px;
					color: #2b2b2b;
					font-weight: 800;
					letter-spacing: 1px;
					margin: 31px 0 0 44px;
					display: inline-block;
				}

				h3:hover {
					cursor: default;
				}

				.span1 {
					float: right;
					font-size: 18px;
					font-family: 'AlibabaPuHuiTiB';
					color: #999999;
					letter-spacing: 1px;
					margin: 38px 33px 0 0;
				}

				.span1:hover {
					cursor: pointer;
				}

				.maste {
					width: 1059px;
					height: 406px;
					margin: 0 auto;
					margin-top: 42px;
					// border: 1px solid green;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					overflow: hidden;

					.masDtl {
						width: 503px;
						height: 180px;
						margin-bottom: 40px;
						display: flex;
						justify-content: space-between;

						.divL {
							width: 135px;
							height: 100%;
							box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
							border-radius: 6px;
						}

						.divR {
							height: 180px;
							width: 338px;
							position: relative;

							// border: 1px solid yellowgreen;
							.tils {
								font-family: 'AlibabaPuHuiTiM';
								font-size: 18px;
								color: #2b2b2b;
								margin-bottom: 5px;
								letter-spacing: 1px;
							}

							.outher {
								font-size: 16px;
								color: #666666;
								margin-bottom: 5px;
								letter-spacing: 1px;
							}

							.intros {
								width: 264px;
								height: 32px;
								background: #eeeeee;
								border-radius: 8px;
								font-size: 14px;
								font-weight: 500;
								color: #666666;
								line-height: 32px;
								letter-spacing: 1px;
								margin-bottom: 5px;
								overflow: hidden;
								text-align: center;
							}

							.tims {
								display: flex;
								align-items: center;
								font-size: 14px;
								position: absolute;
								bottom: 40px;

								.divs {
									display: inline-block;
									width: 12px;
									height: 12px;
									margin-right: 11px;
									display: flex;
								}

								.span1 {
									font-size: 15px;
									color: #cccccc;
								}
							}

							.botmoy {
								display: flex;
								justify-content: space-between;
								align-items: center;

								.span2 {
									font-size: 30px;

									color: #e6564e;

									letter-spacing: 1px;
								}

								.span3 {
									font-size: 15px;
									color: #cccccc;
								}

								.skm {
									width: 134px;
									height: 41px;
									background: #f4f5f6;
									border-radius: 20px;
									text-align: center;
									font-size: 18px;
									font-weight: 500;
									color: #e6564e;
									line-height: 41px;
									letter-spacing: 1px;
								}
							}
						}
					}

					.masDtl:hover {
						cursor: pointer;

						.divR {
							.tils {
								color: #E6564E;
							}
						}
					}
				}
			}

			.dlwig {
				width: 1157px;
				background: #fff;
				margin: 0 auto;
				margin-top: 11px;
				border: 1px solid #fff;

				.dlTop {
					width: 100%;
					height: 38px;

					margin-top: 28px;
					display: flex;
					align-items: center;

					.span1 {
						margin-left: 42px;
						font-size: 24px;
						font-family: 'AlibabaPuHuiTiB';
						color: #2b2b2b;
						line-height: 38px;
						letter-spacing: 1px;
					}

					.span1:hover {
						cursor: default;
					}

					.span2 {
						font-family: 'AlibabaPuHuiTiB';
						width: 95px;
						height: 41px;
						background: #f4f5f6;
						border-radius: 20px;
						font-size: 16px;
						color: #e6564e;
						line-height: 41px;
						text-align: center;
						margin-left: 70px;
					}

					.span2:hover {
						cursor: pointer;
					}

					.span3 {
						font-family: 'AlibabaPuHuiTiB';
						width: 95px;
						height: 41px;
						background: #f4f5f6;
						border-radius: 20px;
						font-size: 16px;
						color: #e6564e;
						line-height: 41px;
						text-align: center;
						margin-left: 8px;
					}

					.span3:hover {
						cursor: pointer;
					}

					.spanActive {
						background: #e6564e;
						color: #ffffff;
					}

					.span4 {
						font-family: 'AlibabaPuHuiTiB';
						float: right;
						font-size: 18px;
						color: #999999;
						letter-spacing: 1px;
						margin-left: 630px;
					}

					.span4:hover {
						cursor: pointer;
					}
				}

				.dlcont {
					width: 1017px;
					min-height: 800px;
					margin-left: 42px;
					margin-top: 38px;

					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					.confx {
						width: 483px;

						height: 173px;
						display: inline-block;
						margin-bottom: 20px;
						display: flex;
						justify-content: space-between;
						overflow: hidden;

						.div1 {
							width: 130px;
							height: 173px;
							position: relative;
							box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

							.mols {
								width: 31px;
								height: 31px;
								position: absolute;
								top: 9px;
								right: 9px;
							}
						}

						.div2 {
							width: 328px;
							height: 173px;

							.p1 {
								font-family: 'AlibabaPuHuiTiM';
								font-size: 18px;
								color: #2b2b2b;
								display: -webkit-box;
								overflow: hidden;
								-webkit-box-orient: vertical;
								text-overflow: ellipsis;
								-webkit-line-clamp: 2;
							}

							.p2 {
								font-size: 16px;
								color: #666666;
								margin-top: 5px;
								display: -webkit-box;
								overflow: hidden;
								-webkit-box-orient: vertical;
								text-overflow: ellipsis;
								-webkit-line-clamp: 2;
							}

							.p3 {
								font-size: 16px;
								font-weight: 500;
								color: #cccccc;
								margin-top: 8px;
							}
						}
					}

					.confx:hover {
						cursor: pointer;

						.div2 {

							.p1 {
								color: #E6564E;
							}
						}
					}
				}
			}
		}
	}
</style>
