<template>
  <div class="seebook">
    <div class="seem">
      <div class="mianb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">华章书院</el-breadcrumb-item>
          <el-breadcrumb-item
            v-if="param.product_type == 1"
            :to="{ path: '/ebook' }"
            >电子书</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="param.product_type == 3"
            :to="{ path: '/aubook' }"
            >有声书</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="param.product_type == 4"
            :to="{ path: '/course' }"
            >课程</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="param.product_type == 11"
            :to="{ path: '/livebook' }"
            >直播</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="param.product_type == 12"
            :to="{ path: '/subject' }"
            >专题</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="pathObj">{{
            param.product_name
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>用户列表</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
        </el-breadcrumb>
      </div>
      <div class="amounts">
        <div class="sums">
          共 {{ detaiData.total }} 人{{ param.readType }}此{{
            param.product_typeName
          }}
        </div>
        <div
          class="aothr"
          style="overflow: auto"
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy"
          :infinite-scroll-immediate-check="true"
          infinite-scroll-distance="10"
        >
          <div
            class="tols"
            v-for="(item, index) in detaiData.list"
            :key="index"
          >
            <div class="tolft">
              <img
                :src="item.avatar"
                style="width: 100%; height: 100%; border-radius: 50%"
                alt=""
              />
            </div>
            <div class="tlrgt">
              <div class="tops">
                {{ item.nickname }}
              </div>
              <div class="osds">
                <span class="span1">{{ item.summary }}</span>
                <span class="span2">{{ item.created_at }}</span>
              </div>
            </div>
          </div>

          <div class="tols"></div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "./footer";
import apiUrl from "../js/apiUrl.js";
import comm from "../js/comm.js";
export default {
  data() {
    return {
      detaiData: {},
      page: 1,
      param: {
        product_id: "",
        product_type: "",
        product_typeName: "",
        product_name: "",
        page_index: 1,
        page_size: 10,
        order_by: "create_at:desc",
      },
      busy: false,
      pathObj: {},
    };
  },
  components: {
    fooTer,
  },
  created() {
    var option = {
      id: this.$route.query.id || "",
      product_type: this.$route.query.product_type || "",
      product_name: this.$route.query.product_name || "",
    };
    if (option) {
      this.param.product_id = option.id;
      this.param.product_type = option.product_type;
      this.param.product_name = option.product_name;

      this.setupBackRouterPath(option);
      this.setupListData();
    }
  },
  methods: {
    //ListData
    setupListData() {
      var that = this;
      var url = apiUrl.reader_list_get;
      var param = that.param;
      this.Ajax(url, param, (data) => {
        if (data) {
          if (that.param.page_index == 1) {
            that.detaiData = data;
          } else {
            var list = that.detaiData.list;
            that.detaiData = data;
            that.detaiData.list = list.concat(data.list);
          }
          that.page = data.count;
        }
      });
    },
    //Next PageList
    loadMore() {
      if (this.busy) return;
      var that = this;
      if (that.param.page_index >= this.page) return;
      this.busy = true;
      that.param.page_index += 1;
      this.setupListData();
    },
    //Router Path
    setupBackRouterPath(option) {
      if (this.param.product_type == 1) {
        this.param.readType = "阅读";
        this.param.product_typeName = "书";
        this.pathObj = {
          path: "ebookdtl",
          query: {
            id: option.id,
          },
        };
      } else if (this.param.product_type == 3) {
        this.param.readType = "听";
        this.param.product_typeName = "有声书";
        this.pathObj = {
          path: "Audtl",
          query: {
            id: option.id,
          },
        };
      } else if (this.param.product_type == 4) {
        this.param.readType = "学习";
        this.param.product_typeName = "课程";
        this.pathObj = {
          path: "couraudio",
          query: {
            id: option.id,
          },
        };
      } else if (this.param.product_type == 11) {
        this.param.readType = "学习";
        this.param.product_typeName = "直播";
        this.pathObj = {
          path: "livedtl",
          query: {
            id: option.id,
          },
        };
      } else if (this.param.product_type == 12) {
        this.param.readType = "学习";
        this.param.product_typeName = "专题";
        this.pathObj = {
          path: "subjectDetail",
          query: {
            id: option.id,
          },
        };
      }
    },
  },
};
</script>

<style lang="scss">
.seebook {
  width: 100%;
  background: #f7f7f7;
  min-width: 1157px;
  cursor: default;
  .seem {
    width: 1157px;

    margin: 0 auto;
    .mianb {
      width: 1157px;
      height: 75px;
      // border: 1px solid red;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #363636;
      .el-breadcrumb {
        font-size: 16px;
      }
      .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #e6554d !important;
      }
    }
    .amounts {
      width: 100%;
      min-height: 800px;
      border: 1px solid #fff;
      background: #fff;
      .sums {
        font-size: 24px;
        font-weight: 800;
        color: #2b2b2b;
        letter-spacing: 1px;
        margin: 55px 0 0 47px;
      }
      .aothr {
        width: 1047px;
        // height: 800px;

        margin: 0 auto;

        margin-top: 41px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .tols {
          height: 86px;
          width: 496px;
          margin-bottom: 21px;
          display: flex;
          justify-content: space-between;
          .tolft {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            margin-top: 5px;
          }
          .tlrgt {
            height: 100%;
            width: 401px;

            border-bottom: 1px solid #eeeeee;
            .tops {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              // margin-bottom: 6px;
            }
            .osds {
              height: 27px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              .span1 {
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                line-height: 27px;
              }
              .span2 {
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                line-height: 27px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
