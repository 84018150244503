<template>
	<div class="main">
		<div class="loginbox">
			<div class="bgbox">
				<img src="../../assets/image/notlogin_place.png" alt="" />
				<p>未授权或授权已过期</p>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		data() {
			return {
			};
		},
		beforeRouteEnter(to, from, next) {
			window.opener = null;
			window.open("about:blank", "_top").close();
		},
		created() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss">
	html,
	body {
		width: 100%;
		height: 100%;
		background: #f7f7f7;
	}

	.main {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;

		.loginbox {
			display: flex;
			flex-direction: row;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

			.bgbox {
				width: 1136px;
				height: 600px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: #ffffff;

				img {
					width: 300px;
					height: 150px;
				}
				
				p{
					margin-top: 22px;
					font-size: 22px;
					color: #DEDEDE;
				}
			}
		}
	}
</style>
