<template>
  <div class="floter">
    <div class="footcent">
	  <div class="cenBot">
		<div class="ulbox">
		   <ul class="ul1">
		  	<li>Copyright©&nbsp;2020&nbsp;&nbsp;华章书院</li>
		   </ul>
		</div>
	  </div>
    </div>
  </div>
</template>

<script>
import apiUrl from "../js/apiUrl.js";
import comm from "../js/comm.js";

export default {
  data() {
    return {
      isshow: false,
    };
  },
  created() {
	  this.setupCrop();
  },
  methods: {
    handelpop() {
      this.isshow = false;
    },
    popblock() {
      this.isshow = true;
    },
	setupCrop(){
		var that = this;
		var token = localStorage.getItem("CorpUserInfo");
		var corp = localStorage.getItem("CorpInfo");
		if (token && !corp) {
			this.Ajax(apiUrl.user_corp2_get, { token : token,client_type:2 }, (data) => {
			  if (data) {
			    localStorage.setItem("CorpInfo",data.title)
				var title = document.title;
				document.title = title;
				console.log(title)
			  }
			});
		} 
	},
  },
};
</script>

<style lang="scss" scoped>
@import url(../assets/css/footer.css);
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  cursor: default;
  .erwem {
    width: 281px;
    height: 344px;
    background: #fff;
    margin: 200px auto;
    border: 1px solid #fff;
    position: relative;
    .mers {
      width: 189px;
      height: 198px;
      margin: 0 auto;
      margin-top: 52px;
      // border: 1px solid red;
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
    }
    .imgas {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
</style>
