<template>
  <div class="cosdtl">
    <div class="costcnt">
      <div class="mianb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">华章书院</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/subject' }"
            >专题</el-breadcrumb-item
          >
          <el-breadcrumb-item class="fonaa">{{
            detailData.title
          }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
        </el-breadcrumb>
      </div>
      <div class="banns">
        <div class="topMg">
          <img
            style="width: 100%; height: 100%"
            :src="detailData.img_url"
            alt=""
          />
        </div>
        <!-- <div class="adds">
          <img
            style="width:100%;height:100%"
            src="../../assets/image/icon_7.png"
            alt=""
          />
        </div> -->
        <div class="aotms">
          {{ detailData.summary }}
        </div>
      </div>
      <div class="haml"
	  v-infinite-scroll="loadMore"
	  :infinite-scroll-disabled="busy"
	  :infinite-scroll-immediate-check="true"
	  infinite-scroll-distance="10"
	  >
        <div class="clsify">
          <div
            @click="setupSubDetailList(1, 1, 0)"
            class="cls1"
            :class="ac == 0 ? 'active' : ''"
            v-show="
              detailData.products && changeSelectTab(detailData.products[0], 1)
            "
          >
            电子书
          </div>
          <div
            @click="setupSubDetailList(1, 3, 1)"
            class="cls1"
            :class="ac == 1 ? 'active' : ''"
            v-show="
              detailData.products && changeSelectTab(detailData.products[1], 3)
            "
          >
            有声书
          </div>
          <div
            @click="setupSubDetailList(1, 4, 2)"
            class="cls1"
            :class="ac == 2 ? 'active' : ''"
            v-show="
              detailData.products && changeSelectTab(detailData.products[2], 4)
            "
          >
            课程
          </div>
        </div>
        <div class="monsl">
          <div
            class="mosot"
            v-for="(iem, ims) in productList"
            :key="ims"
            @click="gotoDetailPage(iem.product_type, iem.id)"
          >
            <div class="imgbx">
              <img
                style="width: 100%; height: 100%; border-radius: 5px"
                :src="iem.img_url_compressed"
                alt=""
              />
              <img
                v-if="iem.product_type == 3"
                class="imkn"
                src="../../assets/image/icon-listen.png"
                alt=""
              />
            </div>
            <div class="masRt">
              <div class="tlos">{{ iem.title }}</div>
              <div class="movs">{{ iem.author }}</div>
              <!-- <p class="p1">
                进入21世纪，人类面临着全新的机遇与挑战，超级技术和全球化
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../components/footer";
import ApiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      ac: 0,
      id: "",
	  busy: false,
      detailData: {},
      productListSearch: {
        group_id: "",
        product_type: 1,
        page_index: 1,
        page_size: 10,
        order_by: "start_at:asc",
      },
      productList: [],
      productCount: 0, //产品数量
      pageCount: 1,
    };
  },
  components: {
    fooTer,
  },
  created() {
    document.title = "专题 - 华章书院";
    this.id = this.$route.query.id || "";
    this.setupDetail();
  },

  methods: {
    //Detail
    setupDetail() {
      var that = this;
      var url = ApiUrl.group_detail_get;
      var param = { group_id: that.id };
      this.Ajax(url, param, (data) => {
        that.detailData = data;
        document.title = data.title + " - 专题 - 华章书院";
        that.productListSearch.group_id = that.id;
        if (data.products) {
          for (var i = 0; i < data.products.length; i++) {
            var item = data.products[i];
            if (item.product_count > 0) {
              if (item.product_type == 1) {
                that.setupSubDetailList(1, 1, 0);
                break;
              }

              if (item.product_type == 3) {
                that.setupSubDetailList(1, 3, 1);
                break;
              }

              if (item.product_type == 4) {
                that.setupSubDetailList(1, 4, 2);
                break;
              }
            }
          }
        }
      });
    },
    //Sub Detail
    setupSubDetailList(index, product_type, i) {
      var that = this;
      if (!index || index <= 0) {
        index = 1;
      }
      if (i >= 0) that.ac = i;
      that.productListSearch.page_index = index;
      if (product_type) {
        that.productListSearch.product_type = product_type;
      }
      that.productListGet();

      if (that.detailData.products) {
        for (var i = 0; i < that.detailData.products.length; i++) {
          var item = this.detailData.products[i];
          if (item.product_count > 0) {
            if (item.product_type == product_type) {
              that.productCount = item.product_count;
            }
          }
        }
      }
    },
    //Product Detail
    productListGet() {
      var that = this;
      if (that.productListSearch.page_index == 1) {
        that.pageCount = 1;
        that.productList = [];
      }
      var url = ApiUrl.group_product_list_get;
      var param = that.productListSearch;
      this.Ajax(url, param, (data) => {
        that.pageCount = data.count;
        if (that.productListSearch.page_index == 1) {
          that.productList = data.list;
          console.log(that.productList);
        } else {
          that.productList.push.apply(that.productList, data.list);
        }
      });
    },
    //Page: Detail
    gotoDetailPage(type, id) {
      this.GoToDetail(type, id);
    },
    //Click: SelectTab
    changeSelectTab(item, product_type) {
      var result = false;
      if (item) {
        if (item.product_count > 0 && item.product_type == product_type) {
          result = true;
        }
      }
      return result;
    },
	//Next PageList
	loadMore() {
	  if (this.busy) return;
	  this.busy = true;
	  this.productListSearch.page_index = this.productListSearch.page_index + 1
	  this.productListGet();
	},
  },
};
</script>

<style lang="scss">
.cosdtl {
  width: 100%;
  background: #f7f7f7;
  min-width: 1157px;
  cursor: default;
  .costcnt {
    width: 1157px;

    margin: 0 auto;
    .mianb {
      width: 1157px;
      height: 75px;
      // border: 1px solid red;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #363636;
      .el-breadcrumb {
        font-size: 16px;
      }
      .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #e6554d !important;
      }
    }
    .banns {
      width: 100%;
      height: 591px;
      // border: 1px solid burlywood;
      position: relative;
      background: #fff;
      .topMg {
        width: 1040px;
        height: 391px;
        margin: 26px 0 0 37px;
        display: inline-block;
      }
      .adds {
        width: 29px;
        height: 29px;
        // border: 1px solid red;
        position: absolute;
        top: 32px;
        right: 29px;
      }
      .aotms {
        margin: 45px 0 0 37px;
        width: 1040px;
        height: 102px;
        font-size: 20px;
        font-weight: 500;
        color: #666666;
        line-height: 48px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .haml {
      width: 100%;
      //   height: 610px;
      background: #fff;
      border: 1px solid #fff;
      margin-top: 17px;
      .clsify {
        margin: 29px 0 0 43px;
        .cls1 {
          width: 82px;
          height: 32px;
          margin-right: 20px;
          background: #f5f5f5;
          border-radius: 17px;
          display: inline-block;
          text-align: center;
          line-height: 32px;

          font-size: 14px;
          font-weight: 500;
          color: #999999;
        }
        .active {
          background: #fff;
          border: 1px solid #e6564e;
          color: #e6564e;
        }
        .cls1:hover {
          cursor: pointer;
        }
        .acitve {
          background: #fff;
          border: 1px solid #e6564e;
          color: #e6564e;
        }
      }
      .monsl {
        width: 1024px;
        min-height: 220px;
        margin: 43px 0 0 48px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .mosot {
          width: 455px;
          height: 160px;

          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;

          .imgbx {
            width: 121px;
            height: 100%;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
            position: relative;
            .imkn {
              width: 31px;
              height: 31px;
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
          .masRt {
            height: 160px;
            width: 315px;
            .tlos {
              font-size: 18px;
              font-weight: 500;
              color: #2b2b2b;
              letter-spacing: 1px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 9px;
            }
            .movs {
              font-size: 16px;
              font-weight: 500;
              color: #666666;
              margin-bottom: 12px;
            }
            .p1 {
              font-size: 16px;
              font-weight: 500;
              color: #cccccc;
              line-height: 23px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
        .mosot:hover {
          cursor: pointer;
					.masRt {
						.tlos {
												color: #E6564E;
						}
					}
        }
      }
    }
  }
}
</style>
