<template>
  <div class="maxBox" ref="main">
    <BackTop
      :bottom="300"
      :right="backTopRight"
      class="boska"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>

    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
          <div class="logo_title" @click="GoToChangeTab(1)" style="cursor: pointer;">
          	<h1>{{corpTitle}}</h1>
          </div>

          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
		<div>
			<router-link to="/yunbook" 
			style="display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;">
				<h3 style="font-size: 18px;color: #2b2b2b;">
					我的书架
				</h3>
				<img
					class="hoveimg"
					style="width:24px;height:100%; margin:0 0 5px 5px;"
					src="../../assets/image/lishi.png"
					alt="" />
			</router-link>
		</div>
		
		

        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height: 70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            class="tab-as"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: item.product_type == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96;padding-left:5px"
              >LIVE</span
            >
          </div>
        </div>
      </div>
      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->
  </div>
</template>

<script>
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
// import homepage from "../Homepage/Homepage.vue";
export default {
  props: ["isShowNav", "isInputing", "isShowScrollBtn"],
  data() {
    return {
      num: 0,
      tabList: [],
      productTypeData: {},
      busy: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      backTopRight: 200,
      isShowScrollBtn2: false,
	  isGoIndex:false,
      // isShowNav: false,
	  corpTitle:''
    };
  },
  //   components: {
  //     Homepage,
  //   },

  created() {
	  
	var option = {
		token: this.$route.query.token || "",
	};
	this.CheckCorpAccount(option);
	
	var that = this;
    var toPath = localStorage.getItem("toPagePath");
    if (toPath && toPath != "") {
      this.setupCurrentIndex(toPath);
    }
    this.setupList();
    this.hotKeywordsGet();
	
	var corp = localStorage.getItem("CorpInfo");
	if(corp){
		that.corpTitle = corp;
	}
	
    window.addEventListener("resize", function() {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  watch: {
    $route(to, from) {
		var toPath = to.path.toLowerCase();
		localStorage.setItem("toPagePath", toPath);
		if (toPath && toPath != "") {
		  this.setupCurrentIndex(toPath);
		}
    },
  },
  methods: {
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
      // console.log(screenWidth);
    },
    scrollToTop() {
      this.$emit("scrollToTop");
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.$refs.inputRef.blur();
      this.isInputing2 = false;
      var url = "/SearchPage";
      var query = { inputStr: this.input_content };
     this.$router.push({
     	path: url,
     	query: query
     });
     /*
     let routeUrl = this.$router.resolve({
       path: url,
       query: query,
     });
     window.open(routeUrl.href, "_blank");
     */
      this.input_content = "";
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
      } else {
        this.isShowNav = false;
      }
      this.$forceUpdate();
    },
    setupCurrentIndex(toPath) {
      toPath = toPath.toLowerCase();
      toPath = toPath.replace("/", "");
      if (toPath == "ebook" || toPath == "ebookdtl") {
        this.num = 1;
      } else if (toPath == "aubook" || toPath == "audtl") {
        this.num = 2;
      } else if (toPath == "course" || toPath == "couraudio") {
        this.num = 3;
      } else if (toPath == "livebook" || toPath == "livedtl") {
        this.num = 4;
      } else if (
        toPath == "teachers" ||
        toPath == "guestcollection" ||
        toPath == "guestdetail" ||
        toPath == "guestlist" ||
        toPath == "invite" ||
        toPath == "inviteresult" ||
        toPath == "teachersearchpage"
      ) {
        this.num = 5;
      } else if (toPath == "subject" || toPath == "subjectdetail") {
        this.num = 6;
      }
    },
    loadMore() {},
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }
          that.tabList.push(item);
        });
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.maxBox {
  width: 100%;
  // min-width: 1157px;
  min-width: 1157px;
  cursor: default;
  // background: #d8e4f8;
  .boska {
    position: fixed;
  }
  .topNav {
    width: 100%;
    // height: 173px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .hovers {
      width: 221px;
      height: 506px;

      z-index: 20;
      position: absolute;
      right: 290px;
      top: 90px;
      background: #fff;

      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      display: none;
      .vipmoy {
        width: 180px;
        height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 14px;
        .viplo {
          width: 34px;
          height: 15px;
        }
        .spanv {
          width: 65px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #e6544c;
        }
      }
      .names {
        width: 171px;
        height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-top: 8px;
      }
      .times {
        width: 123px;
        height: 20px;
        margin-left: 22px;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }
      .fenge {
        width: 179px;
        height: 1px;
        background: #eeeeee;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .fosnam {
        width: 179px;
        height: 100px;
        // border: 1px solid red;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;

        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .fonbot {
        width: 179px;
        height: 190px;
        // border: 1px solid;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;
        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .nect {
        width: 179px;
        height: 57px;
        margin: 0 auto;

        line-height: 57px;

        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    .logipt {
      width: 1157px;
      height: 60px;

      margin: 0 auto;
      margin-top: 27px;
      display: flex;
      justify-content: space-between;
      align-items: center;
	  
      .logoBox {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // border: 1px solid red;
		
		.logo_title {
			display: inline-block;
		
			h1 {
				font-size: 30px;
			}
		}
		
        .logo {
          width: 45px;
          height: 45px;
        }
        .inputs {
          width: 140px;
          height: 34px;
          background: #f7f7f7;
          border-radius: 25px;
          margin-left: 30px;
          text-align: center;
          font-size: 20px;
          line-height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            font-weight: 500;
            color: #c9c9c9;
          }
        }
        .showContent {
          position: absolute;
          top: 50px;
          left: 320px;
          width: 452px;
          // height: 216px;
          background: white;
          z-index: 99999;
          padding: 18px;
          .titleBg {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            .title {
              width: 64px;
              height: 22px;
              font-size: 16px;
              font-weight: 500;
              color: #2b2b2b;
              line-height: 22px;
            }
            .clear {
              width: 32px;
              height: 22px;
              font-size: 16px;
              font-weight: 500;
              color: #e6564e;
              line-height: 22px;
            }
          }
          .item {
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
            line-height: 22px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .logins {
        width: 95px;
        height: 29px;
        font-size: 21px;
        font-weight: 500;
        color: #e6564e;
      }
      .users {
        width: 210px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          font-size: 21px;
          font-weight: 600;
          color: #e6564e;
          line-height: 25px;
          width: 130px;
          height: 25px;
          // border: 1px solid red;
          border-right: 3px solid #d8d8d8;
        }
      }
    }
    .tablistBg {
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topNavShow {
      position: fixed !important;
      z-index: 99 !important;
      top: 0 !important;
      // left: 0 !important;
      // left: calc((100% - 1157px) / 2) !important;
      background: white;
      margin-top: 0 !important;
      // width: calc(100% - 15px);
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tablist {
      height: 70px;
      width: 1157px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #bdbdbd;
      position: relative;
      margin-top: 0px !important;

      .tabActive {
        font-size: 24px;
        font-weight: 500;
        color: #e6564e !important;
        padding-bottom: 5px;
        // border: 1px solid red;
        text-align: center;

        display: flex;
        justify-content: center;
      }
      .tab-as {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .tab-as:hover:before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
      .tabActive2 {
        margin-left: 0;
      }
      .unTabActive2 {
        margin-left: -6px;
      }
      .tabActive::before {
        content: "";
        width: 26px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        // border-bottom: 4px solid #e6564e;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
    }
    .tablist:hover {
      cursor: pointer;
    }
  }
}
</style>
