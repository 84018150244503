<template>
  <div id="app">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
      duration="500"
      appear
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import "animate.css";
export default {
  beforeCreate() {
    document.title = "企业数字图书馆";
  },

  methods: {},
};
</script>
<style lang="scss">
/*
@font-face {
    font-family: 'AlibabaPuHuiTiR';
    src: url('./assets/fonts/Alibaba-PuHuiTi-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AlibabaPuHuiTiM';
    src: url('./assets/fonts/Alibaba-PuHuiTi-Medium.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AlibabaPuHuiTiB';
    src: url('./assets/fonts/Alibaba-PuHuiTi-Bold.otf');
    font-weight: normal;
    font-style: normal;
}

html,*{
	font-family: 'AlibabaPuHuiTiR';
}
h1,h2,h3,h4,h5,h6{
	font-family: 'AlibabaPuHuiTiB'!important;
}
*/
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f7f7f7;
}
.appPC {
  min-width: 1157px;
  overflow: auto;
}
.van-image__img {
  object-fit: cover;
  overflow: auto;
}
.el-image {
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
}
</style>
