function commAjax(url, data, successCallBack, errorCallBack, allCallBack, noLoad) {
    if (!url) return;
    var loading = 1;
    if (!noLoad) {
        loading = this.$loading({
            background: 'rgba(0, 0, 0, 0)'
        });
    }
	

	var token = localStorage.getItem("CorpUserInfo");
	if (token) {
		data.token = token;
	} else {
		 window.top.location.href = "/Login";
	}
	
    
    this.$http.post(url, data, {
        emulateJSON: true,
        timeout: 15000
    }).then(function(res) {
        var result = res.body;

        // if (result.code == undefined) {
        //     this.$message.error("服务器开小差,请稍后重试!");
        //     return;
        // }
        if (res.status == 200) {
            if (loading != 1) { loading.close(); }

            if (result.code == 0 || result.code == 40002 || result.code == 40004 || result.code ==40005 || result.code ==40006 || result.code == 60010) { //接口可访问且正常
                var returnData = result.data || [];
                if (typeof successCallBack == "function" && successCallBack) {
                    successCallBack(returnData, result.code, result.msg);
                } else {
                    if (typeof successCallBack == "string" && successCallBack) {
                        this.$message(successCallBack);
                    }
                }
            } else if (result.code != 0) {
				//token过期
                if (result.code == 40003) {
					localStorage.removeItem("CorpUserInfo")
                    window.top.location.href = "/Login";
                    return;
                }

                if (result.code == 7081) {
                    this.$message.error("你没有权限");
                    return;
                }

                if (typeof errorCallBack == "function" && errorCallBack) {
                    errorCallBack(result.data, result.code, result.msg);
                } else {
                    if (result.code > 100) {
                        result.msg = "服务器开小差,请稍后重试。";
                    }
                    this.$message.error(result.msg);
                }
            }
        }

        if (typeof allCallBack == "function" && allCallBack) {
            allCallBack();
        }



    }, 
    // function(res) {
    //     if (loading != 1) { loading.close(); }
    //     this.$message.error('服务器开小差,请稍后重试');
    //     if (typeof allCallBack == "function" && allCallBack) {
    //         allCallBack();
    //     }
    //     console.log(res.status);
    // }
    );
}

function commAjaxTeacher(url, data, successCallBack, errorCallBack, allCallBack, noLoad) {
    if (!url) return;
    var loading = 1;
    if (!noLoad) {
        loading = this.$loading({
            background: 'rgba(0, 0, 0, 0)'
        });
    }

    // var token = "";
    // try {
    //     var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
    //     if (userInfo && userInfo.token != '') {
    //         token = userInfo.token || "f9a9cbb7f51f245a7ad6c08eb449068f";
    //     } else {
    //         token = "f9a9cbb7f51f245a7ad6c08eb449068f";
    //     }
      
    // } catch (e) {
    //     token = "";
    // }

    // if (!data) {
    //     data = {
    //         token: token
    //     };
    // } else {
    //     data.token = token;
    // }
    
    this.$http.post(url, data, {
        emulateJSON: true,
        timeout: 15000
    }).then(function(res) {
        var result = res.body;

        // if (result.code == undefined) {
        //     this.$message.error("服务器开小差,请稍后重试!");
        //     return;
        // }
        if (res.status == 200) {
            if (loading != 1) { loading.close(); }

            if (result.code == 0 || !result.code) { //接口可访问且正常

                if (typeof successCallBack == "function" && successCallBack) {
                    successCallBack(result);
                } else {
                    if (typeof successCallBack == "string" && successCallBack) {
                        this.$message(successCallBack);
                    }
                }
            } else if (result.code != 0) {
                if (result.code == 7080) {
                    window.top.location.href = "/login/index";
                    return;
                }

                if (result.code == 7081) {
                    this.$message.error("你没有权限");
                    return;
                }

                if (typeof errorCallBack == "function" && errorCallBack) {
                    errorCallBack(result.data, result.code, result.msg);
                } else {
                    if (result.code > 100) {
                        result.msg = "服务器开小差,请稍后重试。";
                    }
                    this.$message.error(result.msg);
                }
            }
        }

        if (typeof allCallBack == "function" && allCallBack) {
            allCallBack();
        }



    }, 
    // function(res) {
    //     if (loading != 1) { loading.close(); }
    //     this.$message.error('服务器开小差,请稍后重试');
    //     if (typeof allCallBack == "function" && allCallBack) {
    //         allCallBack();
    //     }
    //     console.log(res.status);
    // }
    );
}

export default {
    commAjax: commAjax,
    commAjaxTeacher: commAjaxTeacher ,
}