<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="10"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      style="position: fixed;"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>

    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
         <div class="logo_title" @click="GoToChangeTab(1)" style="cursor: pointer;">
         	<h1>{{corpTitle}}</h1>
         </div>

          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
		<div>
			<router-link to="/yunbook" 
			style="display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;">
				<h3 style="font-size: 18px;color: #2b2b2b;">
					我的书架
				</h3>
				<img
					class="hoveimg"
					style="width:24px;height:100%; margin:0 0 5px 5px;"
					src="../../assets/image/lishi.png"
					alt="" />
			</router-link>
		</div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height: 70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            class="tab-as"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: item.product_type == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96;padding-left:5px"
              >LIVE</span
            >
          </div>
        </div>
      </div>

      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->

    <div class="ebook">
      <div class="banner" v-if="banners && banners.length>0">
        <el-carousel
          :interval="5000"
          type="card"
          height="375px"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in banners" :key="index">
            <img
              @click="GoToDetail(item.skip_type, item.skip_target)"
              style="width: 100%; height: 100%; border-radius: 4px"
              :src="item.img_url_pc"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="shujt" v-if="randomList.length>11">
        <div class="shuLf">
          <div
            class="fichs"
            v-for="(item, index) in randomList"
            :key="index"
            @click="goToDetailPage(item)"
          >
            <img
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
              "
              :src="item.img_url_compressed"
              alt=""
            />
          </div>
        </div>
        <div class="shuRt">
          <div class="chank" @click="setupRandomList"><div>换一批</div></div>
        </div>
      </div>
      <div
        class="dlwig"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-immediate-check="true"
        infinite-scroll-distance="10"
      >
        <div class="dlTop">
          <span class="span1">所有电子书</span>
          <div
            class="span3"
            :class="sortType == 1 ? 'labels' : ''"
            @click="changeOrderBy"
            data-sortType="1"
          >
            最新
          </div>
          <div
            class="span3"
            :class="sortType != 1 ? 'labels' : ''"
            @click="changeOrderBy"
            data-sortType="2"
          >
            最热
          </div>
          <div class="span4" @click="goToAllBookPage()">更多 ></div>
        </div>
        <div class="dlcont infinite-list-wrapper">
          <div
            class="confx"
            dis-hover
            v-for="(ite, idx) in listData"
            :key="idx"
            @click="goToDetailPage(ite)"
          >
            <div class="div1">
              <img
                style="
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 6px;
                "
                wid
                :src="ite.img_url_compressed"
                alt=""
              />
            </div>
            <div class="div2">
              <p class="p1">{{ ite.title }}</p>
              <span class="p2">{{ ite.author }}</span>
              <!-- <p class="p3">
              {{ ite.desc }}
            </p> -->
            </div>
          </div>
        </div>
      </div>
      <foo-ter class="footer" />
    </div>
  </div>
</template>

<script>
// import scrollTop from "../../js/scrollTop";
import fooTer from "../../components/footer";
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      num: 1,
      tabList: [],
      productTypeData: {},
      busy: false,
      banners: [],
      randomList: [], //换一换
      listData: [], //电子书列表
      search: {
        id: "0",
        product_type: 1,
        page_index: 1,
        page_size: 10,
        order_by: "update_at:asc",
      },

      totalPage: 1,
      sortType: 1,
      count: 10,
      busy: false,
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
	  corpTitle:''
    };
  },
  components: {
    fooTer,
  },
  created() {
    var that = this;
    document.title = "电子书";
    this.setupBannerList();
    this.setupRandomList();
    this.changeSelectSort(1, 1);
    this.setupList();
    this.hotKeywordsGet();
	
	var corp = localStorage.getItem("CorpInfo");
	if(corp){
		that.corpTitle = corp;
	}
	
    window.addEventListener("resize", function() {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
	GoToIndex(){
		if(this.tabList && this.tabList.length>0){
			this.GoToChangeTab(this.tabList[0].product_type)
		}
	},
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
      // console.log(screenWidth);
    },

    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.$refs.inputRef.blur();
      this.isInputing2 = false;
      var url = "/SearchPage";
      var query = { inputStr: this.input_content };
      this.$router.push({
      	path: url,
      	query: query
      });
      /*
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      */
      this.input_content = "";
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      if (this.$refs.inputRef) {
        this.$refs.inputRef.blur();
      }
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    loadMore() {},
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }
          that.tabList.push(item);
        });
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
    //banner
    setupBannerList() {
      var that = this;
      var url = apiUrl.banner_get;
      var param = { product_type: this.search.product_type };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (!item.img_url_pc || item.img_url_pc == "") {
            item.img_url_pc = item.img_url;
          }
        });
        that.banners = data;
      });
    },
    //Random
    setupRandomList() {
      var that = this;
      var url = apiUrl.random_product_list_get;
      var param = { product_type: this.search.product_type, client_type: 2 };
      this.Ajax(url, param, (data) => {
        that.randomList = data;
      });
    },
    //ListData
    setupListData(index) {
      var that = this;
      if (index <= 1) {
        index = 1;
        that.search.page_index = 1;
        that.totalPage = 1;
      }
      if (that.search.page_index > that.totalPage) {
        this.busy = false;
        return;
      }
      that.search.page_index = index;
      if(!that.search.page_index || that.search.page_index <=1) that.search.page_index = 1;
      var url = apiUrl.menu_product_list_get;
      var param = this.search;
      this.Ajax(url, param, (data) => {
        if (that.search.page_index == 1) that.listData = [];
        if (data && data.list.length > 0) {
          that.listData = that.listData.concat(data.list);
          that.totalPage = data.count;
        }
        that.totalPage = data.count;
        that.busy = false;
      });
    },
    //Next PageList
    loadMore() {
      if (this.busy) return;
      this.busy = true;
      this.setupListData(this.search.page_index + 1);
    },
    //Page: AllBook
    goToAllBookPage() {
      localStorage.setItem("toPagePath", "ebook");
      var query = {
        type: this.search.product_type,
        type2Sort: 1,
      };
      this.GoToAllBook(query);
    },
    //Page: Detail
    goToDetailPage(item) {
      this.GoToDetail(this.search.product_type, item.id);
    },
    //Click sort
    changeOrderBy(e) {
      var sortType = e.currentTarget.dataset.sorttype;
      this.changeSelectSort(1, sortType);
    },
    //sort Detail
    changeSelectSort(index, sortType) {
      var that = this;
      that.sortType = sortType;

      if (that.sortType == 1) {
		that.search.order_by = "start_at:asc";
      } else {
		that.search.order_by = "hot_at:desc";
      }
      that.setupListData(index);
    },
  },
};
</script>

<style lang="scss">
.maxBox {
  width: 100%;
  min-width: 1157px;
  // background: #d8e4f8;
  .showScrollBtn {
    display: block !important;
  }
  .unShowScrollBtn {
    display: none !important;
  }

  .top {
    width: 70px;
    height: 70px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.19;
    display: flex;
    align-items: center;
    .divg {
      width: 32px;
      height: 18px;
      margin: 0 auto;
    }
  }
  .topNav {
    width: 100%;
    // height: 173px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .hovers {
      width: 221px;
      height: 506px;

      z-index: 20;
      position: absolute;
      right: 290px;
      top: 90px;
      background: #fff;

      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      display: none;
      .vipmoy {
        width: 180px;
        height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 14px;
        .viplo {
          width: 34px;
          height: 15px;
        }
        .spanv {
          width: 65px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #e6544c;
        }
      }
      .names {
        width: 171px;
        height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-top: 8px;
      }
      .times {
        width: 123px;
        height: 20px;
        margin-left: 22px;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }
      .fenge {
        width: 179px;
        height: 1px;
        background: #eeeeee;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .fosnam {
        width: 179px;
        height: 100px;
        // border: 1px solid red;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;

        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .fonbot {
        width: 179px;
        height: 190px;
        // border: 1px solid;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;
        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .nect {
        width: 179px;
        height: 57px;
        margin: 0 auto;

        line-height: 57px;

        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    .logipt {
      width: 1157px;
      height: 60px;

      margin: 0 auto;
      margin-top: 27px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoBox {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .logo_title {
        	display: inline-block;
        	h1 {
        		font-size: 30px;
        	}
        }
		
        .inputs {
          width: 140px;
          height: 34px;
          background: #f7f7f7;
          border-radius: 25px;
          margin-left: 30px;
          text-align: center;
          font-size: 20px;
          line-height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            font-weight: 500;
            color: #c9c9c9;
          }
        }
        .showContent {
          position: absolute;
          top: 50px;
          left: 320px;
          width: 452px;
          // height: 216px;
          background: white;
          z-index: 99999;
          padding: 18px;
          .titleBg {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            .title {
              width: 64px;
              height: 22px;
              font-size: 16px;
              font-weight: 500;
              color: #2b2b2b;
              line-height: 22px;
            }
            .clear {
              width: 32px;
              height: 22px;
              font-size: 16px;
              font-weight: 500;
              color: #e6564e;
              line-height: 22px;
            }
          }
          .item {
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
            line-height: 22px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .logins {
        width: 95px;
        height: 29px;
        font-size: 21px;
        font-weight: 500;
        color: #e6564e;
      }
      .users {
        width: 210px;
        height: 47px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          font-size: 21px;
          font-weight: 600;
          color: #e6564e;
          line-height: 25px;
          width: 130px;
          height: 25px;
          // border: 1px solid red;
          border-right: 3px solid #d8d8d8;
        }
      }
    }
    .tablistBg {
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topNavShow {
      position: fixed !important;
      z-index: 99 !important;
      top: 0 !important;
      // left: 0 !important;
      // left: calc((100% - 1157px) / 2) !important;
      background: white;
      margin-top: 0 !important;
      // width: calc(100% - 15px);
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tablist {
      height: 70px;
      width: 1157px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #bdbdbd;
      position: relative;
      margin-top: 0px !important;
      .tabActive {
        font-size: 24px;
        font-weight: 500;
        color: #e6564e !important;
        padding-bottom: 5px;
        // border: 1px solid red;
        text-align: center;

        display: flex;
        justify-content: center;
      }
      .tab-as {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .tab-as:hover:before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
      .tabActive2 {
        margin-left: 0;
      }
      .unTabActive2 {
        margin-left: -6px;
      }
      .tabActive::before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        // border-bottom: 4px solid #e6564e;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
    }
    .tablist:hover {
      cursor: pointer;
    }
  }

  .ebook {
    width: 100%;
    background: #f7f7f7;
    border: 1px solid#fff !important;

    .footer {
      position: flex;
      margin-top: 0;
    }
    .banner {
      height: 420px;
      width: 1157px;
      border: 1px solid #fff;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 12px;
      .el-carousel {
        margin-top: 23px;
        .el-carousel__item {
          width: 750px;
          margin-left: -85px;
        }
        // .el-carousel__item:nth-child(2n) {
        //   background-color: red;
        // }

        // .el-carousel__item:nth-child(2n + 1) {
        //   background-color: yellow;
        // }
      }
    }
    .shujt {
      width: 1157px;
      height: 438px;
      background: #fff;
      margin: 0 auto;
      margin-top: 12px;
      display: flex;
      align-items: center;
      .shuLf {
        width: 936px;
        height: 369px;

        overflow: hidden;
        // margin-left: 44px;
        .fichs {
          width: 129px;
          height: 172px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          display: inline-block;
          margin-left: 27px;
          margin-bottom: 25px;
        }
        .fichs:hover {
          cursor: pointer;
        }
      }
      .shuRt {
        width: 197px;
        height: 100%;

        display: flex;
        align-items: center;
        .chank {
          width: 45px;
          height: 168px;
          background: #f4f5f6;
          border-radius: 23px;
          display: flex;
          align-items: center;
          margin: 0 auto;
          div {
            width: 18px;
            height: 75px;
            font-size: 18px;
            font-weight: 500;
            color: #e6564e;
            line-height: 25px;
            letter-spacing: 1px;
            margin: 0 auto;
          }
        }
        .chank:hover {
          cursor: pointer;
        }
      }
    }
    .dlwig {
      width: 1157px;
      background: #fff;
      margin: 0 auto;
      margin-top: 11px;
      border: 1px solid #fff;
      .dlTop {
        width: 100%;
        height: 38px;

        margin-top: 28px;
        display: flex;
        align-items: center;
        .span1 {
          margin-left: 42px;
          font-size: 24px;
          font-weight: 800;
          color: #2b2b2b;
          line-height: 38px;
          letter-spacing: 1px;
        }

        .span1:hover {
          cursor: default;
        }
        .span3 {
          width: 95px;
          height: 41px;
          background: #f4f5f6;
          border-radius: 20px;
          font-size: 18px;
          font-weight: 500;
          color: #e6564e;
          line-height: 41px;
          text-align: center;
          margin-left: 8px;
        }
        .span3:hover {
          cursor: pointer;
        }
        .span4:hover {
          cursor: pointer;
        }

        .labels {
          width: 95px;
          height: 41px;
          background: #e6564e !important;
          border-radius: 20px;
          font-size: 18px;
          font-weight: 500;
          color: #f4f5f6 !important;
          line-height: 41px;
          text-align: center;
          margin-left: 8px;
        }
        .labels:hover {
          cursor: pointer;
        }
        .span4 {
          float: right;
          font-size: 18px;
          font-weight: 500;
          color: #999999;
          margin-left: 630px;
        }
      }

      .dlcont {
        width: 1017px;
		min-height: 800px;
        margin-left: 42px;
        margin-top: 38px;

        display: flex;
        justify-content: space-between;

        flex-wrap: wrap;
        .confx {
          width: 483px;
          // background: #666666;
          height: 173px;
          display: inline-block;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
		  border: 0;
          .div1 {
            width: 130px;
            height: 173px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          }

          .div2 {
			border: 0;
            width: 328px;
            height: 173px;

            .p1 {
              font-size: 18px !important;
              font-weight: 500;
              color: #2b2b2b;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
            .p2 {
              font-size: 16px !important;
              font-weight: 500;
              color: #666666;
              margin-top: 5px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
            .p3 {
              font-size: 18px;
              font-weight: 500;
              color: #cccccc;
              margin-top: 8px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
          }
        }
        .confx:hover {
          cursor: pointer;
					.div2 {
						
											.p1 {
												color: #E6564E;
											}
					}
        }
      }
    }
    .dlwig::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
