<template>
  <div class="abouts">
    <div class="mianb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">华章书院</el-breadcrumb-item>

        <el-breadcrumb-item style="color:#E8564A">关于我们</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wecont">
      <div class="logos">
        <div class="loga">
          <img
            src="../../assets/image/huazhanglogo.png"
            style="width:100%;height:100%"
            alt=""
          />
        </div>
      </div>
      <div class="brief">
        <div class="div1" style="margin-bottom:40px">
          北京华章图文信息有限公司是机械工业出版社与美国万国图文信息有限公司在1995年合资成立的出版公司。
        </div>
        <div class="div2" style="margin-bottom:40px">
          公司出版领域涵盖科技、经济、教育，涉及计算机技术、经济、管理、心理学、本科及研究生教材等多主流出版门类，年出版新书近800种，在全国图书零售市场占有率名列前茅，其中计算机技术、经管类排名稳居三甲。
        </div>

        <div class="div3" style="margin-bottom:50px">
          华章书院是公司面向终端用户的产品消费升级平台，提供包括电子书、有声读物、在线课程、写作专栏在内的各种数字化内容及服务，同时它也是公司数字出版转型的核心业务平台，通过链接作者与用户，共同打造未来的出版业务场景，共创多元化的产业价值。
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../components/footer";
export default {
  components: {
    fooTer,
  },
};
</script>

<style lang="scss">
.abouts {
  width: 100%;
  background: #f7f7f7;
  min-width: 1157px;
  cursor: default;
  .top {
    width: 70px;
    height: 70px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.19;
    display: flex;
    align-items: center;
    border: 1px solid red;
    .divg {
      width: 32px;
      height: 18px;
      margin: 0 auto;
    }
  }
  .topNav {
    width: 100%;
    height: 173px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .hovers {
      width: 221px;
      height: 506px;

      z-index: 20;
      position: absolute;
      right: 290px;
      top: 90px;
      background: #fff;

      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      display: none;
      .vipmoy {
        width: 180px;
        height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 14px;
        .viplo {
          width: 34px;
          height: 15px;
        }
        .spanv {
          width: 65px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #e6544c;
        }
      }
      .names {
        width: 171px;
        height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-top: 8px;
      }
      .times {
        width: 123px;
        height: 20px;
        margin-left: 22px;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }
      .fenge {
        width: 179px;
        height: 1px;
        background: #eeeeee;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .fosnam {
        width: 179px;
        height: 100px;
        // border: 1px solid red;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;

        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .fonbot {
        width: 179px;
        height: 190px;
        // border: 1px solid;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;
        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .nect {
        width: 179px;
        height: 57px;
        margin: 0 auto;

        line-height: 57px;

        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    .logipt {
      width: 1157px;
      height: 60px;

      margin: 0 auto;
      margin-top: 27px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoBox {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 360px;
          height: 100%;
        }
        .inputs {
          width: 140px;
          height: 50px;
          background: #f7f7f7;
          border-radius: 25px;
          margin-left: 30px;
          text-align: center;
          font-size: 20px;
          line-height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            margin-left: 22px;
            margin-right: 18px;
          }
          span {
            font-size: 21px;
            font-weight: 500;
            color: #c9c9c9;
          }
        }
      }
      .logins {
        width: 95px;
        height: 29px;
        font-size: 21px;
        font-weight: 500;
        color: #e6564e;
      }
      .users {
        width: 210px;
        height: 47px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          font-size: 21px;
          font-weight: 600;
          color: #e6564e;
          line-height: 25px;
          width: 130px;
          height: 25px;
          // border: 1px solid red;
          border-right: 3px solid #d8d8d8;
        }
      }
    }
    .tablistBg {
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topNavShow {
      position: fixed !important;
      z-index: 99 !important;
      top: 0 !important;
      // left: 0 !important;
      // left: calc((100% - 1157px) / 2) !important;
      background: white;
      margin-top: 0 !important;
      // width: calc(100% - 15px);
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tablist {
      height: 70px;
      width: 1157px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #bdbdbd;
      position: relative;
      margin-top: 0px !important;
      .tabActive {
        font-size: 24px;
        font-weight: 500;
        color: #e6564e !important;
        padding-bottom: 5px;
        // border: 1px solid red;
        text-align: center;

        display: flex;
        justify-content: center;
      }
      .tabActive2 {
        margin-left: 0;
      }
      .unTabActive2 {
        margin-left: -6px;
      }
      .tabActive::before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        // border-bottom: 4px solid #e6564e;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
    }
    .tablist:hover {
      cursor: pointer;
    }
  }
  .mianb {
    width: 1157px;
    height: 75px;
    // border: 1px solid red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #363636;
    .el-breadcrumb {
      font-size: 16px;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: #e6554d !important;
    }
  }
  .wecont {
    width: 1157px;
    // height: 1000px;
    background: #fff;
    border: 1px solid #fff;
    margin: 0 auto;
    .logos {
      width: 100%;
      height: 133px;
      margin-top: 67px;
      margin-bottom: 53px;
      .loga {
        width: 271px;
        height: 100%;
        margin: 0 auto;
      }
    }
    .brief {
      width: 1040px;
      //   height: 1000px;
      // border: 1px solid blue;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 500;
      color: #666666;
      line-height: 48px;
    }
  }
}
</style>
