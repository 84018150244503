<template>
	<div class="yunbook">
    <div class="maxBox">
      <div class="mianb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>我的书架</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="yuncont">
        <div class="titxz">
		 <div class="leftbox">
			  <h2>我的书架</h2>
			  <ul>
				<li
				  v-for="(item, index) in navList"
				  :key="index"
				  :class="{ active: index == ac }"
				  @click="navAc(item,index)"
				>
				{{ item.title }}
				</li>
			  </ul>
		  </div>
		  <div class="rightbox">
			  <div class="guli" v-if="!hediImg" @click="hediImg=!hediImg">管理书架</div>
			  <div class="del" v-if="hediImg" @click="delsubmit">删除</div>
			  <div class="guli" v-if="hediImg" @click="delBook">完成</div>
		  </div>
        </div>
		<div class="titxz"><h2>共 {{product_count}} 种</h2></div>
        <div class="books"
		v-infinite-scroll="loadMore"
		:infinite-scroll-disabled="busy"
		:infinite-scroll-immediate-check="true"
		infinite-scroll-distance="200"
		>
		 <checkimg ref="bookViews" v-for="(item,i) in bookList" :key="i" :item="item" :hediImg="hediImg" />
        </div>
      </div>
    </div>
	<fooTer />
 </div>
</template>

<script>
import ApiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
import checkimg from "./cheCkimg";
import fooTer from "../../components/footer";


export default {
  data() {
    return {
     ac: 0,
     hediImg: true,
     navList: [],
     product_count: 0,
	 bookList: [],
	 search: {
	  	page_index: 0,
	  	page_size: 12,
	  	product_type: 1,
	  	order_by: "created_at:desc",
	  	value: ""
	  },
	  totalPage: 1,
	  checkedIds: [],
	  isNavOnloading: false,
	  busy: true
    };
  },
  components: {
  	checkimg,
	fooTer,
  },
  created(){
	  document.title = "我的书架";
	 
	  //获取云书房产品类型
	  this.productTypeDataGet();
	   //var that =this;
	  // window.addEventListener("resize", function () {
	  //   that.css_caculateBackTopRight();
	  // });
	  // this.css_caculateBackTopRight();
  },
  methods: {
	  css_caculateBackTopRight() {
	    var screenWidth = document.body.offsetWidth;
	    if (screenWidth < 1200) {
	      this.isShowScrollBtn2 = false;
	    } else {
	      this.isShowScrollBtn2 = true;
	    }
	    var right = (screenWidth - 1157) / 2 - 30;
	    this.backTopRight = right || 200;
	},
	loadMore() {
	  if (this.busy) return;
	  this.busy = true;
	  this.productDataGet(this.search.page_index + 1);
	},
	productTypeDataGet() {
		var that = this;
		this.Ajax(ApiUrl.yun_product_type_get, {}, function(data) {
			if (data) {
				for (let i = 0; i < data.length; i++) {
					data[i].name = data[i].title;
					that.navList.push(data[i]);
				}
				that.navAc(that.navList[0], 0);
				console.log(JSON.stringify(that.navList[0]))
			}
		});
	},
	navAc(val, i) {
		if (this.isNavOnloading) {
			return
		}
		this.isNavOnloading = true;
		this.hediImg = false;
		//设置当前选中分类
		this.ac = i;
		//设置当前选中的类型和页数
		this.search.product_type = val.product_type;
		this.search.page_index = 1;
		this.bookList = [];
		this.product_count = 0;
		this.productDataGet(1);
		this.busy = false;
	},
	productDataGet(index) {
		var that = this;
		if (index <= 1) {
			that.search.page_index = 1;
			that.totalPage = 1;
		}
		if (that.search.page_index > that.totalPage) {
			that.busy = false;
			return;
		}
		that.search.page_index = index;
		this.Ajax(ApiUrl.yun_search_list_get, this.search, function(data) {
			if (data && data.list) {
				that.bookList = that.bookList.concat(data.list);
				that.product_count = data.total;
				that.totalPage = data.count;
			}
			that.isNavOnloading = false;
			that.busy = false;
		}, function() {
			that.isNavOnloading = false;
		});
	},
	delBook() {
		this.hediImg = !this.hediImg
	},
	delsubmit() {
		for (let bookView of this.$refs.bookViews) {
			if (bookView.isCheck) {
				this.checkedIds.push(bookView.item.id);
			}
		}
		if (this.checkedIds.length == 0) {
			this.$message({
			   message: '请选择要删除的内容',
			   type: 'error',
			   offset: 330,
			   duration: 1000
			});
			return;
		}
		this.hediImg = false;
	
		var that = this;
		this.Ajax(ApiUrl.yun_product_del, {
			product_type: this.search.product_type,
			product_id: this.checkedIds.join(",")
		}, function(data) {
			
			// setTimeout(function() {
			// 	that.navAc(that.navList[that.ac], that.ac);
			// 	that.checkedIds = [];
			// }, 1000);
			
			var arr = new Array();
			that.checkedIds.forEach((id,num)=>{
				that.bookList.forEach((item,index)=>{
					if(id != item.id){
						arr.push(item);
					}
				})
			})
			that.product_count = parseInt(that.product_count) - that.checkedIds.length;
			that.bookList =arr;
			that.checkedIds = [];
			
			that.$message({
			   message: '删除成功',
			   type: 'success',
			   offset: 330,
			   duration: 1000
			});
		});
	}
  },
};
</script>

<style lang="scss">
.yunbook {
  width: 100%;
  background: #f7f7f7;
  min-width: 1157px;
  cursor: default;
  
  .showScrollBtn {
    display: block !important;
  }
  .unShowScrollBtn {
    display: none !important;
  }
  	
  .top {
    width: 70px;
    height: 70px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.19;
    display: flex;
    align-items: center;
    .divg {
      width: 32px;
      height: 18px;
      margin: 0 auto;
    }
  }
  .maxBox {
    margin: 0 auto;
    width: 1157px;  
    .mianb {
      width: 100%;
      height: 75px;

      font-size: 18px;
      // border: 1px solid red;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #363636;
      .el-breadcrumb {
        font-size: 16px;
      }
      .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #e6554d !important;
      }
    }
    .yuncont {
      width: 100%;
      // height: 1000px;
      border: 1px solid #fff;
      background: #fff;
	  
      .titxz {
        // width: 800px;
        height: 38px;
        // border: 1px solid #fff;
        margin: 36px 42px 0 42px;
        display: flex;
		justify-content: space-between;
        align-items:center;
        margin-top: 30px;
		
		.leftbox{
			display: flex;
			align-items: center;
		}
		.rightbox{
			display: flex;
			cursor: pointer;
		}
		.del{
			width: 82px;
			height: 34px;
			color: #e6544c;
			text-align: center;
			font-weight: 500;
			line-height: 34px;
		}
		.guli{
			width: 118px;
			height: 34px;
			background: #e6544c;
			border-radius: 17px;
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			letter-spacing: 1px;
			text-align: center;
			line-height: 34px;

		}
        h2 {
          font-size: 27px;
          font-weight: 800;
          color: #2b2b2b;
          line-height: 38px;
          letter-spacing: 1px;
        }
        ul {
          display: flex;
          list-style: none;
          margin-left: 32px;
          li {
            width: 82px;
            height: 32px;
            background: #f5f5f5;
            border-radius: 17px;
            // border: 1px solid red;
            text-align: center;
            line-height: 32px;

            font-size: 14px;
            font-weight: 500;
            color: #999999;
            margin-right: 20px;
          }
          .active {
            border: 1px solid red;
          }
        }
      }
    }
    .books {
      // border: 1px solid red;
	  width: 100%;
      margin-top: 36px;
	  min-height: 800px;
	  
      .bookBox {
        width: 130px;
        // height: 241px;
        // border: 1px solid blue;
        margin-left: 42px;
        display: inline-block;
        margin-bottom: 20px;
		position: relative;
		
		.bgBooks {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 173px;
			// height: 100%;
			background-color: rgba(0, 0, 0, .5);
		
			.box {
				position: absolute;
				bottom: 10px;
				right: 10px;
				width: 20px;
				height: 20px;
				border: 1px solid #d8d8d8;
				border-radius: 50%;
				display: flex;
		
				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
		}
		
        .bookimg {
          width: 100%;
          height: 173px;
        }
        .tits {
          // margin-top: 3px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        span {
          font-size: 15px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
}
</style>
