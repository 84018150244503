<template>
  <div id="iframeBg">
    <div class="allBox">
      <div class="nav">
        <div class="navList">
          <ul :class="{ ShowUl: isShowNavMore == true }">
            <li
              :class="item.id == selectCategory.id ? 'active' : ''"
              v-for="(item, i) in CategoryList"
              @click="event_caterogryItemClIck(item)"
              :key="i"
            >
              <img
                class="icon"
                :src="
                  item.id == selectCategory.id
                    ? item.activeIconRed
                    : item.iconRed
                "
                alt
              />
              <span :class="{ ACspan: item.id == selectCategory.id }">{{
                item.name
              }}</span>
            </li>
          </ul>
          <div class="moreBox" @click="event_pc_navMoreBtnClick()">
            <span v-show="!isShowNavMore" style="color: #e6564e">更</span>
            <span v-show="!isShowNavMore" style="color: #e6564e">多</span>
            <span v-show="isShowNavMore" style="color: #e6564e">收</span>
            <span v-show="isShowNavMore" style="color: #e6564e">起</span>
            <img
              class="more"
              :class="{ moreImg: isShowNavMore == true }"
              src="../../../assets/img/botton.png"
              alt
            />
          </div>
        </div>
        <div class="morelabeBox" v-if="selectCategory.name != '热门'">
          <span
            class="label"
            v-for="(item, i) in labelList"
            :key="i"
            :class="{ laberBOxAc: selectTagItem.name == item.name }"
            @click="AClabel(item, i)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="content">
        <div class="contentBox">
          <!-- 回到顶部 -->
          <!-- <div class="upTop" @click="event_scrollToTop_pc()">
            <img src="../../../assets/img/UPTop.png" alt />
          </div> -->
          <div
            style="display: none"
            class="enshrine"
            :class="collectionNum > 0 ? '' : 'fix_enshrine'"
            @click="toGuestCollection"
          >
            <img src="../../../assets/img/collect.png" alt />
            <span class="text">收藏</span>
            <div class="num" v-if="collectionNum > 0">
              <span>{{ collectionNum || "" }}</span>
            </div>
          </div>
          <div class="inpBox">
            <div class="screen">
              <div
                class="screenBox"
                :class="isPop ? 'screenBoxACs' : ''"
                @click="showPop((state = 0))"
              >
                <span class="isSpan">筛选</span>
                <img
                  v-if="isPop == false"
                  src="../../../assets/img/iconBotton.png"
                  alt
                />
                <img
                  class="isImg"
                  v-else
                  src="../../../assets/img/botton.png"
                  alt
                />
                <div class="UPpop screenBoxAC" v-if="isPop">
                  <span
                    class="states"
                    :class="habitationType == 0 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(0)"
                    >不限</span
                  >
                  <span
                    class="states"
                    :class="habitationType == 10 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(10)"
                    >国内</span
                  >
                  <span
                    class="states"
                    :class="habitationType == 20 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(20)"
                    >海外</span
                  >
                </div>
              </div>
              <div
                class="screenBox"
                :class="isPops ? 'screenBoxACs' : ''"
                @click="showPop((state = 1))"
              >
                <span class="isSpan">排序</span>
                <img
                  v-if="isPops == false"
                  src="../../../assets/img/iconBotton.png"
                  alt
                />
                <img
                  class="isImg"
                  v-else
                  src="../../../assets/img/botton.png"
                  alt
                />
                <div class="UPpop screenBoxAC" v-if="isPops">
                  <span
                    class="states"
                    :class="search_orderBy == 'normal' ? 'state' : ''"
                    @click="event_orderByTypeClick(0)"
                    >综合</span
                  >
                  <div class="UPpopBox" @click="event_orderByTypeClick(1)">
                    <span
                      class="spanS"
                      :class="search_orderBy != 'normal' ? 'state' : ''"
                      @click="showImg"
                      >入驻时间</span
                    >
                    <div class="UPpopBoxAc">
                      <img
                        class="upImg"
                        v-if="isImg == true"
                        src="../../../assets/img/up.png"
                        alt
                      />
                      <img
                        class="upImg"
                        v-if="isImg == false"
                        src="../../../assets/img/upA.png"
                        alt
                      />
                      <img
                        class="upImg"
                        v-if="isImg1 == true"
                        src="../../../assets/img/downa.png"
                      />
                      <img
                        class="upImg"
                        v-if="isImg1 == false"
                        src="../../../assets/img/down_Icon1Ac.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input
              class="inp"
              type="text"
              @click="toback"
              readonly="readonly"
              placeholder="名字、职业、领域、关键字"
            />
          </div>

          <div class="personMesList">
            <ul>
              <li
                class="personLi"
                v-for="(item, i) in LecturerList"
                :key="i"
                @click="event_lecturerItemClick(item)"
              >
                <div class="personLiBox">
                  <el-image class="personPhoto" :src="item.image">
                    <div slot="placeholder" class="personPhoto">
                      <img
                        class="personPhoto"
                        src="../../../assets/img/avatar_place.png"
                        alt
                      />
                    </div>
                    <div slot="error" class="personPhoto">
                      <img
                        class="personPhoto"
                        src="../../../assets/img/avatar_place.png"
                        alt
                      />
                    </div>
                  </el-image>
                  <div class="personMes">
                    <div class="personMesName">
                      <span class="name">{{ item.name }}</span>
                      <img
                        class="collect"
                        v-show="item.isFavorite == 1"
                        src="../../../assets/img/icom8.png"
                        alt
                      />
                    </div>
                    <div class="personMesoll">
                      <div class="personMesollP">
                        <p v-for="(titleItem, j) in item.titleList">
                          {{ titleItem }}
                        </p>
                      </div>
                      <img
                        class="rightIocn"
                        src="../../../assets/img/youjiantou2.png"
                        alt
                        style="height:30px"
                      />
                    </div>
                    <div class="sort">
                      <span
                        class="labelBox"
                        v-for="(tagItem, j) in item.tagList"
                        >{{ tagItem }}</span
                      >
                      <!-- <span class="labelBox2" v-for="(cateItem,j) in item.cateList">{{cateItem}}</span> -->
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- hide-on-single-page -->
          <div class="pagination">
            <el-pagination
              background
              layout="pager"
              :total="totalListNum"
              hide-on-single-page
              :current-page="currentPage"
              @current-change="handleSizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import elementResizeDetectorMaker from "element-resize-detector";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import Vue from "vue";

export default {
  name: "a",
  data() {
    return {
      userId: "",
      ac: 0,
      rightNavShow: "",
      flag: "",
      windowHeight: document.documentElement.clientHeight,
      height_MT: "",
      height_MT_left: "",
      Leftheight_MT: "",
      CategoryList: [],
      LecturerList: [],
      adBannerList: [],
      //xi相关
      search_keyword: "", //keywork
      selectCategory: {
        name: "",
        id: 0,
      }, //cateId
      search_orderBy: "normal",
      habitationType: 0, //0未设置  10国内  20海外
      collectionNum: 0,
      currentPage: 1,
      pageSize: 10,
      totalListNum: 10,
      isFirstSetup: true,
      isPop: false,
      isPops: false,
      isImg: false,
      isImg1: false,
      isAsc: false,
      selectHabitationType: 0,
      selectTagItem: {
        name: "",
      },
      isShowSecondTag: true,
      labelList: [
        {
          name: "全部",
        },
      ],
      selLabelList: [
        {
          name: "全部",
        },
      ],
      tagList: [],
      isShowImg: 0,
      //vant list refresh
      loading: false,
      finished: false,
      refreshing: false,
      isShowNavMore: false,
      isMoer: false,
      scrollDistance: 0,
      headnavlabelh: 0,
      isHeadnavHeight: true,
      isOneMoreTag: true,
      needUpdate: false,
      rightPadding: 50,
      iframeBgH: 500,
    };
  },
  components: {
    fooTer,
  },
  methods: {
    //统计访问次数
    setupStatistics() {
      this.AjaxTeacher(
        ApiUrl.addVisitCount,
        {
          corpId: "1",
        },
        function(res) {}
      );
    },
    // PC的方法
    other_fixImg(imgStr) {
      return imgStr;
    },
    event_pc_navMoreBtnClick() {
      this.isShowNavMore = !this.isShowNavMore;
    },
    event_scrollToTop_pc() {
      document.body.scrollIntoView();
    },
    toGuestCollection() {
      this.recoredScollData();
      //跳转收藏 仅用在开发页面使用
      var url = "/GuestCollection";
      var query = {};
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
    toback() {
      this.recoredScollData();
      var url = "/TeacherSearchpage";
      var query = {};
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
    // nav切换
    AcIssho(index) {
      this.isShowImg = index;
    },
    handleSizeChange(e) {
      this.currentPage = e;
      this.LecturerList = [];
      this.onLoad();
    },
    onLoad() {
      //网络加载
      if (this.refreshing) {
        this.refreshing = false;
      }

      var that = this;
      if (this.finished) {
        return;
      }
      if (this.selectCategory.name == "") return;
      var tagItemName = this.selectTagItem.name || "";
      var cateId = this.selectCategory ? this.selectCategory.id : 0;
      if (
        tagItemName != "" &&
        this.selectTagItem.name != "全部" &&
        this.selectTagItem.id
      ) {
        cateId = this.selectTagItem.id;
      }

      var data = {
        cateId: cateId,
        keyword: this.search_keyword != "全部" ? this.search_keyword : "",
        orderBy: this.search_orderBy ? this.search_orderBy : "normal",
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      var userId = this.userId || "";
      if (userId) data["userId"] = userId;
      if (this.habitationType == 10 || this.habitationType == 20)
        data["habitationType"] = this.habitationType;

      this.AjaxTeacher(ApiUrl.getLecturerList, data, function(res) {
        that.currentPage = res.currentPage;
        if (that.currentPage == 1) that.LecturerList = [];
        var tempArray = that.LecturerList;
        tempArray = tempArray.concat(res.rows);
        that.totalListNum = res.total;
        that.LecturerList = tempArray;
        that.loading = false;
        that.finished = that.LecturerList.length == res.total;
        if (!that.rightNavShow) {
          //手机
          if (!that.finished) that.currentPage += 1;
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.getLecturerList();
    },

    // 点开弹窗
    showPop(state) {
      if (state == 0) {
        this.isPop = !this.isPop;
        this.isPops = false;
      } else {
        this.isPops = !this.isPops;
        this.isPop = false;
      }
    },
    // 点击标签切换
    AClabel(item, i) {
      this.isShowSecondTag = false;
      this.selectTagItem = item;
      if (this.isOneMoreTag && item.name != "全部") {
        this.selLabelList = [
          {
            name: "全部",
          },
        ];
        this.selLabelList.push(item);
      }

      this.getLecturerList();
    },
    // 点击阴影部分关闭弹框
    close() {
      this.isPop = false;
      this.isPops = false;
    },
    showImg() {
      this.isImg = !this.isImg;
      this.isImg1 = !this.isImg1;
    },
    setupAjaxData() {
      var that = this;
      this.getCategoryListSuccess(function(res) {
        that.event_caterogryItemClIck(that.selectCategory);
      });
      this.getAdBannerList();
      this.getCollectionInfo();
    },
    islabel() {
      this.isShowSecondTag = !this.isShowSecondTag;
    },

    updateAjaxData() {
      var that = this;
      that.event_caterogryItemClIck(that.selectCategory);

      this.getCollectionInfo();
    },

    getCollectionInfo() {
      if (!this.userId) {
        this.collectionNum = 0;
        return;
      }
      var that = this;
      this.AjaxTeacher(
        ApiUrl.getFavoriteList,
        {
          userId: this.userId,
        },
        function(res) {
          that.collectionNum = res.total;
        }
      );
    },

    getCategoryListSuccess(cb) {
      var that = this;
      this.AjaxTeacher(ApiUrl.getCategory, {}, function(res) {
        // var tempArray = [];
        // for (var key in res) {
        //   var array = res[key];
        //   tempArray = tempArray.concat(array);
        // }
        // that.CategoryList = tempArray;
        that.CategoryList = res.level1;
        that.tagList = res.level2;
        that.selectCategory = that.CategoryList[0];
        if (typeof cb == "function" && cb) {
          cb();
        }
      });
    },

    getLecturerList() {
      this.finished = false;
      this.currentPage = 1;
      this.onLoad();
    },

    getAdBannerList() {
      var that = this;
      this.AjaxTeacher(ApiUrl.getAdList, {}, function(res) {
        that.adBannerList = res;
      });
    },

    event_caterogryItemClIck(item) {
      this.selectCategory = item;
      this.selectTagItem = this.labelList[0];
      if (!this.rightNavShow) {
        this.$refs.scrollWrapper.scrollTop = 0;
      }
      this.getLecturerList();
      var tempArray = [
        {
          name: "全部",
        },
      ];
      for (var i = 0; i < this.tagList.length; i++) {
        var tagItem = this.tagList[i];
        if (tagItem.parentId == item.id) {
          tempArray.push(tagItem);
        }
      }
      this.selectTagItem = this.labelList[0];
      this.isShowSecondTag = true;

      this.isOneMoreTag = true;
      this.isShowSecondTag = true;
      this.labelList = tempArray;
      if (item.name == "热门") {
        this.rightPadding = 130;
      }
    },

    event_bannerItemClick(item) {
      var url = item.url || "";
      if (url == "") return;
      window.open(url);
    },

    event_lecturerItemClick(item) {
      var name = item.name || "嘉宾";
      var id = item.id || "";
      if (id == "") {
        // console.log("嘉宾不能为空");
        return;
      }
      // this.selectCategory = item;
      //跳转页面
      this.recoredScollData();
      var url = "GuestDetail";
      var query = {
        name: name,
        id: id,
        navClassTypeName: this.selectCategory.name,
      };

      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },

    event_goCollectionPage() {
      if (!this.userId || this.userId == "") {
        this.$alert("请登录账户");
        return;
      }
      this.recoredScollData();
      var url = "GuestCollection";
      var query = {};
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },

    event_selectHabitationTypeClick(type) {
      this.habitationType = type;
      this.getLecturerList();
    },

    event_orderByTypeClick(type) {
      if (type == 0) {
        this.isAsc = false;
        this.search_orderBy = "normal";
      } else {
        this.search_orderBy = this.isAsc ? "timeDesc" : "timeAsc";
        this.isAsc = !this.isAsc;
      }
      this.getLecturerList();
    },

    //记录滚动至
    recoredScollData() {
      if (!this.rightNavShow) {
        let wrapperScrollTop = this.$refs.scrollWrapper.scrollTop;
        this.scrollDistance = wrapperScrollTop;
      }
    },
  },

  watch: {
    height_MT(val) {
      let that = this;
      // console.log("实时屏幕高度：", val, that.height_MT);
    },
    rightPadding(val) {
      this.height_MT = this.windowHeight - 50 - val - 2;
    },
    isShowSecondTag() {
      var that = this;
      this.$nextTick(function() {
        var itemB = this.$refs.laberListHeght;
        if (itemB) {
          that.rightPadding = itemB.offsetTop + itemB.offsetHeight;
        }
      });
    },
    labelList: function() {
      var that = this;
      this.$nextTick(function() {
        if (that.selectTagItem.name == "热门") {
          var itemA = that.$refs.headnavHeight;
          that.isHeadnavHeight = false;
          var a = itemA.offsetHeight;
          that.headnavlabelh = a + 40;

          var itemBanner = that.$refs.headnavlabelh;
          that.rightPadding = itemBanner.offsetHeight;
        } else {
          var itemB = that.$refs.laberListHeght;
          if (itemB) {
            var b = itemB.offsetHeight;
            that.isHeadnavHeight = true;
            if (b > 55) {
              that.isOneMoreTag = true;
              that.isShowSecondTag = false;
            } else {
              that.isOneMoreTag = false;
            }
            that.rightPadding = itemB.offsetTop + 50;
          }
        }
      });
    },
  },

  mounted() {
    this.rightNavShow = true;
    const clientWidth = document.body.clientWidth;
    this.height_MT_left = this.windowHeight - 50;
    this.height_MT = this.height_MT_left;
    this.Leftheight_MT = this.windowHeight - 144;

    if (this.rightNavShow) {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      var app = document.getElementById("iframeBg");
      erd.listenTo(app, (element) => {
        _this.$nextTick(() => {
          var iHeight = app.offsetHeight + 11;
          iHeight = iHeight > 500 ? iHeight : 500;
          parent.window.postMessage(
            {
              msgType: 1,
              msgData: {
                iHeight: iHeight,
              },
            },
            "*"
          );
        });
      });
    }
  },
  created() {
    document.title = "华章师资 - 华章书院";
    localStorage.setItem("userId", 9999);
    this.userId = localStorage.getItem("userId");

    if (this.isFirstSetup) {
      this.isFirstSetup = false;
      this.setupAjaxData();
    } else {
      this.updateAjaxData();
    }
    //进行统计
    this.setupStatistics();
  },
  activated() {
    if (!this.rightNavShow) {
      this.$refs.scrollWrapper.scrollTop = this.scrollDistance;
    }

    var needUpdate = localStorage.getItem("needUpdata_List") == "1";
    localStorage.removeItem("needUpdata_List");
    localStorage.removeItem("needUpdata_Collection");
    if (needUpdate) {
      this.finished = false;
      this.handleSizeChange(1);
      this.getCollectionInfo();
    }
  },
};
</script>

<style lang="scss" scoped>
.allBox {
  width: 100%;
  height: 100%;
  position: relative;
}

.nav {
  width: 100%;
  /* // height: 129px; */
  padding-bottom: 20px;
  background-color: #fff;
}

.morelabeBox {
  width: 100%;
  height: 100%;
  max-width: 1157px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  border-top: 1px solid #eeeeee;

  .label {
    line-height: 1;
    display: block;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #f6f6f6;
    color: #c1c1c1;
    font-size: 15px;
    margin-right: 8px;
    margin-top: 20px;
    cursor: pointer;
  }

  .laberBOxAc {
    background-color: #e0f3f7;
    color: #52c4c6;
  }
}

.navList {
  width: 100%;
  height: 100%;
  max-width: 1157px;
  margin: 0 auto;
  display: flex;
  position: relative;
  cursor: default;
  li {
    cursor: pointer;
  }
  .moreBox {
    width: auto;
    height: 100%;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;

    right: 0;
    align-items: center;
    background: #fff;
    justify-content: flex-end;

    span {
      color: #333333;
      font-size: 14px;
    }

    img {
      width: 20px;
      object-fit: contain;
      transition: all 0.5s;
    }

    .moreImg {
      transform: rotate(180deg);
    }
  }
}

.navList ul {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  // display: flex;
  // align-items: center;
  // // overflow: hidden;
  // flex-wrap: wrap;
  // justify-content: space-between;
  position: relative;
}

.navList .ShowUl {
  white-space: pre-line;
  overflow: initial;
}

.navList .acUl {
  flex-wrap: wrap;
}

.navList ul li {
  width: 139px;
  display: inline-block;
  flex-flow: column;
  align-items: center;

  margin-top: 12px;
}

.navList ul li .icon {
  width: 75px;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.navList ul li span {
  font-size: 14px;
  color: #b8b8b8;
  margin-top: 5px;
  text-align: center;
  display: block;
}

.navList ul li .ACspan {
  color: #333333;
}

.navList ul li .moer {
  width: 20px;
  height: 20px;
}

.content {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  padding-top: 11px;
  // background-color: red;
}

.UPpop {
  right: 6.9rem;
  width: 5rem;
  top: 50px;
  // right: 50px;
  position: fixed;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  img {
    width: 14px;
    height: 10px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .UPpopBox {
    width: 100%;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    text-align: center;
    align-items: center;

    span {
      padding: 0;
      border: 0;
    }

    .UPpopBoxAc {
      display: flex;
      flex-direction: column;

      .upImg {
        width: 12px;
        height: 9px;
        margin: 0;
      }
    }
  }

  span {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    text-align: center;
    font-size: 15px;
    color: #8a8a8a;
  }

  .state {
    color: #e6554d;
  }

  .headnav {
    position: relative;

    .addIcon {
      position: absolute;
      right: 5px;
      top: 13px;
    }
  }
}

.UPpop :last-child {
  border: 0;
}

.UPpops {
  right: 1.5rem;
}

.contentBox {
  width: 100%;
  height: 100%;

  max-width: 1157px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  /* padding-right: 30px;
  padding-left:39px ; */
  position: relative;

  .enshrine {
    position: absolute;
    bottom: 118px;
    right: -40px;
    width: 1.5rem;
    height: 0.6rem;
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 22;
    cursor: pointer;

    img {
      width: 0.35rem;
      height: 0.35rem;
      object-fit: contain;
    }

    .text {
      color: #e6554d;
      font-size: 20px;
      font-weight: bold;
    }

    .num {
      background-color: #fe8608;
      border-radius: 50%;
      width: 0.24rem;
      height: 0.24rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      color: #fff;
    }
  }

  .upTop {
    position: absolute;
    bottom: 208px;
    right: -40px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);

    img {
      width: 15px;
      height: 26px;
      transform: rotate(90deg);
    }
  }

  .upTop:hover {
    background: rgba(0, 0, 0, 1);
  }

  .screen {
    height: 100%;
    display: flex;
    width: 40%;
    align-items: center;
    font-size: 18px;
    color: #1c1c1c;
    justify-content: space-evenly;
  }

  .screen img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 5px;
    margin-top: 2px;
    transition: all 0.5s;
  }

  .screenBox {
    cursor: pointer;
    width: 34%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 9px;
    padding-bottom: 5px;

    .UPpop {
      position: absolute;
      top: 38px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 11;
      padding: 0;
      padding-bottom: 16px;

      .states {
        width: 80%;
        text-align: center;
        border-bottom: 1px solid #eeeeee;
        padding: 13px 0px;
        color: #8a8a8a;
      }

      .state {
        color: #e6554d;
      }

      .headnav {
        position: relative;

        .addIcon {
          position: absolute;
          right: 5px;
          top: 13px;
        }
      }

      .UPpopBox {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .UPpopBoxAc {
          display: flex;
          flex-direction: column;
        }

        .spanS {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    }
  }

  .screenBoxAC {
    background-color: #fff;

    box-shadow: 0px 17px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
  }

  .screenBoxACs {
    background-color: #fff;

    box-shadow: 0px 17px 15px 0px rgba(0, 0, 0, 0.1);

    // border-radius: 0px 0px 12px 12px;
    .isSpan {
      color: #e6554d !important;
    }

    .isImg {
      transform: rotate(180deg);
    }
  }
}

.inpBox {
  width: 93%;
  height: 79px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.inp {
  width: 447px;
  height: 45px;
  background: #f7f7f7;
  border-radius: 23px;
  background-image: url(../../../assets/img/search.png);
  background-size: 22.5px;
  background-repeat: no-repeat;
  padding-left: 98px;
  background-position: 21px;
  color: #c9c9c9;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  border: none;
}

.inp::-webkit-input-placeholder {
  color: #c9c9c9;
  font-size: 18px;
  /* font-weight: bold; */
}

.screen {
  height: 100%;
  display: flex;
  width: 40%;
  align-items: center;
  font-size: 18px;
  color: #1c1c1c;
  justify-content: space-evenly;
}

.screen img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.screenBox {
  cursor: pointer;
}

.personMesList ul::-webkit-scrollbar {
  display: none;
}

.pagination {
  padding: 50px 0;
  margin-top: 10px;
}

.pagination /deep/ .el-pagination {
  display: flex;
  justify-content: center;
}

.personMesList {
  width: 100%;
  // height: 8.3rem;
  max-height: 900px;
  min-height: 500px;
  white-space: nowrap;
}

.personMesList::-webkit-scrollbar {
  display: none;
}

.personMesList ul {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  // justify-content: space-between;
}

.personMesName {
  display: flex;
  align-items: center;
}

.personMesName .collect {
  width: 23px;
  height: 23px;
  object-fit: contain;
  margin-left: 12px;
}

.personMesName .name {
  color: #2b2b2b;
  font-size: 24px;
  font-weight: bold;
}

.personMes .mes {
  color: #cccccc;
  font-size: 15px;
}

.personMesoll {
  display: flex;
  color: #cccccc;
  font-size: 15px;
  justify-content: space-between;
  max-height: 65px;
  overflow: hidden;
  margin: 5px 0;

  .personMesollP {
    width: 90%;
    height: 100%;

    // margin: 5px 0;
    p {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
    }
  }
}

.personMesoll .rightIocn {
  width: 30px;
  height: 25px;
  object-fit: cover;
  margin-top: -3px;
}

.sort {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  max-height: 25px;
  overflow: hidden;
}

.sort span {
  display: inline-block;
  padding: 2px 10px;
  background-color: #e0f3f7;
  color: #52c4c6;
  font-size: 15px;
  // margin-bottom: 20px;
}

.sort .labelBox {
  display: inline-block;
  //background-color: #e0f3f7;
  //color: #52c4c6;
  background-color: #fff4e7;
  color: #ef8608;
  padding: 2px 8px;
  margin-right: 5px;
  border-radius: 2px;
  font-size: 14px;
}

.sort .labelBox2 {
  display: inline-block;
  background-color: #fff5e5;
  color: #fe8608;
  padding: 2px 8px;
  margin-right: 5px;
  border-radius: 2px;
  margin-bottom: 5px;
}

.personLi {
  width: 50%;
  display: flex;
  // height: 185px;
  cursor: pointer;
}

.personLiBox {
  width: 80%;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  margin: 0 auto;
}

.personLiBox .personPhoto {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  object-fit: cover;
}

.personMes {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 手机版的样式 */
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;

  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}

.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}

.content-MT {
  width: 100%;
  display: flex;
  height: 100%;
  margin-top: 50px;
}

.nav_left {
  width: 27%;
  height: 100%;
  background: #f9f9f9;

  ul {
    width: 100%;
    overflow-y: auto;

    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;

      .nav_img {
        width: 3rem;
        height: 2rem;
        /* opacity: 0.3; */
      }

      span {
        color: #afafaf;
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }
    }

    .active {
      background-color: #fff;

      span {
        color: #333333;
      }

      img {
        opacity: 1;
      }
    }
  }

  .nav_leftul::-webkit-scrollbar {
    display: none;
  }
}

.nav_rigth {
  width: 73%;
  height: 100%;
  /* background-color: yellow; */

  .headnavlabel {
    position: fixed;
    background-color: #fff;
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .my-swipe {
      height: 4.3rem;
      margin-top: 0.3rem;
    }

    .advertising {
      border-radius: 16px;
      width: 90%;
      overflow: hidden;
      margin: 0 auto;

      .banner {
        width: 100%;
        height: 4rem;
        display: block;
      }
    }
  }

  .labelContentBg {
    position: relative;
  }

  .laberList {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #eeeeee;

    .laberImg {
      margin-top: 10px;

      img {
        width: 12px;
        height: 8px;
      }
    }

    .laber {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      // height: 1.9rem;
      overflow: hidden;
      padding-top: 9px;
      min-height: 42px;

      .span {
        height: 18px;
      }

      .laberBOx {
        padding: 3px 8px;
        background-color: #f6f6f6;
        border-radius: 5px;
        margin-right: 5px;
        margin-bottom: 9px;
        max-height: 18px;
        height: 18px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #c1c1c1;

        span {
          font-size: 12px;
        }
      }

      .laberBOxAc {
        color: #e6554d;
        background-color: #e7f5e7;
      }
    }

    .labelAC {
      height: auto;
    }

    .labera {
      height: 1.9rem;
    }
  }

  .tempLaberList {
    position: absolute;
    top: 41px;
    left: 0;
    background: white;
  }

  .laber_limit {
    height: 41px;
    overflow: hidden;
  }

  .laber_unlimit {
    height: auto;
    overflow: auto;
  }

  .enshrine {
    position: fixed;
    right: 0;
    bottom: 3rem;
    width: 5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    /* background: #ffffff; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 100px 0px 0px 100px;
    font-size: 0.5rem;

    .collect {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
    }

    .name {
      margin-left: 0.3rem;
    }

    .num {
      width: 21px;
      height: 21px;
      background: #fe8608;
      display: flex;
      /* text-align: center; */
      border-radius: 50%;
      margin-left: 0.5rem;
      // line-height: 1.5;
      /* padding: 0.1rem; */
      color: #fff;
      justify-content: center;
      align-items: center;
    }
  }

  .fix_enshrine {
    width: 3.7rem;
  }

  .headnav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
    align-items: center;

    .headnavAC {
      display: flex;
      align-items: center;
      color: #1c1c1c;
      font-size: 15px;

      img {
        width: 12px;
        height: 8px;
        margin-left: 5px;
      }
    }

    .advertising {
      border-radius: 16px;
      width: 90%;
      overflow: hidden;
      margin: 0 auto;

      .banner {
        width: 100%;
        height: 4rem;
        display: block;
      }
    }
  }

  .messageList {
    // padding-top: 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow-y: auto;

    .advertising {
      border-radius: 16px;
      width: 90%;
      overflow: hidden;
      margin: 0 auto;

      .banner {
        width: 100%;
        // height: 4rem;
        display: block;
      }
    }

    ul {
      width: 90%;
      margin: 0 auto;

      li {
        padding: 0.6rem 0;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.2rem;

        .profile-photo {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          overflow: hidden;

          .logo {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
          }
        }

        .user-message {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          width: 68%;

          .label {
            display: flex;
            overflow: hidden;
            max-height: 30px;
            flex-wrap: wrap;
            margin-top: 5px;

            // .sss {
            .labelBox {
              display: block;
              background-color: #e0f3f7;
              color: #52c4c6;
              font-size: 12px;
              padding: 4px 8px;
              margin-right: 5px;
              border-radius: 5px;
              margin-bottom: 10px;
              line-height: 1;
            }

            .labelBox2 {
              display: inline-block;
              background-color: #fff5e5;
              color: #fe8608;
              font-size: 12px;
              padding: 2px 8px;
              margin-right: 5px;
              border-radius: 2px;
              margin-bottom: 5px;
            }

            // }
          }

          .person {
            font-size: 18px;
            font-weight: bold;
            color: #2b2b2b;
            display: flex;
            align-items: center;

            .iconstate {
              width: 0.8rem;
              height: 0.8rem;
              margin-left: 5px;
            }
          }

          .message {
            display: flex;
            justify-content: space-between;
            color: #cccccc;
            margin: 3px 0;
            // height: 50px;
            max-height: 50px;
            min-height: 50px;
            overflow: hidden;

            .message-text {
              display: flex;
              flex-direction: column;
              width: 90%;
              height: 100%;

              span {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1.5;
              }
            }

            .right-img {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }

  .messageListAC {
    margin-top: -32px;
  }

  .messageList::-webkit-scrollbar {
    display: none;
  }
}

// 蒙城
.pop {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

// 媒体查询
@media screen and (max-width: 1157px) {
  .contentBox {
    // background: red;
    .upTop {
      right: 0;
    }

    .enshrine {
      right: 0;
    }
  }
}
</style>
