var baseDomain = "https://cloud.cmpreading.com/api/";
var developUrl = 'https://www.hzcourse.com/oepp/web/';
var hzcourseDomain = "https://www.hzcourse.com/"; //师资接口域名
var lecturerDomain = hzcourseDomain + "oepp/web/lecturer/"; //师资接口

var home_list_get=baseDomain+"home_list_get.ashx";//首页数据
var random_product_list_get = baseDomain + "random_product_list_get.ashx"; //换一换数据
var ad_get = baseDomain + "ad_get.ashx"; //广告
var banner_get = baseDomain + "banner_get.ashx"; //banner

//书院
var shuyuan_list_get = baseDomain + "shuyuan_list_get.ashx";
var shuyuan_navbar_get = baseDomain + "shuyuan_navbar_get.ashx";
var shuyuan_audio_get = baseDomain + "shuyuan_audio_get.ashx";//书院推荐频道推荐音频
var shuyuan_list2_get = baseDomain + "shuyuan_list2_get.ashx";//书院推荐频道推荐栏目（分页）

//产品
var menu_product_type_get = baseDomain + "menu_product_type_get.ashx"; //产品类型
var view_product_type_get = baseDomain + "view_product_type_get.ashx"; //最近阅读产品类型
var view_product_list_get = baseDomain + "view_product_list_get.ashx"; //最近阅读产品列表

//精彩
var jc_all_get = baseDomain + "jc_all_get.ashx";
var jc_product_list_get = baseDomain + "jc_product_list_get.ashx";

//电子书
var book_list_get = baseDomain + "book_list_get.ashx";
var book_detail_get = baseDomain + "book_detail_get.ashx";
var book_content_get = baseDomain + "book_content_get.ashx";
var book_chapter_get =baseDomain + "book_chapter_get.ashx" ; //电子书章节内容
var book_record_set =baseDomain + "book_record_set.ashx" ; //电子书记录


//筛选
var menu_list_get = baseDomain + "menu_list_get.ashx"; //筛选，产品类别 
var menu_product_type_get = baseDomain + "menu_product_type_get.ashx"; //筛选，产品类型
var menu_product_list_get = baseDomain + "menu_product_list_get.ashx"; //筛选，产品列表  

//资源
var resource_detail_get = baseDomain + "resource_detail_get.ashx"; //资源
var resource_list_get = baseDomain + "resource_list_get.ashx"; //资源目录

//专栏
var column_list_get = baseDomain + "column_list_get.ashx"; //专栏列表
var column_detail_get = baseDomain + "column_detail_get.ashx"; //专栏详情

//云书房
var yun_product_add = baseDomain + "yun_product_add.ashx"; //添加产品
var yun_product_del = baseDomain + "yun_product_del.ashx";
var yun_search_count_get = baseDomain + "yun_search_count_get.ashx";
var yun_product_list_get = baseDomain + "yun_product_list_get.ashx";
var yun_product_type_get = baseDomain + "yun_product_type_get.ashx";
var yun_search_list_get = baseDomain + "yun_search_list_get.ashx";

//直播
var webcast_list_get = baseDomain + "webcast_list_get.ashx"; //直播
var webcast_detail_get = baseDomain + "webcast_detail_get.ashx";

//搜索
var search_keywords_get = baseDomain + "search_keywords_get.ashx"; //热门搜索
var search_history_get = baseDomain + "search_history_get.ashx"; //历史记录
var search_list_get = baseDomain + "search_list_get.ashx"; //搜索结果列表
var search_history_clear = baseDomain + "search_history_clear.ashx"; //清空搜索历史记录
var search_history_del = baseDomain + "search_history_del.ashx"; //删除搜索历史
var search_title_get = baseDomain + "search_title_get.ashx"; //自动完成
var search_count_get = baseDomain + "search_count_get.ashx"; //搜索结果数量

//特惠专区
var sale_list_get = baseDomain + "sale_list_get.ashx"; //特惠专区

//产品组合，专题
var group_all_get = baseDomain + "group_all_get.ashx"; // 所有组合
var group_detail_get = baseDomain + "group_detail_get.ashx"; // 组合详情
var group_product_list_get = baseDomain + "group_product_list_get.ashx"; //产品列表 

//师资，师资接口用了不同的域名
var delFavorite = lecturerDomain + "delFavorite"; //取消收藏
var submitRequest = lecturerDomain + "submitRequest"; //提交活动申请
var addFavorite = lecturerDomain + "addFavorite"; //收藏讲师
var getFavoriteList = lecturerDomain + "getFavoriteList"; //收藏讲师列表
var addVisitCount = lecturerDomain + "addVisitCount"; //登录次数累计接口
var getAdList = lecturerDomain + "getAdList"; //广告栏信息
var getCategory = lecturerDomain + "getCategory"; //获取所有讲师分类
var getHotKeywordList = lecturerDomain + "getHotKeywordList"; //获取搜索讲师热门关键词
var getUserInviteList = lecturerDomain + "getUserInviteList"; //获取用户订单列表
var getList = lecturerDomain + "getList"; //获取讲师信息列表
var getDetailById = lecturerDomain + "getDetailById"; //讲师详细信息接口

// 二维码落地
var qrcode_detail_get = baseDomain+"qrcode_detail_get.ashx";//二维码落地详情
//读者列表
var reader_list_get =baseDomain+"reader_list_get.ashx"  //读者列表
//个人中心
var user_index_get = baseDomain + "user_index_get.ashx"; //个人中心首页
var user_rights_get = baseDomain + "user_rights_get.ashx"; //用户权益，VIP特权
//var card_get = baseDomain + "card_get.ashx"; //VIP卡列表
var card_get = baseDomain + "card_get_test.ashx"; //VIP卡列表
var user_orderlist_get = baseDomain + "user_orderlist_get.ashx"; //订单信息
var card_giftlist_get=baseDomain+"card_giftlist_get.ashx"; //赠品书单
//当前登录企业信息
var user_corp2_get=baseDomain+"user_corp2_get.ashx";

//登录、分享、支付相关
var jssdk_config_get = baseDomain + "jssdk_config_get.ashx";//微信JS-SDK部分配置信息
//var user_login = baseDomain + "user_login.ashx";//用户微信登录接口
var user_login = baseDomain + "user_login_test.ashx";//用户微信登录接口
var user_weixin_login = baseDomain + "user_weixin_login.ashx";//PC端用户微信登录接口
var purchase_add = baseDomain + "purchase_add.ashx";//产品订阅接口

//记录 资源进度
var resource_progress_update = baseDomain + "resource_progress_update.ashx";//记录 资源进度

//师资
var getCategory = developUrl + 'lecturer/getCategory';//获取所有讲师分类
var getLecturerList = developUrl + 'lecturer/getList';//获取讲师信息列表
var getFavoriteList = developUrl + 'lecturer/getFavoriteList';//收藏讲师列表
var getDetailById = developUrl + 'lecturer/getDetailById';//讲师详细信息接口
var addFavorite = developUrl + 'lecturer/addFavorite';//收藏
var delFavorite = developUrl + 'lecturer/delFavorite';//取消收藏

//其他
var getAdList = developUrl + 'lecturer/getAdList';//获取广告栏信息
var submitRequest = developUrl + 'lecturer/submitRequest';//提交活动邀请
var getHotKeywordList = developUrl + 'lecturer/getHotKeywordList'; //获取搜索热词
var getUserInviteList = developUrl + 'lecturer/getUserInviteList'; //获取用户订单列表
var addVisitCount = developUrl + 'lecturer/addVisitCount';//登录次数累计接口

export default {
	home_list_get:home_list_get,//首页数据
	random_product_list_get: random_product_list_get, //换一换数据
	ad_get: ad_get, //广告
	banner_get: banner_get,
	book_chapter_get:book_chapter_get, //电子书章节
	shuyuan_list_get: shuyuan_list_get, //书院
	shuyuan_navbar_get: shuyuan_navbar_get,
	shuyuan_audio_get: shuyuan_audio_get,
	shuyuan_list2_get: shuyuan_list2_get,
	view_product_list_get: view_product_list_get, //最近阅读
	view_product_type_get: view_product_type_get,
	jc_all_get: jc_all_get, //精彩
	jc_product_list_get: jc_product_list_get,
	book_list_get: book_list_get, //电子书
	book_detail_get: book_detail_get,
	book_content_get: book_content_get,
	book_record_set: book_record_set,
	qrcode_detail_get:qrcode_detail_get,//落地窗
	menu_list_get: menu_list_get,
	menu_product_type_get: menu_product_type_get,
	menu_product_list_get: menu_product_list_get,
	resource_detail_get: resource_detail_get, //资源
	resource_list_get: resource_list_get,
	column_list_get: column_list_get, //专栏
	column_detail_get: column_detail_get,
	yun_product_add: yun_product_add, //云书房
	yun_product_del: yun_product_del,
	yun_search_count_get: yun_search_count_get,
	yun_product_list_get: yun_product_list_get,
	yun_search_list_get: yun_search_list_get,
	yun_product_type_get: yun_product_type_get,
	webcast_list_get: webcast_list_get, //直播
	webcast_detail_get: webcast_detail_get,
	search_keywords_get: search_keywords_get, //搜索
	search_history_get: search_history_get, //搜索
	search_list_get: search_list_get, //搜索结果
	search_history_clear: search_history_clear,
	search_history_del: search_history_del,
	search_title_get: search_title_get, //搜索自动匹配完成
	search_count_get: search_count_get, //搜索结果数量
	sale_list_get: sale_list_get, //特惠专区
	group_all_get: group_all_get, //产品组合，专题
	group_detail_get: group_detail_get,//师资，师资接口用了不同的域名
	group_product_list_get: group_product_list_get,
	delFavorite: delFavorite,
	reader_list_get:reader_list_get, //读者列表
	submitRequest: submitRequest,
	addFavorite: addFavorite,
	getFavoriteList: getFavoriteList,
	addVisitCount: addVisitCount,
	getAdList: getAdList,
	getCategory: getCategory,
	getHotKeywordList: getHotKeywordList,
	getUserInviteList: getUserInviteList,
	getList: getList,
	getDetailById: getDetailById,//讲师详细信息接口
	user_index_get: user_index_get,
	user_rights_get: user_rights_get,
	user_corp2_get: user_corp2_get,//登录用户企业信息
	card_get: card_get,
	card_giftlist_get:card_giftlist_get, //赠品书单
	user_orderlist_get: user_orderlist_get,
	jssdk_config_get: jssdk_config_get,
	user_login: user_login,
	user_weixin_login: user_weixin_login,
	purchase_add: purchase_add,
	resource_progress_update: resource_progress_update,
	//师资接口
	  getCategory: getCategory,
	  getLecturerList: getLecturerList,
	  getFavoriteList: getFavoriteList,
	  getDetailById: getDetailById,
	  addFavorite: addFavorite,
	  delFavorite: delFavorite,
	  getAdList: getAdList,
	  submitRequest: submitRequest,
	  getHotKeywordList: getHotKeywordList,
	  getUserInviteList: getUserInviteList,
	  addVisitCount: addVisitCount,
}
