<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto; background: #f7f7f7"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="10"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      style="position: fixed;"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>
    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
		  
		  <div class="logo_title" @click="GoToChangeTab(1)" style="cursor: pointer;">
		  	<h1>{{corpTitle}}</h1>
		  </div>

          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
		<div>
			<router-link to="/yunbook" 
			style="display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;">
				<h3 style="font-size: 18px;color: #2b2b2b;">
					我的书架
				</h3>
				<img
					class="hoveimg"
					style="width:24px;height:100%; margin:0 0 5px 5px;"
					src="../../assets/image/lishi.png"
					alt="" />
			</router-link>
		</div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height: 70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: index == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="index == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96"
              >LIVE</span
            >
          </div>
        </div>
      </div>
      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->

    <div class="bakcg">
      <div class="maxBox">
        <!-- 筛选框 -->
        <div class="fgs">
          <div class="filtrate">
            <div class="filtop">
              <div class="titles">频道</div>
              <div
                class="clsify1"
                v-if="quryList.list2 && quryList.list2.length > 0"
              >
                <div
                  class="spanv"
                  :class="{ active: quryData.firstClass == index }"
                  v-for="(item, index) in quryList.list2"
                  :key="index"
                  @click="click_firstClassChange(index, item)"
                >
                  {{ item.title }}
                </div>
              </div>

              <div
                class="clsify1"
                v-if="!quryList.list2 || quryList.list2.length == 0"
              >
                <div class="spanv active">全部</div>
              </div>
            </div>
            <div
              class="filbot"
              v-if="
                quryList.list2[quryData.firstClass] &&
                  quryList.list2[quryData.firstClass].sub
              "
            >
              <div class="fonts">
                <div class="tinam">类型</div>
              </div>
              <div class="clsify2">
                <div
                  class="spans"
                  v-for="(ite, ind) in quryList.list2[quryData.firstClass].sub"
                  :key="ind"
                  :class="{ actives: quryData.secondClass == ind }"
                  @click="click_secondClassChange(ind, ite)"
                >
                  {{ ite.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="muncost">
          <div class="costtop">
            <div class="costlft">
              <el-dropdown>
                <span class="el-dropdown-link">
                  {{ quryData.channelName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in quryList.list1"
                    :key="index"
                    class="tgs"
                    :class="{ active: quryData.channel == index }"
                  >
                    <div
                      @click="click_channelItem(item, index)"
                      style="width: 100%; height: 100%"
                    >
                      {{ item.title }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="costrgt">
              <div
                class="spanz"
                v-for="(items, inds) in quryList.list2Sort"
                :class="{ active: quryData.sortType == inds }"
                v-show="inds > 0"
                @click="changeSortTypeClick(inds)"
                :key="inds"
              >
                {{ items.title }}
              </div>
            </div>
          </div>
          <div class="booklis">
            <div
              class="dtuils"
              v-for="(item, index) in ductlist"
              :key="index"
              @click="goToDetailPage(item)"
            >
              <div class="dtlft">
                <img
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                  "
                  :src="item.img_url_compressed"
                  alt=""
                />
                <img
                  class="erjia"
                  v-if="item.product_type == 3"
                  src="../../assets/image/icon-listen.png"
                  alt=""
                />
              </div>
              <div class="dtrgt">
                <div class="tisl">
                  {{ item.title }}
                </div>
                <span>{{ item.author }}</span>
                <!-- <p>{{item.summary}}</p> -->
                <div class="addmg">
                  <!-- <div class="mgbox" style="width:30px;height:30px">
                  <img
                    src="../../assets/image/icon_7.png"
                    alt=""
                    style="width:100%;height:100%"
                  />
                </div>
                <div class="maue">
                  试读
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <foo-ter class="footer" />
    </div>
  </div>
</template>

<script>
import fooTer from "../../components/footer";
// import { ebooklist } from "../../api/bookList";
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      num: 0,
      tabList: [],
      productTypeData: {},
      busy: false,
      busy: false,
      type: 0,
      childList: [],
      ductlist: [], //所有电子书
      page: 0,
      // 获取所有电子书参数
      parameter: {
        page_index: "1",
      },
      quryData: {
        channel: 1,
        product_type: 1,
        channelName: "",
        firstClass: 0,
        secondClass: 0,
        sortType: 1,
        firstClassId: "",
        level: "",
        lastLevel: "",
      },
      quryList: {
        list1: [], //产品类型
        list2: [],
        list2Sort: [
          {
            title: "",
          },
          {
            title: "最新",
          },
          {
            title: "最热",
          },
        ],
      },
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
	  corpTitle:''
    };
  },
  components: {
    fooTer,
  },
  created() {
    var that = this;
    document.title = "分类 - 华章书院";
    this.setupList();
    /* 
			  option 规则
			  1.channel  0电子书 1有声书 2课程  
				2.右边firstClass (First Class)
				  右边secondClass  (second Class)
					右边sortType  (sort Type)
			*/
    var option = {
      type: this.$route.query.type || 1,
      level: this.$route.query.level || "",
      id: this.$route.query.id || "",
      sortType: this.$route.query.type2Sort || 1,
    };
    if (option) {
      this.type = option.type;
      var channel = 0;
      if (this.type == 1) {
        channel = 0;
        this.quryData.product_type = 1;
      } else if (this.type == 3) {
        channel = 1;
        this.quryData.product_type = 3;
      } else if (this.type == 4) {
        channel = 2;
        this.quryData.product_type = 4;
      } else if (this.type == 11) {
        channel = 3;
        this.quryData.product_type = 11;
      } else if (this.type == 12) {
        channel = 4;
        this.quryData.product_type = 12;
      } else if (this.type == 14) {
        channel = 0;
      }
      this.quryData.channel = channel;
      if (option.level && option.id) {
        this.quryData.level = option.level;
        this.quryData.firstClassId = option.id;
      }
      if (option.sortType) {
        this.quryData.sortType = parseInt(option.sortType) || 0;
      }
    }
    this.first_setupTabbarClassList();
    this.hotKeywordsGet();
	
	var corp = localStorage.getItem("CorpInfo");
	if(corp){
		that.corpTitle = corp;
	}
	
    window.addEventListener("resize", function() {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
      // console.log(screenWidth);
    },

    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.$refs.inputRef.blur();
      this.isInputing2 = false;
      var url = "/SearchPage";
      var query = { inputStr: this.input_content };
	  this.$router.push({
	  	path: url,
	  	query: query
	  });
	  /*
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
	  */
      this.input_content = "";
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    //导航
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }
          that.tabList.push(item);
        });
        that.productTypeData = data;
      });
    },

    // 导航
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },

    //第一次 加载tabbarClass
    first_setupTabbarClassList() {
      var that = this;
      //获取ChannelList
      var url = apiUrl.menu_product_type_get;
      var param = { id: "" };
      this.Ajax(url, param, (data) => {
        if (data) {
          data.forEach((item, i) => {
            if (this.quryData.channel == i) {
              this.quryData.channelName = item.title;
            }
          });
          that.quryList.list1 = data;
          that.parameter.page_index = 1;
        }
      });

      //获取Class TypeData
      var subUrl = apiUrl.menu_list_get;
      var subParam = {
        product_type: this.quryData.product_type || "",
        client_type: 2,
      };
      that.quryList.list2 = [];
      this.Ajax(subUrl, subParam, (data) => {
        if (data) {
          that.quryList.list2 = data;
          this.sub_setupClassQuryData();
        }
      });
    },
    //List Data
    setupListData() {
      var that = this;
      var order_by = "hot_at:desc";
      if (this.quryData.sortType == 1) order_by = "start_at:desc";
      if (this.quryData.sortType == 2) order_by = "hot_at:desc";
      var url = apiUrl.menu_product_list_get;
      var param = {
        id: this.quryData.firstClassId || 0,
        product_type: that.type,
        page_index: that.parameter.page_index,
        page_size: 10,
        order_by: order_by,
      };
      this.Ajax(
        url,
        param,
        (data) => {
          if (data) {
            if (that.parameter.page_index == 1) {
              that.ductlist = data.list;
            } else {
              that.ductlist = that.ductlist.concat(data.list);
            }
          }
          that.busy = false;
        },
        null,
        () => {
          // that.ductlist = [];
          that.busy = false;
        }
      );
    },
    //Next ListData
    loadMore() {
      if (this.busy) return;
      this.busy = true;

      this.parameter.page_index++;
      this.setupListData();
    },
    //Page: Detail
    goToDetailPage(item) {
      this.GoToDetail(item.product_type, item.id);
    },
    //Click: Channel
    click_channelItem(item, index) {
      this.quryData.channel = index;
      this.quryData.product_type = item.product_type;

      this.type = item.product_type;
      //Update UI
      this.quryList.list1.forEach((item, i) => {
        if (this.quryData.channel == i) {
          this.quryData.channelName = item.title;
        }
      });

      this.parameter.page_index = 1;
      // this.quryData.firstClassId = "",
      //Update List
      this.first_setupTabbarClassList();
      // this.setupListData();
    },
    //Click: SortItem
    changeSortTypeClick(inds) {
      this.quryData.level = this.quryData.lastLevel;
      this.quryData.sortType = inds;
      this.sub_setupClassQuryData();
    },
    //Click: FirstClass
    click_firstClassChange(index, item) {
      this.quryData.level = 1;
      this.quryData.firstClass = index;
      this.quryData.firstClassId = item.id;
      this.quryData.secondClass = 0;
      this.sub_setupClassQuryData();
    },
    //Click: SecondClass
    click_secondClassChange(idx, ite) {
      this.quryData.level = 2;
      this.quryData.secondClass = idx;
      this.quryData.firstClassId = ite.id;
      this.sub_setupClassQuryData();
    },
    //Detail: First Class/Second Class/Sort Changed
    sub_setupClassQuryData() {
      if (this.quryData.level == 1 || this.quryData.level == 2) {
        this.quryList.list2.forEach((item, i) => {
          if (this.quryData.level == 1) {
            if (this.quryData.firstClassId == item.id) {
              this.quryData.firstClass = i;
            }
          } else {
            if (item.sub) {
              item.sub.forEach((item2, index2) => {
                if (this.quryData.firstClassId == item2.id) {
                  this.quryData.firstClass = i;
                  this.quryData.secondClass = index2;
                }
              });
            }
          }
        });
        this.quryData.lastLevel = this.quryData.level;
        this.quryData.level = "";
      } else {
        this.quryData.firstClassId = "";
        if (this.quryList.list2 && this.quryList.list2.length > 0) {
          this.quryData.firstClass = 0;
          this.quryData.firstClassId = this.quryList.list2[0].id;
        }
      }

      this.parameter.page_index = 1;
      this.setupListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.bakcg {
  width: 100%;
  background: #f7f7f7;
  cursor: default;
  // border: 1px solid red;
  height: 100%;

  .footer {
    position: flex;
    margin-top: 0;
  }
  .maxBox {
    width: 1157px;
    min-width: 1157px;
    margin: 0 auto;

    // margin-top: 20px;
    // border: 1px solid red;
    background: #f7f7f7;
    .filtrate {
      width: 100%;
      // height: 200px;

      background: #fff;

      .filtop {
        height: 80px;

        width: 100%;

        display: flex;
        align-items: center;
        .titles {
          display: inline-block;
          margin: 0 50px;
          // height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;

          // line-height: 22px;
        }
        .clsify1 {
          // width: 68px;
          // height: 34px;
          display: inline-block;
          display: flex;
          text-align: center;

          .spanv {
            // width: 68px !important;
            height: 34px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
            margin-right: 20px;
            background: #f5f5f5;
            border-radius: 17px;
            // line-height: 32px;
            padding: 0 18px;
            display: flex;
            align-items: center;
          }
          .active {
            background: #fff;
            border: 1px solid #e6564e;
            color: #e6564e;
          }
        }
        .clsify1:hover {
          cursor: pointer;
        }
      }
      .filbot {
        // height: 120px;

        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        .fonts {
          height: 100%;
          width: 130px;

          .tinam {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            text-align: center;
            margin: 0 auto;
          }
        }
        .clsify2 {
          width: 1020px;
          height: 100%;
          padding: 20px 0;
          border-top: 1px solid #ccc;
          // display: flex;
          // justify-content: space-between;

          .spans {
            // width: 68px !important;
            height: 34px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
            margin-right: 20px;
            background: #f5f5f5;
            border-radius: 17px;
            line-height: 34px;
            display: inline-block;
            margin: 10px;

            padding: 0 18px;
          }
          .actives {
            background: #fff;
            border: 1px solid #e6564e;
            color: #e6564e;
          }
        }
        .clsify2:hover {
          cursor: pointer;
        }
      }
    }
    .muncost {
      width: 1157px;
      // height: 1000px;
      margin-top: 20px;
      // border: 1px solid blue;
      background: #fff;
      .costtop {
        width: 100%;
        height: 50px;
        background: #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .costlft {
          width: 105px;
          height: 37px;
          border: 1px solid #ff3366;
          border-radius: 40px;
          text-align: center;
          .el-dropdown-link {
            cursor: pointer;
            color: #ff3366;
            line-height: 33px;
            font-weight: 600;
          }
          .el-icon-arrow-down {
            font-size: 15px;
            font-weight: 800;
          }
        }
        .costrgt {
          width: 270px;
          height: 100%;

          display: flex;
          justify-content: space-between;
          .spanz {
            width: 50%;
            height: 100%;
            background: #f7f7f7;
            text-align: center;
            line-height: 45px;
            font-size: 18px;
            color: #999999;
          }
          .active {
            color: #e6564e;
            background: #fff;
          }
        }
        .costrgt:hover {
          cursor: pointer;
        }
      }
      .booklis {
        width: 100%;
        // border: 1px solid red;
        // height: 400px;
		min-height: 800px;
        margin-top: 50px;

        .dtuils {
          width: 455px;
          height: 160px;
          display: inline-block !important;
          margin: 0 20px 40px 70px;
		  cursor: pointer;
          // display: flex;
          // justify-content: space-between;
          .dtlft {
            width: 121px;
            height: 100%;
            display: inline-block;
            position: relative;

            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
            .erjia {
              position: absolute;
              width: 31px;
              height: 31px;
              top: 10px;
              right: 10px;
            }
          }
          .dtrgt {
            width: 315px;
            height: 100%;
            float: right;
            position: relative;
			
            .tisl {
              font-size: 18px;
              color: #333333;
              // letter-spacing: 1px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            span {
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //超出文本设置为...
              display: -webkit-box; //将div1转换为盒子模型
              -webkit-line-clamp: 2; //设置div1的文本为2行
              -webkit-box-orient: vertical; //从顶部向底部垂直布置子元素
              font-size: 16px;
              color: #666666;
              margin-top: 10px;
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 14px;
              color: #cccccc;
              margin-top: 10px;
            }
            .addmg {
              height: 36px;
              width: 100%;

              position: absolute;
              bottom: 0;
              .maue {
                width: 120px;
                height: 33px;
                // background: chocolate;
                background: #f4f5f6;
                border-radius: 18px;
                font-size: 20px;
                font-weight: 500;
                color: #e6564e;
                line-height: 30px;
                letter-spacing: 1px;
                text-align: center;
                float: right;
              }
              .mgbox {
                display: inline-block;
                margin-left: 140px;
              }
            }
          }
        }
      }
      .booklis::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.bakcg::-webkit-scrollbar {
  display: none;
}
</style>
