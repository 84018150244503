import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill'
import comm from './js/comm.js'
import rem from './js/rem.js'
import VueResource from "vue-resource"
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.config.productionTip = false

// import VueWechatTitle from 'vue-wechat-title'
// Vue.use(VueWechatTitle)

// 引入elemeui
import ElementUI from 'element-ui';
import infiniteScroll from 'vue-infinite-scroll'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(infiniteScroll)
Vue.use(ElementUI);
Vue.use(VueResource);
Vue.use(rem);
Vue.use(VideoPlayer)

// 引入iviewui
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

// 引入vue-cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);


//公共AJAX方法
Vue.prototype.Ajax = comm.commAjax;
Vue.prototype.AjaxTeacher = comm.commAjaxTeacher;


router.afterEach((to, from, next) => {
	//百度统计代码
	setTimeout(() => {
	    let _hmt = _hmt || [];
	    (() => {
	      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
	      let hm = document.createElement('script');
	      hm.src = 'https://hm.baidu.com/hm.js?134b3346542cd1990f3f76412316b80d';
	      hm.id = 'baidu_tj';
	      let s = document.getElementsByTagName('script')[0];
	      s.parentNode.insertBefore(hm, s);
	    })();
	}, 50);
	var favicon = document.querySelector('link[rel="icon"]')
    favicon.href='/favicon2.ico';

});



Vue.prototype.GoToDetail = function (product_type, id) {
	if ((!id || id =='') && product_type!=15) return;
	var url = null;
	switch (product_type) {
		case 0: //不做任何处理
			return;
			break;
		case 1: //电子书详情
			url = '../ebookdtl';
			break;
		// case 2: //鲜读
		// 	url = '?id=' + id;
		// 	break;
		case 3: //有声书详情
			// ?id=' + id;
			url = '../Audtl'
			break;
		case 4: //课程
			url = '../couraudio';
			break;
		case 5: //外部链接
			//Todo  问题 跳转外部链接
			url="";
			window.open(id,"_blank");
			break;
		case 11: //直播
			url = "../livedtl"
			if (!id || id == '') return
			break;
		case 12: //专题，产品组合详情，经典集合、其他经营的点击
			url = "../subjectDetail"
			break;
		case 13: //师资，
			url = "../GuestDetail"
			break;
		case 14: //筛选分类
			url = ""
			break;
		case 15: //VIP
			url = "../vip"
			break;
		//case 16:
		//url = ""
		//break;
		default:
			return;
	}
	if (url) {
		var toPath = url.toLowerCase();
		localStorage.setItem("toPagePath", toPath);
		
		/*
		var query = { id: id };
		let routeUrl = this.$router.resolve({
			path: url,
			query: query,
		});
		window.open(routeUrl.href, '_blank');
		*/	   
		
		this.$router.push({
			path: url,
			query: {
				id: id,
			},
		});
		
	}
}

Vue.prototype.GoToAllBook = function (query) {
	this.$router.push({
		path: "Filtrate",
		query: query,
	});
	/*
	let routeUrl = this.$router.resolve({
		path: "Filtrate",
		query: query,
	});
	window.open(routeUrl.href, '_blank');
	*/
}

Vue.prototype.GoToChangeTab = function (product_type) {
	if (!product_type) product_type = 0;
	var path = "";
	switch(product_type)
	{
		case -2:
			path = "../homepage2";
			break;
		case 0:
			path = "homepage";
			break;
		case 1:
			path = "ebook";
			break;
		case 3:
			path = "aubook";
			break;
		case 4:
			path = "course";
			break;
		case 11:
			path = "livebook";
			break;
		case 12:
			path = "subject";
			break;
		case 13:
			path = "teachers";
			break;
	}
	
	var corp = localStorage.getItem("CorpInfo");
	if((path == 'homepage' || path == "ebook") && corp && corp == '兴智书屋'){
		path = '../homepage2';
	}
	var toPath = path.toLowerCase();
	localStorage.setItem("toPagePath", toPath);
	this.$router.push({
		path: path,
	});
}


//页面授权检查
Vue.prototype.CheckCorpAccount = function(option,callback) {
	var withToken = (option && option.token && option.token != '');
	if (withToken) {
		var token = option.token;
		localStorage.setItem("CorpUserInfo", token);
		if(callback) callback();
	} else {
		var token = localStorage.getItem("CorpUserInfo");
		if (!token) {
			 window.top.location.href = "/Login";
		}
	}
}


// Vue.use (VueAxios,axios)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')