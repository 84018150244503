<template>
  <div class="hmpage">
    <div class="pagcent">
      <div class="banner" v-if="banners && banners.length>0">
        <el-carousel
          :interval="5000"
          type="card"
          height="375px"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in banners" :key="index">
            <img
              style="width: 100%; height: 100%; border-radius: 4px"
              :src="item.img_url_pc"
              alt=""
              @click="goToDetailPage(item.skip_type, item.skip_target)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>

	<div
        class="dlwig"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-immediate-check="true"
        infinite-scroll-distance="10"
      >  
      <div
        class="shufangBg"
        v-for="(item, itemNum) in listData.column"
        :key="itemNum"
      >
        <div class="expot" v-if="item.skip_type == 0">
          <div
            class="sort1"
            v-for="(product, index2) in item.list"
            :key="index2"
            @click="goToAllBookPage(item, itemNum, product)"
          >
            <img
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
              "
              alt=""
              mode="scaleToFill"
              :src="product.img_url"
            />
          </div>
        </div>

        <div class="allbook" v-if="item.skip_type == 1">
          <div class="topTit">
            <h2>{{ item.title }}</h2>
            <span @click="goToMorePage(item)">更多 ></span>
          </div>
          <div class="bookas">
            <div
              class="books"
              v-for="(product, index2) in item.list"
              :key="index2"
              @click="goToDetailPage(product.product_type, product.id)"
            >
              <img
                :src="product.img_url"
                style="
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 6px;
                "
                alt=""
              />
              <img
                v-show="product.product_type == 3"
                class="listin"
                src="../../assets//image/icon-listen.png"
                mode=""
              />
            </div>
          </div>
          <div class="bookrif">
            <div
              class="rifmers"
              v-for="(product, index2) in item.list2"
              :key="index2"
              @click="goToDetailPage(product.product_type, product.id)"
            >
              <div class="mgs">
                <img
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                  "
                  :src="product.img_url"
                  alt=""
                />
                <img
                  v-show="product.product_type == 3"
                  class="listin"
                  src="../../assets/image/icon-listen.png"
                  mode=""
                />
              </div>
              <div class="mgRt">
                <h3 class="tels">{{ product.title }}</h3>
                <span class="span1">{{ product.author }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../components/footer";
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      banners: [],
      listData: {
		audio:null,
		column:[]
	  },
      search: {
        id: "0",
        product_type: 0,
        page_index: 1,
        page_size: 3,
        order_by: "hot_at:desc",
        totalPage: 1,
      },
      busy: false,
    };
  },
  components: {
    fooTer,
  },
  created() {
	document.title = "重新定义学习";
	var option = {
		token: this.$route.query.token || "",
	};
	this.CheckCorpAccount(option);
	
	//this.setupBannerList();
	//this.setupListData(2);
	this.setupTab();
  },
  methods: {
	setupTab() {
	  var that = this;
	  this.tabList = [];
	  var url = apiUrl.shuyuan_navbar_get;
	  var param = { client_type: 2 };
	  this.Ajax(url, param, (data) => {
		if(data && data.length == 1){
			data.forEach((item) => {
			  switch(item.product_type){
				  case 1:
					this.GoToChangeTab(1);
					break;
				  case 3:
					this.GoToChangeTab(3);
					break;
				  case 4:
					this.GoToChangeTab(4);
					break;
			  }
			});
		}else{
			this.GoToChangeTab(1);
		}
	  });
	},
    handleScroll(e) {},
    //Banner
    setupBannerList() {
      var that = this;
      var url = apiUrl.banner_get;
      var param = { product_type: this.search.product_type };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (!item.img_url_pc || item.img_url_pc == "") {
            item.img_url_pc = item.img_url;
          }
        });
        that.banners = data;
      });
    },
	//ListData
	setupListData(index) {
	  var that = this;
	  if (index <= 1) {
	    index = 1;
	    that.search.page_index = 1;
	    that.totalPage = 1;
	  }
	  if (that.search.page_index > that.totalPage) {
	    this.busy = false;
	    return;
	  }
	  that.search.page_index = index;
	  if(!that.search.page_index || that.search.page_index <=1) that.search.page_index = 1;
	  var url = apiUrl.shuyuan_list2_get;
	  var param = this.search;
	  this.Ajax(url, param, (data) => {
	    if (that.search.page_index == 1) that.listData.column = [];
	    if (data && data.list.length > 0) {
	      that.listData.column = that.listData.column.concat(data.list);
	      that.totalPage = data.count;
	    }
	    that.totalPage = data.count;
	    that.busy = false;
	  });
	},
	//Next PageList
	loadMore() {
	  if (this.busy) return;
	  this.busy = true;
	  this.setupListData(this.search.page_index + 1);
	},
    //Page: AllBook
    goToAllBookPage(oneItem, index, product) {
      var item = oneItem.list[0];
      if (item && item.product_type == 12 && product.product_type == 12) {
        this.GoToDetail(12, product.id);
      } else if (product.product_type == 14) {
        if (product.product_classes.length > 0) {
          var classItem =
            product.product_classes[product.product_classes.length - 1];
          if (classItem) {
            var query = {
              type: 1,
              type2Sort: 1,
              level: classItem.level,
              id: classItem.id,
            };
            this.GoToAllBook(query);
          }
        }
      } else {
        var item2 = this.shuyuanData.column[index - 1];
        this.goToMorePage(item2);
      }
    },
    //Page: AllBook
    goToMorePage(item) {
      // var oneProduct = item.list[0];
      // var query = {
      //   type: oneProduct.product_type,
      //   type2Sort: 1,
      // };
      var query = {
        type: 1,
        type2Sort: 1,
      };
      if (item.product_classes.length > 0) {
        var classItem = item.product_classes[item.product_classes.length - 1];
        if (classItem) {
          query.level = classItem.level;
          query.id = classItem.id;
        }
      }

      this.GoToAllBook(query);
    },
    //Page: Detail
    goToDetailPage(product_type, id) {
      if (product_type == 14 || product_type == 3) {
      }
      this.GoToDetail(product_type, id);
    },
  },
};
</script>

<style lang="scss">
.hmpage {
  width: 100%;
  height: 100%;
  min-width: 1157px;
  background: #f7f7f7;
  cursor: default;

  .back-ball {
    width: 100px;
    height: 100px;
    background: red;
    z-index: 999;
  }
  .pagcent {
    width: 1157px;
    margin: 0 auto;
    min-height: 800px;
    .banner {
      height: 420px;
      width: 100%;
      border: 1px solid #fff;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 12px;
      .el-carousel {
        margin-top: 23px;
        .el-carousel__item {
          width: 750px;
          margin-left: -85px;
        }
        // .el-carousel__item:nth-child(2n) {
        //   background-color: red;
        // }

        // .el-carousel__item:nth-child(2n + 1) {
        //   background-color: yellow;
        // }
      }
    }
    .expot {
      width: 100%;
      height: 170px;
      margin: 0 auto;
      margin-top: 10px;
      // border: 1px solid red;
      background: #fff;
      overflow-y: hidden;

      .sort1 {
        display: inline-block;
        width: 265px;
        height: 140px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
        margin: 15px 0px 0 20px;
      }
      .sort1:hover {
        cursor: pointer;
      }
    }
    .allbook {
      width: 100%;
      // background: pink;
      margin: 0 auto;
      margin-top: 10px;
      border: 1px solid #fff;
      background: #fff;
      .topTit {
        width: 100%;
        height: 38px;
        // border: 1px solid;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 28px;
        h2 {
          font-size: 27px;
          font-weight: 800;
          color: #2b2b2b;
          letter-spacing: 1px;
          margin-left: 42px;
        }
        span {
          font-size: 18px;
          font-weight: 500;
          color: #999999;
          letter-spacing: 1px;
          margin-right: 35px;
        }
        span:hover {
          cursor: pointer;
        }
      }

      .bookas {
        width: 1010px;
        // height: 370px;
        margin: 0px 0 0 42px;

        overflow: hidden;
        .books {
          display: inline-block;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          width: 130px;
          height: 173px;

          margin-right: 38px;
          margin-top: 24px;
          position: relative;
        }
        .listin {
          width: 30px;
          height: 30px;
          z-index: 9;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        .books:hover {
          cursor: pointer;
        }
      }
      .bookrif {
        width: 1010px;

        margin: 0 0 0 42px;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #fff;

        margin-top: 25px;
        .rifmers {
          width: 483px;
          height: 173px;

          display: flex;
          justify-content: space-between;
          margin-bottom: 28px;
          .mgs {
            width: 130px;
            height: 100%;
            position: relative;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          }
          .listin {
            width: 30px;
            height: 30px;
            z-index: 9;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .mgs:hover {
            cursor: pointer;
          }
          .mgRt {
            height: 173px;
            width: 328px;

            .tels {
              font-size: 18px;
              font-weight: 500;
              color: #2b2b2b;
              letter-spacing: 1px;
              margin-bottom: 5px;
              // border: 1px solid red;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
            .span1 {
              font-size: 16px;
              font-weight: 500;
              color: #666666;
              letter-spacing: 1px;
              margin-bottom: 8px;
              // height: 38px;
              overflow: hidden;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
            .p1 {
              font-size: 19px;
              font-weight: 500;
              color: #cccccc;
              letter-spacing: 1px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              margin-bottom: 13px;
              overflow: hidden;
            }
            .motk {
              width: 108px;
              height: 29px;
              background: #f4f5f6;
              border-radius: 19px;
              font-size: 20px;
              font-weight: 500;
              color: #e6564e;
              line-height: 29px;
              letter-spacing: 1px;
              text-align: center;
              float: right;
            }
          }
          .mgRt:hover {
            cursor: pointer;
          }
        }
     

		    .rifmers:hover {
					.tels {
						color: #E6564E;
					}
				}
			}
    }
  }
}
</style>
