<template>
	<div class="maxBox" ref="main" style="height: 100vh; overflow: auto" v-infinite-scroll="loadMore"
		:infinite-scroll-immediate-check="true" infinite-scroll-distance="10" @scroll="handleScroll"
		@click="removeInputState()">
		<BackTop :bottom="300" :right="backTopRight" style="position: fixed;" :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      " on-click="scrollToTop()">
			<div class="top" @click="scrollToTop">
				<div class="divg">
					<img style="width: 100%; height: 100%" src="../../assets/image/shang.png" alt="" />
				</div>
			</div>
		</BackTop>
		<div class="topNav">
			<div class="logipt">
				<!-- logo -->
				<div class="logoBox">
					<div class="logo" @click="GoToIndex" style="cursor: pointer">
						<img style="width: 100%; height: 100%" src="../../assets/image/logo.png" alt="" />
					</div>

					<!-- <router-link to="/searchpage"> -->
					<div class="inputs" style="width: 485px !important; background: #f7f7f7ff">
						<img src="../../assets/image/search.png" alt="" />
						<input ref="inputRef" class="ipus" style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              " confirm-type="search" placeholder-class="text" placeholder="书名、作者、关键字" @focus="input_focusIpt"
							v-on:keyup.enter="input_search(input_content)" v-model="input_content" />
						<!-- @blur="input_blurIpt" -->
						<div class="btnBox" style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              " @click="input_search()">
							搜索
						</div>
					</div>
					<div class="showContent" v-if="isInputing2">
						<div class="titleBg">
							<div class="title">热门搜索</div>
							<div class="clear"></div>
						</div>
						<div class="item" v-for="(item, index) in hotKeywords" :key="index"
							@click="input_search(item.title)">
							{{ item.title }}
						</div>
					</div>
					<!-- </router-link> -->
				</div>
				<!-- logins -->
				<!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
				<!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
			</div>
			<!-- tab栏 -->
			<div class="tablistBg" v-if="isShowNav"
				style="height:70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);"></div>
			<div class="tablistBg" :class="isShowNav ? 'topNavShow' : ''" style="margin-top: 0px">
				<div class="tablist">
					<div class="tab-as" v-for="(item, index) in tabList" :key="index"
						:class="{ tabActive: item.product_type == num }" @click="GoToChangeTab(item.product_type)" style="color: #333333">
						{{ item.title }}
						<span v-if="item.product_type == 11" :class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
							style="color:#47CD96;padding-left:5px">LIVE</span>
					</div>
				</div>
			</div>
			<div class="hovers" ref="hoves">
				<div class="vipmoy">
					<img class="viplo" src="../../assets/image/viplogo.png" alt="" />
					<span class="spanv">[续费VIP]</span>
				</div>
				<div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
				<div class="times">2021.08.10 到期</div>
				<div class="fenge"></div>
				<div class="fosnam">
					<p>我的订单</p>
					<p>我的卡券</p>
				</div>
				<div class="fonbot">
					<p>物流查询</p>
					<p>我的企业</p>
					<p>关于我们</p>
					<p>作者助手</p>
				</div>
				<div class="nect">退出</div>
			</div>
		</div>
		<!-- content -->

		<div class="liveMax">
			<div class="livecent">
				<div class="banner" v-if="banners && banners.length>0">
					<el-carousel :interval="5000" type="card" height="375px" indicator-position="none">
						<el-carousel-item v-for="(item, index) in banners" :key="index">
							<img @click="GoToDetail(item.skip_type, item.skip_target)"
								style="width: 100%; height: 100%; border-radius: 4px" :src="item.img_url_pc" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="monBox">
					<div class="tmsd" v-for="(month, inde) in listData" :key="inde">
						<div class="disz" @click="changeShowState(inde)">
							<span class="span1" v-if="month.is_current_year==1"> {{ month.webcastmon }}月</span>
							<span class="span1" v-if="month.is_current_year!=1"> {{ month.webcastyear }}年{{ month.webcastmon }}月</span>
							<img ref="mgs" class="jiant" :class="{ UPBoxImgAC: month.state }"
								src="../../assets/image/icon_live.png" alt="" />
							<span class="span2" v-if="month.state">收起</span>
							<span class="span3" v-else>可展开</span>
						</div>
						<div style="margin-left: 57px" class="dtals" v-for="(iem, idm) in month.list" :key="idm"
							v-show="month.state" @click="goToDetailPage(iem.id)">
							<div class="cents">
								<div class="days">
									<div class="msxz">{{ iem.webcastday }}日</div>
								</div>
								<div class="mkls">
									<div class="imag">
										<img :src="iem.image_url" style="
                      width: 100%;
                      /*height: 100%;
                      object-fit: cover;
                      border-radius: 9px;*/
                    " alt="" />
									</div>
									<div class="kols">
										<div class="tits">
											{{ iem.title }}
										</div>
										<div class="users">
											{{ iem.lecturer_names || "" }}
										</div>
										<div class="times">
											{{ iem.begindate }} - {{ iem.enddate }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<foo-ter />
		</div>
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				num: 11,
				tabList: [],
				productTypeData: {},
				busy: false,
				product_type: 11,
				banners: [],
				listData: [],
				webcastSearch: {
					page_index: 1,
					page_size: 1000,
					keyword: "",
					totalPage: 0,
				},
				isShowNav: false,
				input_content: "",
				hotKeywords: [], //热门搜索
				isInputing2: this.isInputing,
				isShowScrollBtn: false,
				backTopRight: 200,
				isShowScrollBtn2: false,
			};
		},
		components: {
			fooTer,
		},
		created() {
			var that = this;
			document.title = "直播LIVE - 华章书院";
			this.setupList();
			this.setupBannerList();
			this.setupListData();
			this.hotKeywordsGet();

			window.addEventListener("resize", function() {
				that.css_caculateBackTopRight();
			});
			this.css_caculateBackTopRight();
		},
		methods: {
			GoToIndex(){
				if(this.tabList && this.tabList.length>0){
					this.GoToChangeTab(this.tabList[0].product_type)
				}
			},
			css_caculateBackTopRight() {
				var screenWidth = document.body.offsetWidth;
				if (screenWidth < 1200) {
					this.isShowScrollBtn2 = false;
				} else {
					this.isShowScrollBtn2 = true;
				}
				var right = (screenWidth - 1157) / 2 - 30;
				this.backTopRight = right || 200;
				// console.log(screenWidth);
			},

			scrollToTop() {
				this.$refs.main.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			removeInputState() {
				this.isInputing = false;
				this.input_blurIpt();
				this.$forceUpdate();
			},
			hotKeywordsGet() {
				var that = this;
				this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
					if (data && data.length > 0) {
						that.hotKeywords = data;
					}
				});
			},
			input_search(title) {
				if (title && title != "") this.input_content = title;
				this.$refs.inputRef.blur();
				this.isInputing2 = false;
				var url = "/SearchPage";
				var query = {
					inputStr: this.input_content
				};
				this.$router.push({
					path: url,
					query: query
				});
				/*
				let routeUrl = this.$router.resolve({
				  path: url,
				  query: query,
				});
				window.open(routeUrl.href, "_blank");
				*/
				this.input_content = "";
			},
			input_focusIpt() {
				setTimeout(() => {
					this.isInputing2 = true;
					this.$refs.inputRef.focus();
					this.$forceUpdate();
				}, 200);
			},
			input_blurIpt() {
				this.isInputing2 = false;
				this.$refs.inputRef.blur();
				// setTimeout(() => {
				// this.isInputing2 = true;
				//   this.$forceUpdate();
				// }, 500);
			},
			handleScroll(e, item) {
				let scrollY = e.target.scrollTop;
				console.log(e.target.scrollTop);
				var height = 90;
				if (scrollY >= height) {
					this.isShowNav = true;
					this.isInputing = false;
					this.input_blurIpt();
				} else {
					this.isShowNav = false;
				}
				this.isShowScrollBtn = scrollY > 200;
				this.$forceUpdate();
			},
			loadMore() {},
			setupList() {
				var that = this;
				this.tabList = [];
				var url = apiUrl.shuyuan_navbar_get;
				var param = {
					client_type: 2
				};
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (item.product_type == 11) {
							item.title = "直播";
						}
						that.tabList.push(item);
					});
					that.productTypeData = data;
				});
			},
			handellet() {
				if (this.$refs.hoves.style.display == "none") {
					this.$refs.hoves.style.display = "block";
				} else if ((this.$refs.hoves.style.display = "block")) {
					this.$refs.hoves.style.display = "none";
				}
			},
			//Banner
			setupBannerList() {
				var that = this;
				var url = apiUrl.banner_get;
				var param = {
					product_type: this.product_type
				};
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (!item.img_url_pc || item.img_url_pc == "") {
							item.img_url_pc = item.img_url;
						}
					});
					that.banners = data;
				});
			},
			//ListData
			setupListData() {
				var that = this;
				var url = apiUrl.webcast_list_get;
				var param = that.webcastSearch;
				this.Ajax(url, param, function(data) {
					if (data && data.list.length > 0) {
						//按月份分组
						for (var i = 0; i < data.list.length; i++) {
							var item = data.list[i];
							var exsit = false;
							for (var j = 0; j < that.listData.length; j++) {
								var webcast = that.listData[j];
								if (webcast.webcastmon == item.webcastmon && webcast.webcastyear == item.webcastyear) {
									webcast.list.push(item);
									exsit = true;
									break;
								}
							}
							if (!exsit) { 
								that.listData.push({
									webcastyear: item.webcastyear,
									webcastmon: item.webcastmon,
									is_current_year :item.is_current_year,
									state: true,
									list: [item],
								});
							}
						}
						that.totalPage = data.count;
					}
				});
			},
			//Page Detail
			goToDetailPage(id) {
				this.GoToDetail(this.product_type, id);
			},
			//Click: Show/Close
			changeShowState(i) {
				this.listData[i].state = !this.listData[i].state;
				this.listData = this.listData;
			},
		},
	};
</script>

<style lang="scss">
	.maxBox {
		width: 100%;
		cursor: default;
		min-width: 1157px;
		// background: #d8e4f8;

		.top {
			width: 70px;
			height: 70px;
			background: #000000;
			border-radius: 8px;
			opacity: 0.19;
			display: flex;
			align-items: center;

			.divg {
				width: 32px;
				height: 18px;
				margin: 0 auto;
			}
		}

		.topNav {
			width: 100%;
			// height: 173px;
			border: 1px solid #fff;
			background: #fff;
			position: relative;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.hovers {
				width: 221px;
				height: 506px;
				z-index: 20;
				position: absolute;
				right: 290px;
				top: 90px;
				background: #fff;

				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
				display: none;

				.vipmoy {
					width: 180px;
					height: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0 auto;
					margin-top: 14px;

					.viplo {
						width: 34px;
						height: 15px;
					}

					.spanv {
						width: 65px;
						height: 20px;
						font-size: 14px;
						font-weight: 500;
						color: #e6544c;
					}
				}

				.names {
					width: 171px;
					height: 25px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin: 0 auto;
					font-size: 18px;
					font-weight: 500;
					color: #333333;
					margin-top: 8px;
				}

				.times {
					width: 123px;
					height: 20px;
					margin-left: 22px;
					margin-top: 9px;
					font-size: 14px;
					font-weight: 500;
					color: #999999;
				}

				.fenge {
					width: 179px;
					height: 1px;
					background: #eeeeee;
					margin: 0 auto;
					margin-top: 18px;
					margin-bottom: 18px;
				}

				.fosnam {
					width: 179px;
					height: 100px;
					// border: 1px solid red;
					margin: 0 auto;
					border-bottom: 1px solid #eeeeee;

					p {
						width: 76px;
						height: 25px;
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						line-height: 25px;
						letter-spacing: 1px;
						margin-top: 20px;
					}
				}

				.fonbot {
					width: 179px;
					height: 190px;
					// border: 1px solid;
					margin: 0 auto;
					border-bottom: 1px solid #eeeeee;

					p {
						width: 76px;
						height: 25px;
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						line-height: 25px;
						letter-spacing: 1px;
						margin-top: 20px;
					}
				}

				.nect {
					width: 179px;
					height: 57px;
					margin: 0 auto;
					line-height: 57px;
					font-size: 18px;
					font-weight: 500;
					color: #333333;
				}
			}

			.logipt {
				width: 1157px;
				height: 60px;

				margin: 0 auto;
				margin-top: 27px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.logoBox {
					height: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;

					.logo {
						width: 360px;
						height: 100%;
					}

					.inputs {
						width: 140px;
						height: 34px;
						background: #f7f7f7;
						border-radius: 25px;
						margin-left: 30px;
						text-align: center;
						font-size: 20px;
						line-height: 50px;
						display: flex;
						align-items: center;

						img {
							width: 17px;
							height: 17px;
							margin-left: 12px;
							margin-right: 12px;
						}

						span {
							font-size: 16px;
							font-weight: 500;
							color: #c9c9c9;
						}
					}

					.showContent {
						position: absolute;
						top: 50px;
						left: 320px;
						width: 452px;
						// height: 216px;
						background: white;
						z-index: 99999;
						padding: 18px;

						.titleBg {
							display: flex;
							justify-content: space-between;
							margin-bottom: 3px;

							.title {
								width: 64px;
								height: 22px;
								font-size: 16px;
								font-weight: 500;
								color: #2b2b2b;
								line-height: 22px;
							}

							.clear {
								width: 32px;
								height: 22px;
								font-size: 16px;
								font-weight: 500;
								color: #e6564e;
								line-height: 22px;
							}
						}

						.item {
							height: 22px;
							font-size: 16px;							
							font-weight: 500;
							color: #999999;
							line-height: 22px;
							margin-top: 10px;
							cursor: pointer;
						}
					}
				}

				.logins {
					width: 95px;
					height: 29px;
					font-size: 21px;
					font-weight: 500;
					color: #e6564e;
				}

				.users {
					width: 210px;
					height: 47px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					div {
						font-size: 21px;
						font-weight: 600;
						color: #e6564e;
						line-height: 25px;
						width: 130px;
						height: 25px;
						// border: 1px solid red;
						border-right: 3px solid #d8d8d8;
					}
				}
			}

			.tablistBg {
				width: 100%;
				background: white;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.topNavShow {
				position: fixed !important;
				z-index: 99 !important;
				top: 0 !important;
				// left: 0 !important;
				// left: calc((100% - 1157px) / 2) !important;
				background: white;
				margin-top: 0 !important;
				// width: calc(100% - 15px);
				width: 100%;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			}

			.tablist {
				height: 70px;
				width: 1157px;
				margin: 0 auto;
				display: flex;
				justify-content: space-around;
				align-items: center;
				font-size: 21px;
				font-weight: 400;
				color: #bdbdbd;
				position: relative;
				margin-top: 0px !important;

				.tabActive {
					font-weight: 500;
					color: #e6564e !important;
					padding-bottom: 5px;
					// border: 1px solid red;
					text-align: center;

					display: flex;
					justify-content: center;
					font-size: 21px;
				}

				.tab-as {
					text-align: center;
					display: flex;
					justify-content: center;
					font-size: 18px;
				}

				.tab-as:hover {
					color: #e6564e !important;
				}

				.tab-as:hover:before {
					content: "";
					width: 40px;
					border-radius: 5px;
					height: 3px;
					position: absolute;
					text-align: center !important;
					background-color: #e45754;
					display: block;
					bottom: 0;
				}

				.tabActive2 {
					margin-left: 0;
				}

				.unTabActive2 {
					margin-left: -6px;
				}

				.tabActive::before {
					content: "";
					width: 40px;
					border-radius: 5px;
					height: 3px;
					position: absolute;
					text-align: center !important;
					// border-bottom: 4px solid #e6564e;
					background-color: #e45754;
					display: block;
					bottom: 0;
				}
			}

			.tablist:hover {
				cursor: pointer;
			}
		}

		.liveMax {
			width: 100%;
			min-width: 1157px;
			background: #f7f7f7;

			.livecent {
				width: 1157px;
				margin: 0 auto;

				.banner {
					height: 420px;
					width: 100%;
					border: 1px solid #fff;
					background: #fff;
					margin: 0 auto;
					margin-bottom: 12px;

					.el-carousel {
						margin-top: 23px;

						.el-carousel__item {
							width: 750px;
							margin-left: -85px;
						}
					}
				}

				.monBox {
					width: 100%;
					// height: 1000px;
					min-height: 500px;
					margin-top: 16px;
					// border: 1px solid;
					background: #fff;
					padding-bottom: 20px;

					.tmsd {
						padding-top: 10px;
						background: #fff;

						.disz {

							// margin-bottom: 15px;
							.span1 {
								font-size: 24px;
								font-weight: 800;
								color: #333333;
								line-height: 40px;
								letter-spacing: 1px;
								margin-left: 57px;
							}

							.jiant {
								width: 7px;
								height: 12px;
								margin-bottom: 2px;
								margin: 0 5px;
							}

							.UPBoxImgAC {
								transform: rotate(90deg);
								height: 26upx;
								width: 18upx;
								margin-top: 14upx;
							}

							.span2 {
								font-size: 14px;
								font-weight: bold;
								color: #e6564e;
								letter-spacing: 1px;

								// padding-bottom: 20px;
							}

							.span3 {
								font-size: 14px;
								font-weight: bold;
								color: #e6564e;
								letter-spacing: 1px;
							}
						}

						.disz:hover {
							cursor: pointer;
						}

						.dtals {
							border-left: 1px solid #d8d8d8;

							// border: 1px solid blue;
							.cents:hover {
								.mkls {
									.kols {
										.tits {
											color: #E6564E;
										}
									}
								}
							}

							.cents {
								height: 105px;
								width: 100%;
								display: flex;
								margin-bottom: 54px;

								// background: coral;
								.days {
									height: 100%;
									width: 86px;

									.msxz {
										padding: 32px 0 0 18px;
										font-size: 20px;
										color: #333333;
										letter-spacing: 1px;
									}

									.msxz:hover {
										cursor: default;
									}
								}

								.mkls {
									height: 100%;
									width: 774px;

									display: flex;
									justify-content: space-between;

									.imag {
										width: 177px;
										height: 100%;
										background: #d8d8d8;
										border-radius: 9px;
										overflow-y: hidden;
									}

									.imag:hover {
										cursor: pointer;
									}

									.kols {
										height: 100%;
										width: 554px;
										position: relative;

										.tits {
											font-size: 18px;
											font-weight: bold;
											color: #333333;
											letter-spacing: 1px;
										}

										.users {
											margin-top: 8px;
											font-size: 16px;
											font-weight: bold;
											color: #666666;
										}

										.times {
											font-size: 14px;
											font-weight: bold;
											color: #999999;
											position: absolute;
											bottom: 0;
										}
									}

									.kols:hover {
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
