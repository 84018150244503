<template>
	<div class="ebookdtl">
		<!-- <div class="top" @click="backTop">
      <div class="divg">
        <img
          style="width: 100%; height: 100%"
          src="../../assets/image/shang.png"
          alt=""
        />
      </div>
    </div> -->
		<!-- <BackTop></BackTop> -->

		<div class="eblmax">
			<div class="mianb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<!-- <el-breadcrumb-item :to="{ path: '/ebook' }">电子书</el-breadcrumb-item> -->
					<el-breadcrumb-item style="font-weight: bold;">电子书</el-breadcrumb-item>
					<el-breadcrumb-item>{{ data.title }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="dTals">
				<div class="magsL">
					<div class="magz" @click="previewImgClick(1)">
						<img
							:src="data.img_url"
							style="width: 100%; height: 100%; object-fit: cover"
							alt="" />
						<img class="ems" src="../../assets/image/fangda.png" alt="" />
					</div>
				</div>
				<div class="totlR">
					<div class="rtops">
						<div class="tits">{{ data.title }}</div>
						<div class="addys">{{ data.author }}</div>
					</div>
					<div class="rbotms">
						<div>
							<div class="readBtn" v-if="data.is_crop == 0" @click="goToReader(data.uuid,data.chapterid)">
								在线试读
							</div>
							<div class="readBtn" v-if="data.is_crop == 1" @click="goToReader(data.uuid,data.chapterid)">
								在线阅读
							</div>
						</div>
						<div class="botRt">
							<div class="rt1" style="color: #d8d8d8">
								阅读次数：
								<span style="color: #000"> {{ data.view_count }}</span>
							</div>
							<div class="rt2" style="color: #d8d8d8">
								<div class="tssz">
									有<span style="color: #000">{{ data.readers_count }}</span>人正在阅读此书
								</div>

								<!-- <div
									class="tsd"
									@click="goToPersonPage(data.id)"
									v-if="data.readers_list && data.readers_list.length > 0">
									<img
										v-for="(item, index) in data.readers_list"
										:key="index"
										v-show="index < 3"
										class="photo"
										:src="item.avatar"
										style="width: 30px; height: 30px; border-radius: 50%"
										alt="" />
									<img
										style="width: 9px; height: 9px"
										src="../../assets/image/icon_rigth.png"
										alt="" />
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="hongb">
					<div class="qrcode">
						<div class="qr-code" id="qrcode_id" ref="qrcode_id"></div>
					</div>
					<div class="p1">微信阅读</div>
					<div class="p2">{{ data.title }}</div>
				</div> -->
				<!-- <div class="zxsm">
					  <img class="mlsaz" src="../../assets/image/loms.png" alt="" />
					  <p>扫码</p>
					  <p>在线</p>
					  <p>阅读</p>
					  <div class="saomaBg" v-if="data.qrcode_url">
						<div class="qrBg">
						  <img class="saoma" src="../../assets/image/saoma.png" alt="" />
						  <div class="qrcode" id="qrcode_id" ref="qrcode_id"></div>
						  <div class="foas">
							<p>扫描二维码</p>
							<p>在线阅读电子书</p>
						  </div>
						</div>
					  </div>
					</div> -->
			</div>

			<div class="tmsd">
				<div class="disz" @click="handleshow()">
					<span class="span1">目录</span>
					<div>
						<img
							ref="mgs"
							class="jiant"
							:class="!isShow ? 'UPBoxImgAC' : 'jiant'"
							src="../../assets/image/icon_live.png"
							alt="" />
						<!-- <span class="span2">收起</span> -->
						<span class="span3" ref="shows2">展开</span>
					</div>
				</div>
				<div style="margin-left: 57px" class="dtactive" ref="actis">
					<div class="catalogue">
						<!-- <div
							class="losl"
							v-for="item in contentData"
							:key="item.id"
							v-if="item.is_crop == 0">
							<span class="spone">{{ item.title }}</span>
						</div> -->

						<div
							class="losl cursor"
							v-for="item in contentData"
							:key="item.id"
							
							:class="{
								foldLevel1: item.level == 1,
								foldLevel2: item.level == 2,
								foldLevel3: item.level == 3,
								actived: item.is_curr_view == 1,
								noOpen: item.open == 0,
								
							  }"
							@click="goFoldDetail(item)">
							<span class="spone">{{ item.title }}</span>
							<img class="lock" src="../../assets/image/lock.png" alt="" v-if="item.open == 0" />
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="catalog">
        <el-button
          @click="isShowFold = true"
          type="primary"
          style="width: 647px; background: #fff; border: none"
        >
          <div class="catLft">
            <img class="ms1" src="../../assets/image/gang.png" alt="" />
            <span class="pan1">电子书目录</span>
          </div>
        </el-button>
        <el-button
          v-if="data.products != null"
          @click="goToAutoBookDetail()"
          type="primary"
          style="
            border: none;
            justify-content: flex-end;
            display: flex;
            background: #fff;
          "
        >
          <div class="catLft">
            <img class="ms1" src="../../assets/image/erjiji.png" alt="" />
            <span class="pan1">听书</span>
          </div>
        </el-button>
        <el-drawer
          title="我是标题"
          :visible.sync="isShowFold"
          :with-header="false"
        >
          <div class="popupTitle">
            <div class="diva">目录</div>
            <div class="divb" @click="changeSortType">
              <div class="dism">排序</div>
              <div class="divimg">
                <img
                  class="ilmge"
                  :class="sort == 0 ? '' : 'sort2'"
                  src="../../assets/image/colors.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="fenj"></div>
          <div class="catalogue">
            <div
              class="losl"
              v-for="item in contentData"
              :key="item.id"
              v-show="!checkIsShowFolder(item)"
              :class="{
                noOpen: item.open == 0,
                foldLevel1: item.level == 1,
                foldLevel2: item.level == 2,
                foldLevel3: item.level == 3,
              }"
              @click="goFoldDetail(item)"
            >
              <span class="spone">{{ item.title }}</span>
            </div>
          </div>
        </el-drawer>

        <div class="catRg" v-if="data.product_type == 3">
          <img class="ms2" src="../../assets/image/erjiji.png" alt="" />
          <span class="pan2">听书</span>
        </div>
      </div> -->
			<div class="writers">
				<h5>作者</h5>
				<div class="thbox">
					<div class="thors">
						<div class="mlge">
							<!-- <img
								style="width: 100%; height: 100%; border-radius: 50%"
								alt=""
								v-if="data.author_info && data.author_info.avatar"
								:src="data.author_zone.avatar" />
							<img
								style="width: 100%; height: 100%; border-radius: 50%"
								alt=""
								src="../../assets/image/morent.png"
								v-else /> -->
							<!-- src="../../assets/image/buddha.png" -->
							
							<img
								style="width: 100%; height: 100%; border-radius: 50%"
								alt=""
								v-if="data.lecturer_info && data.lecturer_info.img_url_compressed"
								:src="data.lecturer_info.img_url_compressed" />
							<img
								style="width: 100%; height: 100%; border-radius: 50%"
								alt=""
								src="../../assets/image/morent.png"
								v-else />
						</div>
						<div class="mlRgt">
							<div class="titi" v-if="data.lecturer_info && data.lecturer_info.name">{{ data.lecturer_info.name }}</div>
							<div class="titi" v-else>{{ data.author }}</div>
							
							<p v-html="data.lecturer_info.desc" v-if="data.lecturer_info && data.lecturer_info.desc"></p>
							<p v-html="data.aboutauthor" v-else></p>
						</div>
						<!-- <div class="iconts">
							<img
								v-if="data.author_id && data.author_id != ''"
								src="../../assets/image/icon_rigth.png"
								alt="" />
						</div> -->
					</div>
				</div>
				<div></div>
			</div>
			<div class="nrom">
				<h4>简介</h4>
				<div class="blql">
					<div
						class="divs"
						v-for="(item, index) in data.product_classes"
						:key="index"
						@click="goToAllBookPage(item)">
						{{ item.title }}
					</div>
				</div>
				<div class="nmcent" v-html="data.desc"></div>
			</div>
		</div>
		<div
			class="popContainer"
			v-show="isshow"
			ref="popls"
			@click="previewImgClick">
			<div class="maxmage">
				<img
					:src="data.img_url_original"
					style="width: 100%; height: 100%"
					alt="" />
			</div>
		</div>

	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				isShow: true,
				isshow: false,
				contentData: [],
				contentFoldList: [], //折叠的目录的父order或子parent
				sort: 0,
				isShowFold: false,
				id: "",
				data: {
					status: {},
				},
				isLogin:false
			};
		},
		components: {
			fooTer,
			qrcode: QRCode,
		},
		// mounted() {
		//   window.addEventListener("scroll", this.scrollToTop);
		// },
		// destroyed() {
		//   window.removeEventListener("scroll", this.scrollToTop);
		// },
		created() {
			document.title = "电子书";
			this.isLogin = localStorage.hasOwnProperty("CorpUserInfo");
			var option = {
				id: this.$route.query.id || "",
				token: this.$route.query.token || "",
			};
			this.CheckCorpAccount(option);
			if (option && option.id) {
				this.id = option.id;
				this.setupDetailData();
				this.setupContentFolder();
			}
		},
		methods: {
			handleshow() {
				if (this.$refs.shows2.innerHTML == "展开") {
					this.$refs.actis.style.height = "100%";
					this.$refs.shows2.innerHTML = "收起";
					this.$refs.mgs.style.transform = "rotate(90deg)";
				} else {
					this.$refs.shows2.innerHTML = "收起";
					this.$refs.actis.style.height = 140 + "px";
					this.$refs.shows2.innerHTML = "展开";
					this.$refs.mgs.style.transform = "rotate(0deg)";
				}
			},
			goToAutoBookDetail() {
				var products = this.data.products[0];
				this.GoToDetail(products.product_type, products.id);
			},
			//Detail
			setupDetailData() {
				var that = this;
				var url = apiUrl.book_detail_get;
				var param = { id: this.id };
				this.Ajax(url, param, (data) => {
					if (data) {
						that.data = data;
						document.title = data.title + " - 电子书";

						// if (data.qrcode_url && data.qrcode_url != "") {
						// 	// setTimeout(() => {
						// 	that.setupQRCode(data.qrcode_url);
						// 	// }, 500);
						// }
					}
				});
			},
			//QRCode
			setupQRCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			//Folder
			setupContentFolder() {
				var that = this;
				var url = apiUrl.book_content_get;
				var param = { id: this.id };
				this.Ajax(url, param, (data) => {
					if (data) {
						that.contentData = data;
					}
				});
			},
			//Page: Author
			goToAuthorDetailPage() {
				if (this.data.author_id && this.author_id != "") {
					var url = "GuestDetail";
					var query = {
						name: this.data.author,
						id: this.data.author_id || 0,
					};
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
					// this.$router.push({
					//   path: url,
					//   query: query,
					// });
				}
			},
			//Page: Read personList
			goToPersonPage(id) {
				localStorage.setItem("toPagePath", "ebook");
				var url = "seebook";
				var query = {
					product_type: this.data.product_type,
					product_name: this.data.title,
					id: id,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				// this.$router.push({
				//   path: url,
				//   query: query,
				// });
			},
			//Page: AllBook
			goToAllBookPage(item) {
				localStorage.setItem("toPagePath", "ebook");
				var query = {
					type: 1,
					level: item.level,
					id: item.id,
				};
				this.GoToAllBook(query);
			},
			//Click: Preview
			previewImgClick(type) {
				if (type == 1) {
					this.isshow = true;
				} else {
					this.isshow = false;
				}
			},
			//Click: Sort
			changeSortType() {
				if (this.contentData) {
					this.sort = this.sort == 0 ? 1 : 0;
					this.contentData = this.contentData.reverse();
				}
			},
			//Click: Fold Detail
			goFoldDetail(item) {
				//目录详情点击
				if (item && item.uuid && item.chapterid) {
					if (item.open == 1) {
						this.goToReader(item.uuid,item.chapterid);
					} else {
						this.$message({
							message: '暂无权限阅读当前章节',
							type: 'warning',
							offset: 330,
							duration: 2000
						});
					}
				}
			},
			goToReader(uuid,chapterid){
				/*
				this.$router.push({
					path: "ebookrdr",
					query: {
						uuid: uuid,
						chapterid: chapterid,
					},
				});
				*/
				var url = "ebookrdr";
				var query = {
					uuid: uuid,
					chapterid: chapterid,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			//Check Show
			checkIsShowFolder(item) {
				var that = this;
				if (that.contentFoldList.indexOf(item.parent) > -1) {
					return true;
				} else {
					return false;
				}
			},
			// backTop() {
			//   const that = this;
			//   let timer = setInterval(() => {
			//     let ispeed = Math.floor(-that.scrollTop / 5);
			//     document.documentElement.scrollTop = document.body.scrollTop =
			//       that.scrollTop + ispeed;
			//     if (that.scrollTop === 0) {
			//       clearInterval(timer);
			//     }
			//   }, 16);
			// },

			// // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			// scrollToTop() {
			//   const that = this;
			//   let scrollTop =
			//     window.pageYOffset ||
			//     document.documentElement.scrollTop ||
			//     document.body.scrollTop;
			//   that.scrollTop = scrollTop;
			//   if (that.scrollTop > 60) {
			//     that.btnFlag = true;
			//   } else {
			//     that.btnFlag = false;
			//   }
			// },
		},
	};
</script>

<style lang="scss">
	.ebookdtl {
		width: 100%;
		background: #f7f7f7;
		min-width: 1157px;
		position: relative;
		cursor: default;
		margin: 20px auto;
		

		// .top {
		//   width: 70px;
		//   height: 70px;
		//   background: #000000;
		//   border-radius: 8px;
		//   opacity: 0.19;
		//   display: flex;
		//   align-items: center;
		//   z-index: 999999;
		//   position: fixed;
		//   top: 660px;
		//   right: 80px;
		//   .divg {
		//     width: 32px;
		//     height: 18px;
		//     margin: 0 auto;
		//   }
		// }
		.popContainer {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 999;

			.maxmage {
				width: 400px;
				height: 500px;
				background: #fff;
				margin: 0 auto;
				margin-top: 200px;
			}
		}

		.eblmax {
			width: 1157px;
			// border: 1px solid red;
			
			margin: 0 auto;

			.mianb {
				width: 1157px;
				height: 75px;
				// border: 1px solid red;
				margin: 0 auto;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 500;
				color: #363636;

				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #e6554d !important;
				}
			}

			.dTals {
				width: 1157px;
				height: 275px;

				display: flex;
				background: #fff;
				position: relative;

				// border: 1px solid red;
				.hongb {
					width: 162px;
					height: 178px;
					// border: 1px solid red;
					position: absolute;
					right: 60px;
					top: -20px;
					background-image: url("../../assets/image/hongb.png");
					background-size: 100%;

					.qrcode {
						width: 120px;
						height: 120px;
						border: 5px solid #fff;
						margin: 9px 0px 0 14px;

						.qr-code {
							width: 100%;
							height: 100%;

							//
						}
					}

					div {
						font-size: 12px;
						font-weight: 500;
						color: #ffffff;

						letter-spacing: 2px;
						text-align: center;
					}

					.p1 {
						margin-top: 7px;
						// border: 1px solid red;
					}

					.p2 {
						text-align: center;
						width: 130px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0 auto;

						// border: 1px solid red;
					}
				}

				// .zxsm {
				//   width: 70px;
				//   height: 150px;
				//   background: #e6564e;
				//   border-radius: 8px;
				//   position: fixed;
				//   right: 105px;
				//   top: 500px;
				//   // display: flex;

				//   // justify-content: center;
				//   z-index: 9;
				//   .saomaBg {
				//     height: 245px;
				//     width: 200px;
				//     position: fixed;
				//     right: 170px;
				//     top: 490px;
				//     display: none;
				//     z-index: 9;
				//     .qrBg {
				//       width: 100%;
				//       height: 100%;
				//       position: relative;

				//       .saoma {
				//         width: 100%;
				//         height: 100%;
				//       }

				//       .qrcode {
				//         position: absolute;
				//         top: 30px;
				//         left: 30px;
				//         width: 130px;
				//         height: 130px;
				//       }
				//       .foas {
				//         width: 100%;
				//         // border: 1px solid red;
				//         position: absolute;
				//         bottom: 20px;
				//         right: 5px;
				//         p {
				//           text-align: center;
				//           font-size: 16px;
				//           font-weight: 500;
				//           color: #000000;
				//           letter-spacing: 1px;
				//         }
				//       }
				//     }
				//   }
				//   .mlsaz {
				//     width: 37.4px;
				//     height: 37.4px;
				//     margin-top: 17px;
				//     margin-left: 17px;
				//   }
				//   p {
				//     font-size: 18px;
				//     font-weight: 800;
				//     color: #ffffff;
				//     margin-left: 16px;
				//     letter-spacing: 3px;
				//   }
				//   &:hover {
				//     .saomaBg {
				//       display: block;
				//     }
				//   }
				// }

				.magsL {
					width: 306px;
					height: 100%;
					// border: 1px solid blue;
					display: flex;
					align-items: center;

					.magz {
						width: 154px;
						height: 203px;

						//   border: 1px solid yellowgreen;
						margin: 0 auto;
						position: relative;

						//   background: #000;
						.ems {
							width: 24px;
							height: 24px;
							position: absolute;
							bottom: 5px;
							right: 5px;
						}
					}
				}

				.totlR {
					width: 851px;
					height: 100%;

					// border: 1px solid blue;
					.rtops {
						width: 400px;
						// height: 48px;
						// border: 1px solid red;
						margin-top: 30px;
						display: flex;
						justify-content: space-between;
						flex-direction: column;

						.tits {
							// height: 42px;
							width: 100%;
							font-size: 24px;
							font-weight: bold;
							color: #2b2b2b;
							line-height: 30px;
							letter-spacing: 1px;
							overflow: hidden;

							text-overflow: ellipsis;

							display: -webkit-box;

							-webkit-box-orient: vertical;

							-webkit-line-clamp: 2;
						}

						.addys {
							display: flex;
							align-items: center;
							margin-right: 60px;
							margin-top: 14px;
							font-size: 18px;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
						}
					}

					.rbotms {
						width: 100%;
						height: 74px;

						margin-top: 40px;
						display: flex;
						justify-content: space-between;

						.readBtn {
							font-size: 20px;
							letter-spacing: 1px;
							font-weight: 400;
							background-color: #e6554d;
							//background-color: #29B5FF;
							color: #FFF0FF;
							display: flex;
							height: 60px;
							width: 200px;
							border-radius: 12px;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							margin-top: 5px;
							border: 1px solid rgb(216, 216, 216);
							box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
						}

						.botLf1 {
							width: 120px;
							height: 74px;
							background: #27b5ff;
							box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
							border-radius: 8px;
							font-size: 24px;
							font-weight: bold;
							color: #ffffff;
							line-height: 74px;
							text-align: center;
						}

						.botLf2 {
							width: 282px;
							height: 74px;
							background: #e6564e;
							box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
							border-radius: 8px;
							text-align: center;

							// border: 1px solid blue;
							.p1 {
								height: 33px;
								font-size: 24px;
								font-weight: bold;
								color: #ffffff;
							}

							.p2 {
								height: 22px;
								font-size: 15px;
								color: #ffffff;
							}
						}

						.botRt {
							width: 412px;
							height: 72px;

							.rt1 {
								width: 100%;
								height: 28px;
								font-size: 18px;
							}

							.rt2 {
								width: 100%;
								height: 30px;
								display: flex;
								margin-top: 14px;

								font-size: 18px;

								.tssz {
									display: flex;
									align-items: center;
									margin-right: 50px;
								}

								.tsd {
									display: flex;
									align-items: center;
									cursor: pointer;

									img {
										margin-right: 5px;
									}
								}
							}
						}
					}
				}
			}

			.catalog {
				width: 100%;
				height: 86px;
				// border: 1px solid;
				background: #fff;
				margin-top: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.catLft {
					height: 35px;

					display: flex;
					align-items: center;
					margin-left: 59px;

					.ms1 {
						width: 28px;
						height: 28px;
						margin-right: 19px;
					}

					.pan1 {
						font-size: 20px;
						font-weight: 600;
						color: #2b2b2b;
						line-height: 35px;
					}
				}

				.popupTitle {
					width: 100%;
					height: 45px;

					margin-top: 80px;
					display: flex;
					justify-content: space-between;

					.diva {
						font-size: 24px;
						font-weight: bold;
						color: #333333;
						margin-left: 65px;
					}

					.divb {
						display: flex;
						align-items: center;
						margin-right: 61px;

						.dism {
							font-size: 20px;
							font-weight: 400;
							color: #e6564e;
						}

						.divimg {
							width: 18px;
							height: 25px;
							margin-left: 10px;

							.ilmge {
								width: 100%;
								height: 100%;
							}

							.sort2 {
								transform: rotate(180deg);
							}
						}
					}
				}

				.fenj {
					width: 460px;
					height: 2px;
					background: #eeeeee;
					margin: 0 auto;
					margin-top: 25px;
				}

				::-webkit-scrollbar {
					width: 0px;
				}

				.catRg {
					height: 35px;
					display: flex;
					align-items: center;
					margin-right: 45px;

					.ms2 {
						width: 38.4px;
						height: 38.4px;
						margin-right: 16px;
					}

					.pan2 {
						font-size: 20px;
						font-weight: 600;
						color: #333333;
						line-height: 35px;
					}
				}
			}

			.tmsd {
				// height: 76px;
				margin-top: 16px;
				// padding-top: 39px;
				background: #fff;
				cursor: default;
				border: 1px solid #fff;

				// border: 1px solid red;
				.disz {
					// margin-bottom: 15px;
					// border: 1px solid blue;
					border-bottom: 1px solid #eeeeee;
					margin: 0 auto;
					width: 1037px;
					height: 76px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.span1 {
						font-size: 22px;
						font-weight: 550;
						color: #333333;
						// margin-left: 62px;
						letter-spacing: 1px;
					}

					.jiant {
						width: 7px;
						height: 12px;
						margin-bottom: 2px;
						margin: 0 5px;
					}

					.UPBoxImgAC {
						transform: rotate(90deg);
						height: 26upx;
						width: 18upx;
						margin-top: 14upx;
					}

					.span2 {
						font-size: 14px;
						font-weight: bold;
						color: #e6564e;
						letter-spacing: 1px;

						// padding-bottom: 20px;
					}

					.span3 {
						font-size: 14px;
						font-weight: bold;
						color: #e6564e;
						letter-spacing: 1px;
					}
				}

				.disz:hover {
					cursor: pointer;
				}

				.dtactive {
					height: 140px;
					overflow: hidden;
					width: 1037px;
					font-size: 20px;
					font-weight: 500;
					color: #666666;
					width: 1016px;
					line-height: 38px;
					margin-top: 15px;
					// border: 1px solid red;
					margin-bottom: 40px;

					.losl {
						display: block;
						font-size: 20px;
						font-weight: 500;
						color: #666666;
						// border: 1px solid red;
						margin-bottom: 15px;
						letter-spacing: 1px;



						&.cursor {
							cursor: pointer;
							position: relative;

							.lock {
								width: 20px;
								position: absolute;
								right: 0;
								top: 8px;
							}
						}

						&.cursor:hover {
							//background-color: rgb(238, 241, 246);
							color: #e6554d;
						}

						&.foldLevel1 {
							padding-left: 0;
						}

						&.foldLevel2 {
							padding-left: 2em;
						}

						&.foldLevel3 {
							padding-left: 4em;
						}

						&.actived {
							color: #e6554d;
						}

						&.noOpen {
							color: #858c96 !important;
						}




					}
				}
			}

			.writers {
				width: 100%;
				//height: 221px;
				border: 1px solid #fff;
				margin-top: 22px;
				background: #fff;
				display: flex;
				flex-direction: column;
				

				h5 {
					font-size: 24px;
					font-weight: 800;
					color: #2b2b2b;
					letter-spacing: 1px;

					margin: 25px 0 25px 75px;
				}

				h5:hover {
					cursor: default;
				}

				.thbox {
					width: 100%;

					//display: flex;
					// justify-content: space-around;
					padding:0 30px;
					box-sizing: border-box;
					//flex-direction: row;
					display: inline-block;

					.thors {
						//height: 110px;
						width: 100%;
						padding-left:40px;
						box-sizing: border-box;
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.mlge {
							width: 110px;
							height: 110px;
						}

						.mlRgt {
							//height: 100%;
							flex: 1;
							padding:0 0 20px 20px;
							box-sizing: border-box;

							// border: 1px solid red;
							.titi {
								font-size: 20px;
								font-weight: 500;
								color: #333333;
								letter-spacing: 1px;

								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
							}

							p {
								font-size: 18px;
								font-weight: 500;
								color: #cccccc;
								// overflow: hidden;
								// text-overflow: ellipsis;
								// display: -webkit-box;
								// -webkit-box-orient: vertical;
								// -webkit-line-clamp: 2;
								margin-top: 10px;
							}
						}

						.iconts {
							height: 100%;
							width: 7px;
							// background: cornflowerblue;
							display: flex;
							align-items: center;

							img {
								width: 7px;
								height: 13px;
							}
						}
					}

					.thors:hover {
						cursor: pointer;
					}
				}
			}

			.nrom {
				width: 100%;
				// height: 531px;
				border: 1px solid #fff;
				padding-top: 5px;
				padding-bottom: 40px;
				margin-top: 16px;
				background: #fff;

				h4 {
					font-size: 24px;
					font-weight: 800;
					color: #2b2b2b;
					letter-spacing: 1px;
					margin: 26px 0 16px 80px;
				}

				h4:hover {
					cursor: default;
				}

				.blql {
					width: 100%;
					height: 29px;

					display: flex;
					padding-left: 80px;

					.divs {
						margin-right: 12px;
						background: #eeeeee;
						border-radius: 14px;
						font-size: 19px;
						font-weight: 500;
						color: #666666;
						line-height: 29px;
						letter-spacing: 1px;
						padding: 0 15px;
					}
				}

				.blql:hover {
					cursor: pointer;
				}

				.nmcent {
					width: 1040px;
					margin: 0 auto;
					margin-top: 24px;
					font-size: 20px;
					font-weight: 500;
					color: #666666;
					line-height: 48px;
					margin-left: 80px;
					// background: yellow;
					margin-bottom: 60px;
				}
			}
		}
	}
</style>
